/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Community Components
import CommunityDashboard from "layouts/Community/Dashboard";
// import Lock from 'layouts/Community/AuthPage/components/Lock';
import CommunityAuthPage from "layouts/Community/AuthPage";

// Common Components
import RootUrl from "layouts/Common/RootUrl";

import PrivateRoute from "componentsCustom/PrivateRoute";
import Trade from "layouts/Common/Trade";
import Bots from "layouts/Common/Bots";
import Arbitration from "layouts/Common/Arbitration";
import Orders from "layouts/Common/Orders";
import Markets from "layouts/Common/Markets";
import News from "layouts/Common/News";
import Profile from "layouts/Common/Profile";
import Wallet from "layouts/Common/Wallet";
import P2P from "layouts/Common/P2P";
import Admin from "layouts/Community/Admin";
import Notifications from "layouts/Common/Notifications";

// Material UI Components
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import {
  Home,
  AnalyticsOutlined,
  Timeline,
  CurrencyBitcoinRounded,
  Newspaper,
  AccountCircle,
  Money,
  Article,
  AdminPanelSettings,
} from "@mui/icons-material";

// Constants
import { ROUTES } from "constants";

// Utils
import filterRoutes from "utils/filterRoutes";

export const routes = [
  {
    type: "route",
    name: "Root",
    key: "root",
    route: ROUTES.ROOT,
    component: <PrivateRoute component={<RootUrl />} />,
  },
  {
    type: "route",
    name: "Login",
    key: "login",
    route: ROUTES.AUTH,
    component: <PrivateRoute component={<CommunityAuthPage />} isAuth={true} />,
  },
  // {
  //   type: "route",
  //   name: "Lock",
  //   key: "lock",
  //   route: ROUTES.LOCK,
  //   component: <PrivateRoute component={<Lock />} />,
  // },
  {
    type: "collapse",
    name: "Home",
    key: "home",
    route: ROUTES.HOME,
    icon: <Home size="12px" />,
    component: <PrivateRoute component={<CommunityDashboard />} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Trade",
    key: "trade",
    route: ROUTES.TRADE,
    icon: <AnalyticsOutlined size="12px" color="white" />,
    component: <PrivateRoute component={<Trade />} />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Bots",
  //   key: "bots",
  //   route: ROUTES.BOTS,
  //   icon: <SmartToyIcon size="12px" />,
  //   component: <PrivateRoute component={<Bots />} />,
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "Orders",
    key: "orders",
    route: ROUTES.ORDERS,
    icon: <Article size="12px" />,
    component: <PrivateRoute component={<Orders />} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Wallet",
    key: "wallet",
    route: ROUTES.WALLET,
    icon: <Money size="12px" />,
    component: <PrivateRoute component={<Wallet />} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "P2P",
    key: "p2p",
    route: ROUTES.P2P,
    icon: <CurrencyExchangeIcon size="12px" />,
    component: <PrivateRoute component={<P2P />} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Arbitration",
    key: "arbitration",
    route: ROUTES.ARBITRATION,
    icon: <Timeline size="12px" />,
    component: <PrivateRoute component={<Arbitration />} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Markets",
    key: "markets",
    route: ROUTES.MARKETS,
    icon: <CurrencyBitcoinRounded size="12px" />,
    component: <PrivateRoute component={<Markets />} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "News",
    key: "news",
    route: ROUTES.NEWS,
    icon: <Newspaper size="12px" />,
    component: <PrivateRoute component={<News />} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Admin",
    key: "admin",
    route: ROUTES.ADMIN,
    icon: <AdminPanelSettings size="12px" />,
    component: <PrivateRoute component={<Admin />} />,
    noCollapse: true,
  },
  {
    type: "route",
    name: "Notifications",
    key: "notifications",
    route: ROUTES.NOTIFICATIONS,
    component: <PrivateRoute component={<Notifications />} />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: ROUTES.PROFILE,
    icon: <AccountCircle size="12px" />,
    component: <PrivateRoute component={<Profile />} />,
    noCollapse: true,
    tab: 'profile',
  },
];

export default function routesFromUser(userType) {
  return filterRoutes(routes, userType);
}
