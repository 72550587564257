import axios from "axios";
import { BACK_API } from "apiconfig";
import { store } from "index";

export const fetchMessages = async () => {
  const url = `${BACK_API}/messages`;
  const headers = {
    token: store.getState().currentUser.data.jwtToken,
    id: store.getState().currentUser.data.id,
  };
  return await axios
    .get(url, { headers })
    .then(({ data }) => [...data].reverse())
    .catch((err) => {
      throw err;
    });
};

export async function addMessage({ content }) {
  const parsedBody = { content };
  const url = `${BACK_API}/messages`;
  const headers = {
    token: store.getState().currentUser.data.jwtToken,
    id: store.getState().currentUser.data.id,
  };
  return await axios
    .post(url, parsedBody, { headers })
    .then(({ data }) => data);
}

export async function updateMessage({ id, content }) {
  const parsedBody = { content };
  const url = `${BACK_API}/messages/${id}`;
  const headers = {
    token: store.getState().currentUser.data.jwtToken,
    id: store.getState().currentUser.data.id,
  };
  return await axios.put(url, parsedBody, { headers }).then(({ data }) => data);
}

export async function deleteMessage(messageId) {
  const url = `${BACK_API}/messages/${messageId}`;
  const headers = {
    token: store.getState().currentUser.data.jwtToken,
    id: store.getState().currentUser.data.id,
  };
  return await axios.delete(url, { headers }).then(({ data }) => data);
}
