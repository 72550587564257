import { fetchExchangePair } from "api/pairs"
import { STATUS } from "constants/fetchStatus"
import useFetch from "hooks/useFetch"
import { store } from "index"

export default function useStrategyRequiredBalance(exchange1, exchange2, pair, amount) {
    const { data: exchange1PairsData, status: exchange1PairsStatus } = useFetch(fetchExchangePair, exchange1, pair)
    const { data: exchange2PairsData, status: exchange2PairsStatus } = useFetch(fetchExchangePair, exchange2, pair)
    const exchange1PairPrice = exchange1PairsData?.price
    const exchange2PairPrice = exchange2PairsData?.price
    const exchange1Symbol2BalanceNeeded = amount * exchange1PairPrice
    const exchange2Symbol2BalanceNeeded = amount * exchange2PairPrice
    const firstSymbol = pair.split("-")[0]
    const secondSymbol = pair.split("-")[1]
    const wallets = store.getState().wallets
    const exchange1Symbol1Balance = wallets[exchange1]?.pairs.find(pair => pair.symbol === firstSymbol)?.free
    const exchange2Symbol1Balance = wallets[exchange2]?.pairs.find(pair => pair.symbol === firstSymbol)?.free
    const exchange1Symbol2Balance = wallets[exchange1]?.pairs.find(pair => pair.symbol === secondSymbol)?.free
    const exchange2Symbol2Balance = wallets[exchange2]?.pairs.find(pair => pair.symbol === secondSymbol)?.free
    const getBothStatuses = () => {
        if (exchange1PairsStatus === STATUS.OK && exchange2PairsStatus === STATUS.OK) return STATUS.OK
        if (exchange1PairsStatus === STATUS.LOADING || exchange2PairsStatus === STATUS.LOADING) return STATUS.LOADING
        return STATUS.ERROR
    }

    const enoughExchange1Symbol1Balance = exchange1Symbol1Balance > amount
    const enoughExchange2Symbol1Balance = exchange2Symbol1Balance > amount
    const enoughExchange1Symbol2Balance = exchange1Symbol2Balance > exchange1Symbol2BalanceNeeded
    const enoughExchange2Symbol2Balance = exchange2Symbol2Balance > exchange2Symbol2BalanceNeeded

    const hasRequiredBalance = (
        enoughExchange1Symbol1Balance &&
        enoughExchange2Symbol1Balance &&
        enoughExchange1Symbol2Balance &&
        enoughExchange2Symbol2Balance
    )
    const requiredBalances = {
        exchange1Symbol1: `<span style="color:${enoughExchange1Symbol1Balance ? "green" : "red"}">${exchange1}: ${amount} ${firstSymbol}</span>`,
        exchange2Symbol1: `<span style="color:${enoughExchange2Symbol1Balance ? "green" : "red"}">${exchange2}: ${amount} ${firstSymbol}</span>`,
        exchange1Symbol2: `<span style="color:${enoughExchange1Symbol2Balance ? "green" : "red"}">${exchange1}: ${exchange1Symbol2BalanceNeeded} ${secondSymbol}</span>`,
        exchange2Symbol2: `<span style="color:${enoughExchange2Symbol2Balance ? "green" : "red"}">${exchange2}: ${exchange2Symbol2BalanceNeeded} ${secondSymbol}</span>`
    }
    return {
        data: { hasRequiredBalance, requiredBalances },
        status: getBothStatuses()
    }
}