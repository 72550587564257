import { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

// react-redux
import { useSelector, useDispatch } from "react-redux";
import { clearInformativeMessage } from "redux/actions/informativeMessage";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Message from "../Status/Message";

// Overview page components
import Header from "layouts/Common/Profile/components/Header";

import SettingsTab from "./tabs/settingsTab";
import ExchangesTab from "./tabs/exchangesTab";
import ProfileTab from "./tabs/profile";
import ReferralTab from "./tabs/referralTab";
import PricingPage from "../Pricing";
import { getCheckoutResponse } from "api/checkout";

function Profile() {
  const { message } = useSelector((state) => state.informativeMessage);
  const dispatch = useDispatch();
  let { state } = useLocation();
  const [params, setParams] = useSearchParams();
  const [tabValue, setTabValue] = useState("profile");
  const getCurrentTab = () => {
    switch (tabValue) {
      case "profile":
        return <ProfileTab />;
      case "settings":
        return <SettingsTab />;
      case "exchanges":
        return <ExchangesTab />;
      case "plans":
        return <PricingPage />;
      case "referrals":
        return <ReferralTab />;
      default:
        return <ProfileTab />;
    }
  };

  const url = new URL(window.location.href);
  const sessionId = url.searchParams.get("session_id");
  const cancel_payment = url.searchParams.get("cancel_payment");

  const clearParams = (param, tab) => {
    params.delete(param);
    setParams(params);
    setTabValue(tab);
  };

  useEffect(() => {
    if (sessionId) {
      getCheckoutResponse(sessionId);
      clearParams("session_id", "plans");
    }

    cancel_payment && clearParams("cancel_payment", "plans");
  }, [sessionId, cancel_payment]);

  useEffect(() => {
    return () => {
      dispatch(clearInformativeMessage());
    };
  }, []);

  useEffect(() => {
    state && setTabValue(state.tab);
  }, [state]);

  return (
    <DashboardLayout>
      {message && <Message />}
      <Header tabValue={tabValue} setTabValue={setTabValue} />
      {getCurrentTab()}
    </DashboardLayout>
  );
}

export default Profile;
