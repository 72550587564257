import axios from "axios"
import { BACK_API } from "apiconfig";
import { store } from "index";
import getKeysByExchange from "utils/getKeysByExchange";

export async function postTrade(selectedExchange, pair, amount, price, side, type) {
  const { id, jwtToken } = store.getState().currentUser.data
  const { publicKey, privateKey, password } = getKeysByExchange(selectedExchange)
  const headers = { 'token': jwtToken, 'id': id };
  const parsedBody = {
    public_key: publicKey,
    private_key: privateKey,
    password: password,
    exchange: selectedExchange,
    pair: pair,
    amount,
    price,
    side: side,
    type: type
  };
  const url = `${BACK_API}/buy-sell`;
  return await axios
    .post(url, parsedBody, { headers })
    .then(res => res.data.rows);
}