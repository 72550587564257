import SoftBox from "components/SoftBox";
import EditButton from "./EditButton";
import DeleteButton from "./DeleteButton";

export default function Actions({ idStategy, setShowComponent }) {

    return (
        <SoftBox display="flex" alignItems="center" sx={{ cursor: "pointer", lineHeight: 0 }}>
            <SoftBox id="editStrategyButton" mr={1}>
                <EditButton id={idStategy} setShowComponent={setShowComponent} />
            </SoftBox>
            <SoftBox id="deleteStrategyButton">
                <DeleteButton id={idStategy} />
            </SoftBox>
        </SoftBox>
    )
}