// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// NewProduct page components
import ExchangeSelectorArbitration from "../../../EditStrategy/components/ExchangeSelectorArbitration";
import SymbolSelectorArbitration from "../../../EditStrategy/components/PairSelectorArbitration";
import AmountInput from "../../../EditStrategy/components/Amount";
import ProfitInput from "../../../EditStrategy/components/Profit";

// Translate
import { useTranslation } from "react-i18next";
import useFetch from "hooks/useFetch";
import { fetchExchangePair } from "api/pairs";

function StrategyInfo({ exchange1, pair, amount, profit, setNewStrategy }) {
  const { t } = useTranslation();
  const { data } = useFetch(fetchExchangePair, exchange1, pair);
  return (
    <SoftBox>
      <SoftTypography variant="h5">{t("Strategy Information")}</SoftTypography>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <ExchangeSelectorArbitration
              title={t("Exchange 1")}
              setStrategy={setNewStrategy}
              label={t("exchange1")}
              focus={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ExchangeSelectorArbitration
              title={t("Exchange 2")}
              setStrategy={setNewStrategy}
              label={t("exchange2")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <SymbolSelectorArbitration setStrategy={setNewStrategy} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <AmountInput
              priceValue={data?.price}
              inputValue={amount}
              setStrategy={setNewStrategy}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ProfitInput inputValue={profit} setStrategy={setNewStrategy} />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default StrategyInfo;
