// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Settings page components
import CancelButton from "examples/Buttons/CancelButton";
import ActionButton from "examples/Buttons/ActionButton";
import PasswordInputToggle from "examples/Inputs/PasswordInputToggle";
import SoftInput from 'components/SoftInput';

// Translate
import { useTranslation } from 'react-i18next';

function ProfileChangePass({ info, loading, action }) {
    const { t } = useTranslation();
    const { handleCancel, handleChangePassword, handleSubmitChangePassword } = action;

    const passwordFields = [
        {
            label: "current password",
            propsField: { placeholder: "Current Password", name: "current" }
        },
        {
            label: "new password",
            propsField: { placeholder: t("New Password"), name: "newPass" }
        },
        {
            label: "confirm new password",
            propsField: { placeholder: t("Confirm New Password"), name: "confirm" }
        },
    ];

    const renderPasswordFields = passwordFields.map((field, key) => {
        const fielsKey = `element-${key}`;
        const { label, propsField } = field;
        return (
            <Grid key={fielsKey} item xs={12}>
                <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                        >
                            {t(label)}
                        </SoftTypography>
                    </SoftBox>
                    {propsField.name === "current" ?
                        <PasswordInputToggle onChange={handleChangePassword} {...propsField} /> :
                        <SoftInput onChange={handleChangePassword} {...propsField} type={info.showPassword ? "text" : "password"} />
                    }
                </SoftBox>
            </Grid>
        );
    });

    const passwordRequirements = [
        "One capital letter",
        "Min 8 characters",
        "One number (2 are recommended)",
        "Change it often",
    ];

    const renderPasswordRequirements = passwordRequirements.map((item, key) => {
        const itemKey = `element-${key}`;

        return (
            <SoftBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                <SoftTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
                    {t(item)}
                </SoftTypography>
            </SoftBox>
        );
    });

    return (
        <Card>
            <SoftBox p={3}>
                <SoftTypography variant="h5" textTransform="capitalize">{t('change password')}</SoftTypography>
            </SoftBox>
            <SoftBox component="form" pb={3} px={3} onSubmit={handleSubmitChangePassword}>
                <Grid container spacing={3}>
                    {renderPasswordFields}
                </Grid>
                <SoftBox mt={6} mb={1}>
                    <SoftTypography variant="h5">{t('Password requirements')}</SoftTypography>
                </SoftBox>
                <SoftBox mb={1}>
                    <SoftTypography variant="body2" color="text">
                        {t('Please follow this guide for a strong password')}
                    </SoftTypography>
                </SoftBox>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={6}>
                        <SoftBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                            {renderPasswordRequirements}
                        </SoftBox>
                    </Grid>
                    <SoftBox component={Grid} item xs={12} lg={6} display="flex" alignItems={{ xs: "center", lg: "flex-end" }} gap={1}>
                        <CancelButton handle={handleCancel} />
                        <ActionButton loading={loading} label="change" />
                    </SoftBox>
                </Grid>
            </SoftBox>
        </Card>
    );
}

export default ProfileChangePass;
