import "./styles.scss";

const fallbackImg =
  "https://cdn.jsdelivr.net/npm/cryptocurrency-icons@0.18.1/32/icon/act.png";

export default function CryptoIcon({ pair, imageUrl }) {
  const url = imageUrl
    ? `https://www.cryptocompare.com/${imageUrl}`
    : fallbackImg;
  return (
    <div className="cp-container">
      <img
        style={{ width: "28px", height: "28px" }}
        src={url}
        alt={pair.split("-")[0] || "coin"}
      />
      <div className="name">{pair}</div>
    </div>
  );
}
