import useStrategyRequiredBalance from "utils/useCheckStrategyRequiredBalance";
import StatusCell from "components/StatusCell";
import { STATUS } from "constants/fetchStatus";
import Loader from "components/Loader";
import InfoModal from "./InfoModal";

export default function RequiredBalance({ strategy }) {
  const { exchange1, exchange2, pair, amount } = strategy;
  const {
    data: { hasRequiredBalance, requiredBalances },
    status,
  } = useStrategyRequiredBalance(exchange1, exchange2, pair, amount);
  const BalanceStatusCell = (
    <StatusCell
      icon={hasRequiredBalance ? "done" : "close"}
      color={hasRequiredBalance ? "success" : "error"}
    />
  );
  return (
    <>
      {status === STATUS.LOADING && <Loader size="sm" />}
      {status === STATUS.ERROR && "Error"}
      {status === STATUS.OK && (
        <InfoModal
          button={BalanceStatusCell}
          requiredBalances={requiredBalances}
        />
      )}
    </>
  );
}
