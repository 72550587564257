const dollarDecimals = 2;

export const meaningfulAmountDecimals = (amount, dollarPrice) => {
    if(!amount || !dollarPrice) return 0
    const log = Math.round(Math.log10(dollarPrice));
    
    // Ensure that requiredDecimals is within the valid range (0 to 100)
    const requiredDecimals = Math.min(Math.max(log + dollarDecimals, 0), 100);

    const formattedAmount = parseFloat(amount.toFixed(requiredDecimals));
   
    return formattedAmount;
};
