import axios from "axios";
import { BACK_API } from "apiconfig";
import { store } from "index";
import { getBots } from "redux/actions/bots";

export const fetchBots = async () => {
  const url = `${BACK_API}/bots`;
  const headers = {
    token: store.getState().currentUser.data.jwtToken,
    id: store.getState().currentUser.data.id,
  };
  return await axios
    .get(url, { headers })
    .then(({ data }) => data)
    .catch((err) => {
      throw err;
    });
};

export async function addBot({ bot_id, exchange, amount }) {
  const parsedBody = { bot_id, exchange, amount };
  const url = `${BACK_API}/bots`;
  const headers = {
    token: store.getState().currentUser.data.jwtToken,
    id: store.getState().currentUser.data.id,
  };
  return await axios
    .post(url, parsedBody, { headers })
    .then(() => {
      store.dispatch(getBots())
      return { success: true }
    }).catch(() => {
      return { success: false }
    });
}

export async function deleteBotCopyTrade(suscription_id) {
  const url = `${BACK_API}/bots/copy-trade/${suscription_id}`;
  const headers = {
    token: store.getState().currentUser.data.jwtToken,
    id: store.getState().currentUser.data.id,
  };
  return await axios
    .delete(url, { headers }).then(() => {
      store.dispatch(getBots())
      return { success: true }
    }).catch(() => {
      return { success: false }
    });
}
