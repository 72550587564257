// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// Soft UI Dashboard PRO React example components
import TransparentInfoCard from "examples/Cards/InfoCards/TransparentInfoCard";
import ReferralCode from "./ReferralCode";
import { useTranslation } from 'react-i18next';
import OutlinedCounterCard from "examples/Cards/CounterCards/OutlinedCounterCard";

export default function Explanation() {
    const { t } = useTranslation();
    return (
        <SoftBox mb={2}>
            <Card>
                <SoftBox p={2}>
                    <ReferralCode />
                </SoftBox>
                <SoftBox p={2} lineHeight={1}>
                    <Grid container spacing={3}>
                        <Grid item md={12}>
                            <SoftTypography variant="h6" fontWeight="medium">
                                {t("How to use")}
                            </SoftTypography>
                            <SoftTypography variant="button" color="text" fontWeight="regular">
                                {t("Integrate your referral code in 3 easy steps")}
                            </SoftTypography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <TransparentInfoCard
                                        color="success"
                                        icon="paid"
                                        description={t("1. Share your code for people to register")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TransparentInfoCard
                                        color="success"
                                        icon="unarchive"
                                        description={t("2. Get paid monthly for each referral")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TransparentInfoCard
                                        color="success"
                                        icon="emoji_events"
                                        description={t("3. Second and third level referrals are also rewarded!")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={12}>
                            <SoftTypography variant="h6" fontWeight="medium">
                                {t("Rewards")}
                            </SoftTypography>
                            <SoftTypography variant="button" color="text" fontWeight="regular">
                                {t("This is how much you will get paid")}
                            </SoftTypography>
                            <Grid container spacing={3} justifyContent="space-evenly" mt={0.5}>
                                <Grid item xs={6} lg={3}>
                                    <OutlinedCounterCard count={6} suffix={t("usdc / MO")} title={t('Level 1')} useDecimals={false} />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <OutlinedCounterCard count={3} suffix={t("usdc / MO")} title={t('Level 2')} useDecimals={false} />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <OutlinedCounterCard count={1} suffix={t("usdc / MO")} title={t('Level 3')} useDecimals={false} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
            </Card>
        </SoftBox>
    )
}