// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Projects from "./Projects";

// Translate
import { useTranslation } from "react-i18next";

export default function ArbitrageOpportunities() {
  const { t } = useTranslation();
  return (
    <SoftBox p={0}>
      <Projects display="flex" flexDirection="column" height="100%" mb={6}>
        <SoftTypography
          component="a"
          href="#"
          variant="button"
          color="text"
          fontWeight="medium"
          sx={{
            mt: "auto",
            mr: "auto",
            display: "inline-flex",
            alignItems: "center",
            cursor: "pointer",

            "& .material-icons-round": {
              fontSize: "1.125rem",
              transform: `translate(2px, -0.5px)`,
              transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
            },

            "&:hover .material-icons-round, &:focus  .material-icons-round": {
              transform: `translate(6px, -0.5px)`,
            },
          }}
        >
          {t("Read More")}
          <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
        </SoftTypography>
      </Projects>
    </SoftBox>
  );
}
