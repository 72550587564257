// react-router components
import { Link } from "react-router-dom";

// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import { Stack } from "@mui/material";
import { ROUTES } from "constants";

// Translate
import { useTranslation } from "react-i18next";

export default function NoWalletsWarning() {
  const { t } = useTranslation();
  return (
    <Stack
      spacing={2}
      sx={{ margin: 5 }}
      justifyContent="center"
      alignItems="center"
      id="addExchange"
    >
      <SoftTypography
        className="amount-card"
        ownerState={{ verticalAlign: "baseline" }}
      >
        {t("You need to activate an exchange")}!
      </SoftTypography>
      <SoftButton
        component={Link}
        state={{ tab: "exchanges" }}
        to={ROUTES.PROFILE}
        style={{ marginTop: "20px", align: "center", maxWidth: "128px" }}
        variant="gradient"
        color="info"
        fullWidth
      >
        <SoftTypography variant="button" color="white">
          {t("Activate")}
        </SoftTypography>
      </SoftButton>
    </Stack>
  );
}
