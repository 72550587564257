// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

export const registerForm = [
  {
    key: uuidv4(),
    name: "nombreCompleto",
    label: "Full Name",
    type: "text",
    required: true,
  },
  {
    key: uuidv4(),
    name: "email",
    label: "Email",
    type: "email",
    required: true,
  },
  {
    key: uuidv4(),
    name: "username",
    label: "User Name",
    type: "text",
    required: true,
  },
  {
    key: uuidv4(),
    name: "password",
    label: "Password",
    type: "password",
    required: true,
  },
  {
    key: uuidv4(),
    name: "phoneNumber",
    label: "Phone Number (optional)",
    type: "number",
  },
  {
    key: uuidv4(),
    name: "country",
    label: "Country",
    type: "text",
    required: true,
  },
];
