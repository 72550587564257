import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { exchangesData } from "data/exchanges";
import Exchange from "./components/Exchange";

// @mui material components
import { Grid } from "@mui/material";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

import "./index.css";

function Exchanges() {
  // Initialize the state with the rendered components
  const [renderedExchanges] = useState(() =>
    exchangesData.map((exchange) => (
      <Exchange key={uuidv4()} exchange={exchange} />
    ))
  );

  return (
    <Grid container justifyContent="center" spacing={4} mt={1} mb={3}>
      <Grid item xs={12} md={11} xl={10}>
        <SoftBox id="exchangesSection">{renderedExchanges}</SoftBox>
      </Grid>
    </Grid>
  );
}

export default Exchanges;
