/***** We receive an action and set de data to the store *****/

import { SET_FAV_PAIRS, FAV_PAIRS_STATUS } from "constants/actionTypes"
import { STATUS } from "constants/fetchStatus"

export default (favPairs = { data: [], status: STATUS.PENDING }, action) => {
    switch (action.type) {
        case SET_FAV_PAIRS:
            return { ...favPairs, data: action.payload }
        case FAV_PAIRS_STATUS:
            return { ...favPairs, status: action.payload }
        default:
            return favPairs
    }
}