// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

export const loginForm = [
    {
        key: uuidv4(),
        name: "email",
        label: "Email",
        type: "email",
        placeholder: "Email",
        required: true,
    },
    {
        key: uuidv4(),
        name: "password",
        label: "Password",
        type: "password",
        placeholder: "Password",
    },
];
