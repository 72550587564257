import SoftTypography from "components/SoftTypography"

// Translate
import { useTranslation } from "react-i18next";

const TourContent = ({ props, children }) => {
    const { t } = useTranslation();
    return (
        <SoftTypography component="span" variant="button" color="dark" {...props}>
            {t(children)}
        </SoftTypography>
    )
}

export default TourContent