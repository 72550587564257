import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import Card from "@mui/material/Card";
import '../index.scss';

export default function TradingChart({ selectedPair, selectedExchange }) {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    return (
        <Card className="trading-view" id="trading">
            <AdvancedRealTimeChart
                container_id="trading"
                style="1"
                timezone={timeZone}
                interval="D"
                symbol={`${selectedExchange.toUpperCase()}:${selectedPair.replace("-", "")}`}
                theme="dark"
                autosize>
            </AdvancedRealTimeChart>
        </Card>
    )
}