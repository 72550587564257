import { store } from "index";

export const setInitialTourState = () => {
  const { count } = store.getState().keys;
  const tourState = {
    home: true,
    trade: true,
    tradeModal: true,
    orders: true,
    wallet: true,
    arbitration: true,
    addStrategy: true,
    strategies: true,
    markets: true,
    news: true,
    admin: true,
    profile: true,
    addExchange: !count,
  };
  const currentTourState = JSON.parse(localStorage.getItem("tourViewed"));
  if (!currentTourState) {
    localStorage.setItem("tourViewed", JSON.stringify({ ...tourState }));
  }
};

export const disableTourState = () => {
  localStorage.removeItem("tourViewed");
};

export const validateTourState = () => {
  const currentTourState = JSON.parse(localStorage.getItem("tourViewed"));
  return !!currentTourState;
};

export const getTourState = (section) => {
  const currentTourState = JSON.parse(localStorage.getItem("tourViewed"));

  if (currentTourState && currentTourState.hasOwnProperty(section)) {
    return currentTourState[section];
  } else {
    console.warn(`Section "${section}" not found in the current tour state.`);
    return null;
  }
};

export const setTourState = (section) => {
  const currentTourState = JSON.parse(localStorage.getItem("tourViewed"));

  if (currentTourState && currentTourState.hasOwnProperty(section)) {
    currentTourState[section] = false;
    localStorage.setItem("tourViewed", JSON.stringify(currentTourState));
  } else {
    console.warn(`Section "${section}" not found in the current tour state.`);
  }
};
