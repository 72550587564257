import { useState } from "react";

// react-redux
import { useSelector } from "react-redux";

import ReactModal from 'react-modal-resizable-draggable';

// FloatingVideo Components
import PlayList from "./components/playList";
import VideoPlaying from "./components/videoPlaying";

import './styles.css';

export default function FloatingVideo({ isVideoPlaying, toggleVideoPlaying }) {
  const { videos } = useSelector((state) => state);

  const [playing, setPlaying] = useState(false);
  const [showPlayList, setShowPlayList] = useState(true);
  const [currentVideo, setCurrentVideo] = useState("");

  return (
    <ReactModal
      className="modal-resizer"
      initWidth={300}
      initHeight={300}
      minWidth={300}
      minHeight={300}
      onRequestClose={toggleVideoPlaying}
      isOpen={isVideoPlaying}
    >
      {
        showPlayList ? (
          <PlayList
            videos={videos}
            showPlayList={showPlayList}
            setShowPlayList={setShowPlayList}
            setCurrentVideo={setCurrentVideo}
            toggleVideoPlaying={toggleVideoPlaying}
          />
        ) : (
          <VideoPlaying
            videos={videos}
            playing={playing}
            setPlaying={setPlaying}
            showPlayList={showPlayList}
            setShowPlayList={setShowPlayList}
            currentVideo={currentVideo}
            setCurrentVideo={setCurrentVideo}
            toggleVideoPlaying={toggleVideoPlaying}
          />
        )
      }
    </ReactModal >

  );
}

