import StrategiesTable from './StrategiesTable';
import NewStrategy from './NewStrategy';
import EditStrategy from './EditStrategy';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import { useTranslation } from 'react-i18next';
import { STATUS } from 'constants/fetchStatus';
import Loader from 'components/Loader';

export default function Strategies() {
  const { t } = useTranslation();
  const { data: strategies, status } = useSelector(state => state.arbitration.strategies)
  const [showComponent, setShowComponent] = useState({
    table: true,
    newStrategy: false,
    editStrategy: false,
    strategyId: 0
  });
  return <SoftBox my={2}>
    <SoftTypography fontSize="medium">
      {t('If an opportunity is found and it meets one of your strategies criteria, 2 orders will be created')}
    </SoftTypography>
    <SoftBox mt={2}>
      {status === STATUS.LOADING && <Loader />}
      {status === STATUS.ERROR && "Something failed"}
      {status === STATUS.OK && <>
        {showComponent.table && <StrategiesTable strategies={strategies} setShowComponent={setShowComponent} />}
        {showComponent.newStrategy && <NewStrategy setShowComponent={setShowComponent} />}
        {showComponent.editStrategy && <EditStrategy id={showComponent.strategyId} setShowComponent={setShowComponent} />}
      </>}
    </SoftBox>
  </SoftBox>
}