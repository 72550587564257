// @mui material components
// import Tooltip from "@mui/material/Tooltip";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { DOMAIN } from "apiconfig";
import { CLIENT_NAME } from "apiconfig";

function ReferralCode() {
    const { t } = useTranslation();
    const { secondary } = colors;
    const { borderWidth } = borders;
    const { data: currentUser } = useSelector(state => state.currentUser)
    const refLink = `https://${CLIENT_NAME}.${DOMAIN}/auth/register?ref=${currentUser.referralCode}`
    const handleCopyToClipboard = () => navigator.clipboard.writeText(refLink)

    return (
        <>
            <SoftBox lineHeight={1}>
                <SoftTypography variant="h6" fontWeight="medium">
                    {t("Referral code")}
                </SoftTypography>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                    {t("Make sure people register with the following code")}
                </SoftTypography>
            </SoftBox>
            <SoftBox
                borderRadius="md"
                border={`${borderWidth[1]} dashed ${secondary.main}`}
                pt={2}
                pb={1.5}
                px={2}
                mt={2}
            >
                <SoftBox display="flex" alignItems="center" mb={2}>
                    <SoftBox width="70%" mr={1}>
                        <SoftInput
                            size="small"
                            defaultValue={refLink}
                            icon={{ component: "lock", direction: "right" }}
                            disabled
                        />
                    </SoftBox>
                    {/*  <Tooltip title="Referral code expires in 24 hours" placement="top"> */}
                    <SoftButton
                        variant="outlined"
                        color="secondary"
                        size="small"
                        sx={{ padding: "0.5rem 1rem" }}
                        onClick={handleCopyToClipboard}
                    >
                        {t("copy")}
                    </SoftButton>
                    {/*  </Tooltip> */}
                </SoftBox>
            </SoftBox>
        </>
    );
}

export default ReferralCode;
