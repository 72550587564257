import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import OpportunitiesTable from "./OpportunitiesTable";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { STATUS } from "constants/fetchStatus";
import Loader from "components/Loader";

export default function Opportunities() {
    const { t } = useTranslation();
    const { data: opportunities, status } = useSelector(state => state.arbitration.opportunities)
    return <SoftBox mt={2}>
        <SoftTypography fontSize="medium">
            {t('These are the last price differences found. Create strategies to profit from the next ones!')}
        </SoftTypography>
        {status === STATUS.LOADING && <Loader />}
        {status === STATUS.ERROR && "Something failed"}
        {status === STATUS.OK && opportunities.length > 0 && <OpportunitiesTable opportunities={opportunities} />}
        {status === STATUS.OK && opportunities.length === 0 && <SoftTypography>{t('No opportunities yet!')}</SoftTypography>}
    </SoftBox>
}