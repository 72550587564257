/***** We receive an action and set de data to the store *****/

import { SET_NOTIFICATIONS } from "constants/actionTypes";

export default (notifications = [], action) => {
    switch (action.type) {
        case SET_NOTIFICATIONS:
            return action.payload
        default:
            return notifications;
    };
};