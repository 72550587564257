import { useState } from "react";

// react-redux
import { useSelector, useDispatch } from "react-redux";
import { getVideos } from "redux/actions/videos";

// sweetalert2 components
import Swal from "sweetalert2";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Admin React components
import AdminDataTable from "../../Components/AdminDataTable";
import { getVideosDataTableData } from "../../data/dataTableData";

// Utils
import { deleteVideo } from "api/videos";
import EditVideo from "../../Components/EditForm/EditVideo";
import AddVideo from "../../Components/AddForm/AddVideo";

// Translate
import { useTranslation } from "react-i18next";

export default function VideosTab() {
  const { t } = useTranslation();
  const { data: videos, status } = useSelector((state) => state.videos);
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState({});

  const cancelEdit = () => {
    setIsEditing(false);
    setSelectedVideo({});
  };

  const editClickedVideo = (video) => {
    setSelectedVideo(video);
    setIsEditing(true);
  };

  const newClickedVideo = () => {
    setIsAdding(!isAdding);
  };

  const refreshButtonAction = () => {
    dispatch(getVideos());
  };

  const handleRemove = ({ id: videoId }) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: t("Delete Video?"),
        text: t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("Yes, DELETE!"),
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteVideo(videoId)
            .then(() => {
              cancelEdit();
              refreshButtonAction();
              Swal.fire(t("Deleted!"), t("Video has been deleted"), "success");
            })
            .catch(() => {
              Swal.fire(t("Error"), t("Video could not be deleted"), "error");
            });
        }
      });
  };

  return (
    <SoftBox>
      {isEditing && (
        <EditVideo
          videoData={selectedVideo}
          handleCancel={cancelEdit}
          handleRemove={handleRemove}
          handleRefresh={refreshButtonAction}
        />
      )}
      {isAdding && (
        <AddVideo
          handleCancel={newClickedVideo}
          handleRefresh={refreshButtonAction}
        />
      )}
      {!isEditing && !isAdding && (
        <AdminDataTable
          label="Video"
          dataTableData={getVideosDataTableData(
            videos,
            "Video",
            editClickedVideo,
            handleRemove
          )}
          dataState={status}
          handleRefresh={refreshButtonAction}
          handleNew={newClickedVideo}
          button
        />
      )}
    </SoftBox>
  );
}
