import { SET_INFORMATIVE_MESSAGE, CLEAR_INFORMATIVE_MESSAGE, INFORMATIVE_MESSAGE_STATUS } from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";

/***** Redux actions that end up in the store *****/
export const setInformativeMessage = ({ success, message }) => async dispatch => {
    dispatch({ type: INFORMATIVE_MESSAGE_STATUS, payload: STATUS.LOADING });
    dispatch({ type: INFORMATIVE_MESSAGE_STATUS, payload: success ? STATUS.OK : STATUS.ERROR });
    dispatch({ type: SET_INFORMATIVE_MESSAGE, payload: message });

};

export const clearInformativeMessage = () => async dispatch => {
    dispatch({ type: CLEAR_INFORMATIVE_MESSAGE, payload: null });
};