import { useState } from 'react'
// Redux
import { useDispatch } from "react-redux";
// Soft UI Dashboard React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// Custom components
import WalletsAddedGuard from 'componentsCustom/WalletsAddedGuard';
// Translate
import TabSelector from './components/TabSelector';
import Opportunities from './components/Opportunities';
import Actions from './components/Actions';
import Strategies from './components/Strategies';
import RefreshButton from '../Orders/components/RefreshButton';
import { getArbitrationActions, getArbitrationExchanges, getArbitrationPairs, getArbitrationOpportunities, getArbitrationStrategies } from 'redux/actions/arbitration';
import SoftBox from 'components/SoftBox';

function Arbitration() {

  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState("opportunities")
  const fetchArbitrationData = () => {
    dispatch(getArbitrationActions())
    dispatch(getArbitrationExchanges())
    dispatch(getArbitrationPairs())
    dispatch(getArbitrationOpportunities())
    dispatch(getArbitrationStrategies())
  }

  return (
    <>
      <DashboardLayout>
        <WalletsAddedGuard>
          <SoftBox id="arbitrationSection">
            <RefreshButton isLoading={false} action={fetchArbitrationData} />
            <TabSelector tabValue={tabValue} setTabValue={setTabValue} />
            {tabValue === "opportunities" && <Opportunities />}
            {tabValue === "strategies" && <Strategies />}
            {tabValue === "actions" && <Actions />}
          </SoftBox>
        </WalletsAddedGuard>
      </DashboardLayout>
    </>
  );
};

export default Arbitration;