import axios from "axios";
import { store } from "index";
import { BACK_API } from "apiconfig";

export const fetchVideos = async () => {
  const url = `${BACK_API}/videos`;
  const headers = {
    token: store.getState().currentUser.data.jwtToken,
    id: store.getState().currentUser.data.id,
  };

  return await axios
    .get(url, { headers })
    .then(({ data }) => data)
    .catch((err) => {
      throw err;
    });
};

export const getYouTubeVideoInfo = async (videos) => {
  const parseYouTubeUrl = (url) => {
    const regex =
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const parseISODuration = (isoDuration) => {
    const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
    const matches = isoDuration.match(regex);

    const hours = parseInt(matches[1] || 0, 10);
    const minutes = parseInt(matches[2] || 0, 10);
    const seconds = parseInt(matches[3] || 0, 10);

    const parsedTime = hours * 3600 + minutes * 60 + seconds;

    const m = Math.floor((parsedTime % 3600) / 60);
    const s = Math.floor(parsedTime % 60);

    const mm = m.toString().padStart(2, "0");
    const ss = s.toString().padStart(2, "0");

    return `${mm}:${ss}`;
  };

  try {
    return await Promise.all(
      videos.map(async ({ id, title, url, create_date, section }) => {
        const videoId = parseYouTubeUrl(url);
        if (videoId) {
          const api_url = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}&part=snippet,contentDetails`;
          const response = await axios.get(api_url);
          const video = response.data.items[0];
          const duration = parseISODuration(video.contentDetails.duration);
          const author = video.snippet.channelTitle;
          return { id, title, duration, url, author, create_date, section };
        } else {
          throw "Invalid YouTube URL";
        }
      })
    );
  } catch (error) {
    throw error;
  }
};

export async function addVideo({ title, url, section }) {
  const parsedBody = { title, url, section };
  const apiUrl = `${BACK_API}/videos`;
  const headers = {
    token: store.getState().currentUser.data.jwtToken,
    id: store.getState().currentUser.data.id,
  };
  return await axios
    .post(apiUrl, parsedBody, { headers })
    .then(({ data }) => data);
}

export async function updateVideo({ id, title, url, section }) {
  const parsedBody = { title, url, section };
  const apiUrl = `${BACK_API}/videos/${id}`;
  const headers = {
    token: store.getState().currentUser.data.jwtToken,
    id: store.getState().currentUser.data.id,
  };
  return await axios
    .put(apiUrl, parsedBody, { headers })
    .then(({ data }) => data);
}

export async function deleteVideo(videoId) {
  const apiUrl = `${BACK_API}/videos/${videoId}`;
  const headers = {
    token: store.getState().currentUser.data.jwtToken,
    id: store.getState().currentUser.data.id,
  };
  return await axios.delete(apiUrl, { headers }).then(({ data }) => data);
}
