// react-router-dom components
import { Link } from "react-router-dom";

// Soft UI Dashboard React component
import SoftTypography from 'components/SoftTypography';

// Constants
import { ROUTES } from "constants";

// Translate
import { useTranslation } from 'react-i18next';

export default function BasicLink({ route, label, handleClick }) {
    const { t } = useTranslation();

    return (
        <SoftTypography
            component={Link}
            to={route}
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
            onClick={handleClick}
        >
            {t(label)}
        </SoftTypography>
    );
};

// Setting default values for the props of CoverLayout
BasicLink.defaultProps = {
    route: ROUTES.LOGIN,
    label: "Sign in",
};