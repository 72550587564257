import { fetchPricing } from "api/pricing";
import { SET_PRICING } from "constants/actionTypes";
import { PRICING_STATUS } from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";

/***** Redux actions that end up in the store *****/
export const getPricing = () => async (dispatch) => {
  try {
    dispatch({ type: PRICING_STATUS, payload: STATUS.LOADING });
    const data = await fetchPricing();
    dispatch({ type: SET_PRICING, payload: data });
    dispatch({ type: PRICING_STATUS, payload: STATUS.OK });
  } catch (error) {
    dispatch({ type: PRICING_STATUS, payload: STATUS.ERROR });
  }
};
