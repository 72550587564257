import { combineReducers } from "redux";

import wallets from "./wallets";
import orders from "./orders";
import currentUser from "./currentUser";
import arbitration from "./arbitration";
import keys from "./keys";
import informativeMessage from "./informativeMessage";
import users from "./users";
import favPairs from "./favPairs";
import toast from "./toast";
import notifications from "./notifications";
import news from "./news";
import videos from "./videos";
import messages from "./messages";
import pricing from "./pricing";
import bots from "./bots";

const appReducer = combineReducers({
  wallets,
  orders,
  currentUser,
  arbitration,
  keys,
  informativeMessage,
  users,
  favPairs,
  toast,
  notifications,
  news,
  videos,
  messages,
  pricing,
  bots,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") return appReducer(undefined, action);
  return appReducer(state, action);
};

export default rootReducer;
