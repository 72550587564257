import axios from "axios"
import { CRYPTOCOMPARE_API_KEY } from "apiconfig";

export async function fetchNews() {
    const esUrl = `https://min-api.cryptocompare.com/data/v2/news/?lang=ES&api_key=${CRYPTOCOMPARE_API_KEY}`;
    const enUrl = `https://min-api.cryptocompare.com/data/v2/news/?lang=EN&api_key=${CRYPTOCOMPARE_API_KEY}`;

    const es = await axios
        .get(esUrl)
        .then(({ data }) => data.Data)
        .catch((err) => {
            console.error(err);
            return [];
        });

    const en = await axios
        .get(enUrl)
        .then(({ data }) => data.Data)
        .catch((err) => {
            console.error(err);
            return [];
        });

    return { es, en }
}