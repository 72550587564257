// Soft UI Dashboard React base styles
import { Card } from "@mui/material";
// Data
import { CryptoCoinsHeatmap } from "react-ts-tradingview-widgets";

export default function MarketData() {
    return (
        <Card style={{ backgroundColor: "black", height: "100%" }}>
            <CryptoCoinsHeatmap height="343" colorTheme="dark"></CryptoCoinsHeatmap>
        </Card>
    )
}

