// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Translate
import { useTranslation } from "react-i18next";

export default function SaveButton({ handleSubmit }) {
  const { t } = useTranslation();
  return (
    <Grid item xs={12} md={6} xl={6}>
      <SoftButton
        onClick={handleSubmit}
        variant="gradient"
        color="info"
        size="small"
        fullWidth
      >
        <SoftTypography variant="button" color="white" fontWeight="medium">
          {t("Save API Keys")}
        </SoftTypography>
      </SoftButton>
    </Grid>
  );
}
