// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React icons
import Document from "examples/Icons/Document";
import Cube from "examples/Icons/Cube";

// Translate
import { useTranslation } from 'react-i18next';

function SidenavProfile({ setTabValue }) {
    const { t } = useTranslation();

    const sidenavItems = [
        { icon: <Document />, label: "basic information", value: 0 },
        { icon: <Cube />, label: "change password", value: 1 },
    ];

    const handleSetTabValue = (newValue) => setTabValue(newValue);

    const renderSidenavItems = sidenavItems.map(({ icon, label, value }, key) => {
        const itemKey = `item-${key}`;

        return (
            <SoftBox key={itemKey} component="li" pt={key === 0 ? 0 : 1} onClick={() => handleSetTabValue(value)} sx={{ cursor: "pointer" }}>
                <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize"
                    sx={({
                        borders: { borderRadius },
                        functions: { pxToRem },
                        palette: { light },
                        transitions,
                    }) => ({
                        display: "flex",
                        alignItems: "center",
                        borderRadius: borderRadius.md,
                        padding: `${pxToRem(10)} ${pxToRem(16)}`,
                        transition: transitions.create("background-color", {
                            easing: transitions.easing.easeInOut,
                            duration: transitions.duration.shorter,
                        }),

                        "&:hover": {
                            backgroundColor: light.main,
                        },
                    })}
                >
                    <SoftBox mr={1.5} lineHeight={1}>
                        {icon}
                    </SoftBox>
                    {t(label)}
                </SoftTypography>
            </SoftBox>
        );
    });

    return (
        <Card
            sx={{
                borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
                position: "sticky",
                top: "1%",
            }}
        >
            <SoftBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={2}
                m={0}
                sx={{ listStyle: "none" }}
            >
                {renderSidenavItems}
            </SoftBox>
        </Card>
    );
}

export default SidenavProfile;
