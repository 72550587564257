// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftButton from "components/SoftButton";

// @mui material components
import CircularProgress from '@mui/material/CircularProgress';

// Translate
import { useTranslation } from 'react-i18next';

export default function ActionButton({ loading, label, color, handle }) {
    const { t } = useTranslation();
    return (
        <SoftButton
            variant="gradient"
            color={color}
            type="submit"
            disabled={loading}
            onClick={handle}
            fullWidth
        >
            {loading ? <CircularProgress color="inherit" size={15} /> : t(label)}
        </SoftButton>
    );
};

// Setting default values for the props of CoverLayout
ActionButton.defaultProps = {
    loading: false,
    label: "action",
    color: "info"
};

// Typechecking props for the CoverLayout
ActionButton.propTypes = {
    loading: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
};