// Soft UI Dashboard React base styles
import { Card } from "@mui/material";
// Data
import { CryptoCoinsHeatmap } from "react-ts-tradingview-widgets";

export default function HeatMap() {
    return (
        <Card className="heatmap">
            <CryptoCoinsHeatmap height="500" colorTheme="dark"></CryptoCoinsHeatmap>
        </Card>
    )
}
