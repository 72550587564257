import { USER_STATUS } from "constants";
import { userForm } from "../../schemas/form";
import { timestampToDateTime } from "utils/timestampToDateTime";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";

// Translate
import { useTranslation } from "react-i18next";

export default function UsersInput({ label, value, handle }) {
    const { t } = useTranslation();
    let renderInput = null;

    const statusOptions = [
        { label: t("Registered"), value: USER_STATUS.REGISTERED },
        { label: t("Activated"), value: USER_STATUS.ACTIVATED },
    ];

    const fullAccessOptions = [
        { label: t("Yes"), value: true },
        { label: t("No"), value: false },
    ];

    const handleStatus = ({ value }) => {
        handle(label, value)
    }

    const handleFullAccess = ({ value }) => {
        handle(label, value)
    }

    switch (label) {
        case "status":
            renderInput = (
                <SoftSelect
                    options={statusOptions}
                    defaultValue={[...statusOptions].find(
                        (option) => option.value === value
                    )}
                    menuPosition="fixed"
                    onChange={handleStatus}
                    autoFocus
                />
            );
            break;

        case "fullAccess":
            renderInput = (
                <SoftSelect
                    options={fullAccessOptions}
                    defaultValue={[...fullAccessOptions].find(
                        (option) => option.value === value
                    )}
                    menuPosition="fixed"
                    onChange={handleFullAccess}
                />
            );
            break;

        case "registerDate":
            renderInput = (
                <SoftInput
                    value={timestampToDateTime(value)}
                    disabled
                />
            );
            break;

        default:
            renderInput = (
                <SoftInput
                    value={value}
                    disabled
                />
            );
            break;
    }

    return (
        <SoftBox mb={1.5}>
            <SoftBox
                mb={1}
                ml={0.5}
                lineHeight={0}
                display="inline-block"
            >
                <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                >
                    {t(userForm[label])}
                </SoftTypography>
            </SoftBox>
            {renderInput}
        </SoftBox>
    )
}
