import axios from "axios"
import { BACK_API } from "apiconfig"
import { store } from "index";

const API = axios.create({ baseURL: BACK_API })

export const fetchWallet = body => {
    const headers = {
        'token': store.getState().currentUser.data.jwtToken,
        'id': store.getState().currentUser.data.id
    };
    return API.post("/balance", body, { headers: headers })
}