import axios from "axios";
import { BACK_API } from "apiconfig";
import { store } from "index";

export const sendEmailContactUs = async (contactUsData) => {
  const headers = {
    token: store.getState().currentUser.data.jwtToken,
    id: store.getState().currentUser.data.id,
  };
  const url = `${BACK_API}/contact`;

  return axios.post(url, contactUsData, { headers });
};
