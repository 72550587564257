import ReportsDoughnutChart from "examples/Charts/DoughnutCharts/ReportsDoughnutChart";
import Loader from "components/Loader";
import {
    Chart as ChartJS,
    BarElement,
    Tooltip,
    Legend,
    CategoryScale,
    ArcElement
} from "chart.js"
ChartJS.register(
    BarElement,
    Tooltip,
    Legend,
    CategoryScale,
    ArcElement
)

export default function SummaryChartPie({ fullSelectedWallet, walletStatus }) {
    const pairs = fullSelectedWallet.pairs
    const limitedPairs = pairs.slice(0, 5)
    return (
        <ReportsDoughnutChart
            title="Distribution"
            count={{ number: fullSelectedWallet.walletBaseCoinValue > 0 ? `${fullSelectedWallet.walletBaseCoinValue}$` : <Loader />, text: "Total" }}
            status={walletStatus}
            chart={{
                labels: limitedPairs.map(pair => pair.symbol),
                datasets: {
                    label: "Value",
                    backgroundColors: ["primary", "secondary", "info", "success", "warning"],
                    data: limitedPairs.map(pair => `${(pair.baseCoinValue / fullSelectedWallet.walletBaseCoinValue * 100).toFixed(2)}`),
                }
            }}
        />
    )
}