import { updateKeysActive } from "api/keys";
import { updateKeys, fetchKeys } from "api/keys";
import { SET_KEYS, SET_KEYS_COUNT, UPDATE_KEY_ACTIVE, SET_KEYS_STATUS, ADD_OR_UPDATE_EXCHANGE_KEYS, SET_EXCHANGE_KEYS_STATUS } from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";
import { getOrdersFromExchange, getOrdersFromExchanges } from "./orders";
import { getWallet, getWallets } from "./wallets";

/***** Redux actions that end up in the store *****/
export const getKeys = () => async dispatch => {
    try {
        dispatch({ type: SET_KEYS_STATUS, payload: STATUS.LOADING });
        const data = await fetchKeys();
        dispatch({ type: SET_KEYS, payload: data });
        dispatch({ type: SET_KEYS_COUNT, payload: data.length });
        dispatch({ type: SET_KEYS_STATUS, payload: STATUS.OK });
        dispatch(getOrdersFromExchanges())
        dispatch(getWallets())
    } catch (error) {
        dispatch({ type: SET_KEYS_STATUS, payload: STATUS.ERROR });
    };
};

export const saveKeys = (privateKey, publicKey, password, exchange) => async dispatch => {
    try {
        dispatch({ type: SET_EXCHANGE_KEYS_STATUS, payload: { status: STATUS.LOADING, exchange } });
        const data = await updateKeys(privateKey, publicKey, password, exchange);
        dispatch({ type: ADD_OR_UPDATE_EXCHANGE_KEYS, payload: data });
        dispatch({ type: SET_EXCHANGE_KEYS_STATUS, payload: { status: STATUS.OK, exchange } });
        dispatch(getOrdersFromExchange(exchange))
        dispatch(getWallet(exchange))
    } catch (error) {
        dispatch({ type: SET_EXCHANGE_KEYS_STATUS, payload: { status: STATUS.ERROR, exchange } });
    };
};

export const toggleKeysActive = (exchange, active) => async dispatch => {
    try {
        dispatch({ type: SET_EXCHANGE_KEYS_STATUS, payload: { status: STATUS.LOADING, exchange } });
        const data = await updateKeysActive(exchange, active);
        dispatch({ type: UPDATE_KEY_ACTIVE, payload: { active: data, exchange } });
        dispatch({ type: SET_EXCHANGE_KEYS_STATUS, payload: { status: STATUS.OK, exchange } });
        dispatch(getOrdersFromExchange(exchange))
        dispatch(getWallet(exchange))
    } catch (error) {
        dispatch({ type: SET_EXCHANGE_KEYS_STATUS, payload: { status: STATUS.ERROR, exchange } });
        dispatch({ type: SET_KEYS_STATUS, payload: STATUS.ERROR });
    };
};
