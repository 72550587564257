import { useState } from "react";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";

// Admin React components
import UsersTab from "./tabs/users";
import MessagesTab from "./tabs/messages";
import VideosTab from "./tabs/videos";
import Header from "./Components/Header";

export default function Admin() {
  const [tabValue, setTabValue] = useState("users");
  const getCurrentTab = () => {
    switch (tabValue) {
      case "users":
        return <UsersTab />;
      case "messages":
        return <MessagesTab />;
      case "videos":
        return <VideosTab />;
      default:
        return <UsersTab />;
    }
  };

  return (
    <DashboardLayout>
      <SoftBox id="adminSection">
        <Header tabValue={tabValue} setTabValue={setTabValue} />
        {getCurrentTab()}
      </SoftBox>
    </DashboardLayout>
  );
}
