import PropTypes from 'prop-types'
import Icon from "@mui/material/Icon";
import colors from "assets/theme/base/colors";

function NavigationVideos({ prev, handle }) {
    return (
        <Icon
            sx={{
                color: colors.white.main,
                transform: "scale(1.5)",
                cursor: "pointer",
                "&:hover": {
                    transform: "scale(1.7)",
                },
            }}
            onClick={handle}
        >
            {prev ? "skip_previous" : "skip_next"}
        </Icon>
    )
}

NavigationVideos.propTypes = {
    prev: PropTypes.bool,
    handle: PropTypes.func
}

export default NavigationVideos
