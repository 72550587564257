import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import {
    Chart as ChartJS,
    BarElement,
    Tooltip,
    LinearScale,
    Legend,
    CategoryScale,
    ArcElement
} from "chart.js"
ChartJS.register(
    BarElement,
    Tooltip,
    LinearScale,
    Legend,
    CategoryScale,
    ArcElement
)

export default function SummaryChartBars({ fullSelectedWallet, walletStatus }) {
    const pairs = fullSelectedWallet.pairs
    const limitedPairs = pairs.slice(0, 10)
    return (
        <VerticalBarChart
            title="Fiat value"
            status={walletStatus}
            chart={{
                labels: limitedPairs.map(pair => pair.symbol),
                datasets: [{
                    label: "Value",
                    color: "dark",
                    data: limitedPairs.map(pair => pair.baseCoinValue),
                }],
            }}
        />
    )
}