import { CLIENT_NAME, ENV } from "apiconfig";
import { ENV_TYPES } from "constants";
import * as Sentry from "@sentry/react";

export const runSentry = () => {
    if (ENV === ENV_TYPES.PRO) {
        Sentry.init({
            dsn: "https://b2b29ef9d7330f25d5cac5817915750d@o4505770311745536.ingest.sentry.io/4505833409085440",
            integrations: [
                new Sentry.BrowserTracing(),
                new Sentry.Replay(),
            ],
            environment: CLIENT_NAME,
            // Performance Monitoring
            tracesSampleRate: 1, // Capture 100% of the transactions, reduce in production!
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
}