import { useState } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React component
import SoftInput from "components/SoftInput";

// Utils
import { visibilityIcon } from 'examples/Icons/VisibilityIcon';

// Translate
import { useTranslation } from 'react-i18next';

export default function PasswordInputToggle({ onChange, placeholder, name }) {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    // Show - Hiden password
    const handleClickShowPassword = () => {
        setShowPassword((show) => !show);
        onChange({ target: { name: "showPassword", value: !showPassword } })
    };

    return (
        <SoftInput
            name={name}
            type={showPassword ? "text" : "password"}
            placeholder={t(placeholder)}
            onChange={onChange}
            icon={visibilityIcon(showPassword, handleClickShowPassword)}
            required
        />
    );
};

// Setting default values for the props of CoverLayout
PasswordInputToggle.defaultProps = {
    placeholder: "Password",
    name: "password"
};

// Typechecking props for the CoverLayout
PasswordInputToggle.propTypes = {
    onChange: PropTypes.func.isRequired,
};