/***** We receive an action and set de data to the store *****/

import {
  SET_CURRENT_USER,
  SET_CURRENT_LANGUAGE_USER,
  SET_SUBSCRIPTION_STATUS_USER,
  SET_CURRENT_USER_STATUS,
  SET_REQUIRE_TWO_FA_USER,
  SET_REQUIRE_TOURS_USER
} from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";

export default (currentUser = { data: [], status: STATUS.PENDING }, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return { data: action.payload };
    case SET_CURRENT_LANGUAGE_USER:
      return {
        ...currentUser,
        data: { ...currentUser.data, language: action.payload },
      };
    case SET_REQUIRE_TWO_FA_USER:
      return {
        ...currentUser,
        data: { ...currentUser.data, require_two_fa: action.payload },
      };
    case SET_REQUIRE_TOURS_USER:
      return {
        ...currentUser,
        data: { ...currentUser.data, tours: action.payload },
      };
    case SET_SUBSCRIPTION_STATUS_USER:
      return {
        ...currentUser,
        data: { ...currentUser.data, subscription_status: action.payload },
      };
    case SET_CURRENT_USER_STATUS:
      return { ...currentUser, status: action.payload };
    default:
      return currentUser;
  }
};
