import { fetchNotifications, updateNotificationsAsRead } from "api/notifications";
import { SET_NOTIFICATIONS } from "constants/actionTypes";

/***** Redux actions that end up in the store *****/
export const getNotifications = () => async dispatch => {
    const data = await fetchNotifications();
    dispatch({ type: SET_NOTIFICATIONS, payload: data });
};

export const setNotificationsAsRead = () => async dispatch => {
    const data = await updateNotificationsAsRead();
    dispatch({ type: SET_NOTIFICATIONS, payload: data });
};
