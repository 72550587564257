import axios from "axios"
import { BACK_API } from "apiconfig";
import { store } from "index";

export const fetchReferralEarnings = async userId => {
    const headers = {
        'token': store.getState().currentUser.data.jwtToken,
        'id': store.getState().currentUser.data.id
    };
    const url = `${BACK_API}/referral-earnings`;
    const params = {
        userId: userId
    };

    return await axios
        .get(url, { params, headers })
        .then(res => res.data)
        .catch(err => { throw err })
}