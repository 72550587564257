import axios from "axios";
import { unlockProtocolActions } from "./contract";
import { CLIENT_NAME } from "apiconfig";
import { BACK_API } from "apiconfig";
import clientConfig from "utils/clientConfig";
import { store } from "index";
import { setCurrentUser } from "redux/actions/currentUser";
import { setInformativeMessage } from "redux/actions/informativeMessage";
import { ROUTES } from "constants";
import { loginActions } from "redux/actions/currentUser";

export async function registerUser(user) {
  const {
    referralCode,
    username,
    password,
    email,
    nombreCompleto,
    phoneNumber,
    country,
    userSystemLanguage,
  } = user;

  const url = `${BACK_API}/register`;
  const body = {
    referred_from: referralCode,
    email: email.trim(),
    password,
    username: username.trim(),
    fullName: nombreCompleto.trim(),
    phone: phoneNumber,
    country,
    client: CLIENT_NAME,
    userSystemLanguage,
  };

  return await axios
    .post(url, body)
    .then(async () => {
      return {
        success: true,
        data: clientConfig.useUnlock
          ? `User successfully created. Log in to get a contract.`
          : `User successfully created. Check your email for verification link.`,
      };
    })
    .catch(({ response }) => {
      return { success: false, data: response.data.error };
    });
}

export const signInWithUnlock = async (sesion) => {
  const { email, password, rememberMe } = sesion;

  const url = `${BACK_API}/verify`;
  const body = {
    email,
    password,
    rememberMe,
  };

  return await axios
    .post(url, body)
    .then(async ({ data }) => {
      return unlockProtocolActions(data).then((res) => res);
    })
    .catch(({ response }) => {
      return { success: false, data: response.data.error };
    });
};

export const signIn = async (sesion) => {
  const { email, password, rememberMe } = sesion;

  const url = `${BACK_API}/verify`;
  const body = {
    email,
    password,
    rememberMe,
  };

  return await axios
    .post(url, body)
    .then(({ data }) => {
      const { require_two_fa } = data;
      if (require_two_fa) {
        store.dispatch(setCurrentUser({ twoFA: data.twoFA }));
        return ROUTES.TWOFA;
      }
      store.dispatch(loginActions(data));
      return ROUTES.HOME;
    })
    .catch(({ response }) => {
      store.dispatch(
        setInformativeMessage({
          success: false,
          message: response.data.error,
        })
      );
    });
};

export const loginUser = async (userCode) => {
  const { jwtToken } = store.getState().currentUser.data.twoFA;
  const url = `${BACK_API}/login`;
  const body = {
    jwtToken,
    userCode,
  };

  return await axios
    .post(url, body)
    .then(({ data }) => {
      store.dispatch(loginActions(data));
      return ROUTES.HOME;
    })
    .catch(() => {
      store.dispatch(
        setInformativeMessage({
          success: false,
          message: "Invalid 2FA code, please try again.",
        })
      );
    });
};

export async function forgotPassword(email) {
  const url = `${BACK_API}/forgot-password`;
  const body = {
    email,
  };
  return await axios
    .post(url, body)
    .then(() => {
      return {
        success: true,
        data: "You will receive a password recovery link at your email address in a few minutes.",
      };
    })
    .catch(({ response }) => {
      return { success: false, data: response.data.error };
    });
}

export async function resetPassword(token, newPassword) {
  const url = `${BACK_API}/reset-password`;
  const body = {
    token,
    newPassword,
  };
  return await axios
    .post(url, body)
    .then(() => {
      return {
        success: true,
        data: `Your password has been reset.`,
      };
    })
    .catch(({ response }) => {
      return { success: false, data: response.data.error };
    });
}
