import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// Translate
import { useTranslation } from 'react-i18next';

const { gradients } = colors;

const GradientTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: gradients.info.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        background: linearGradient(gradients.info.main, gradients.info.state),
        maxWidth: 300,
    },
}));

export default function ProfitInput({ inputValue, setStrategy }) {
    const { t } = useTranslation();

    const handledChange = ({ target }) => {
        const { value } = target;
        setStrategy(prev => ({ ...prev, profit: value }));
    };

    return (
        <SoftBox id="profitInput" mb={3}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                >
                    {t('profit')}
                    <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                        color='error'
                    >
                        *
                    </SoftTypography>
                </SoftTypography>
            </SoftBox>
            <GradientTooltip title={t("Please enter a percentage of at least 0.5% to ensure fees do not impact profitability.")} placement="top">
                <SoftInput
                    className="inputs"
                    name="profit"
                    size="small"
                    type="number"
                    placeholder={t('%profit')}
                    value={inputValue || ""}
                    onChange={handledChange}
                    inputProps={{ min: 0, max: 100, step: 0.01 }}
                    required
                />
            </GradientTooltip>
        </SoftBox>
    );
}
