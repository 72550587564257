// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

export const editForm = {
    fullName: {
        key: uuidv4(),
        name: "fullName",
        label: "full name",
        type: "text",
        placeholder: "full name",
        required: true,
    },
    email: {
        key: uuidv4(),
        name: "email",
        label: "email",
        type: "email",
        placeholder: "email",
        required: true,
    },
    username: {
        key: uuidv4(),
        name: "username",
        label: "username",
        type: "text",
        placeholder: "username",
        required: true,
    },
    password: {
        key: uuidv4(),
        name: "password",
        label: "password",
        type: "password",
        placeholder: "password",
    },
    phone: {
        key: uuidv4(),
        name: "phone",
        label: "phone",
        type: "number",
        placeholder: "phone",
        required: true,
    },
    country: {
        key: uuidv4(),
        name: "country",
        label: "country",
        type: "text",
        placeholder: "country",
        required: true,
    },
};
