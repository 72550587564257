export const CLIENTS_CONFIG = [
    {
        name: "canviodayone-notrading",
        url: "https://canviodayone-notrading.tradingplatformcustom.com",
        email: "info@ctrading.io",
        emailName: "Canvio",
        domain: "tradingplatformcustom.com",
        useMatomo: false,
        matomoId: 0,
        matomoCookieDomain: "",
        useTrading: false,
        useArbitrage: false,
        useReferrals: false,
        needToApproveUsers: true,
        usePremiumModel: false,
        isDemo: false,
        useUnlock: false,
        unlockNetwork: "",
        unlockContracts: "",
        usePostHog: false,
        primaryColor: "#21d4fd",
        primaryColorGradientEnd: "#2152ff"
    },
    {
        name: "zion",
        url: "https://zion-app.tradingplatformcustom.com",
        email: "info@ctrading.io",
        emailName: "Zion",
        domain: "tradingplatformcustom.com",
        useMatomo: false,
        matomoId: 0,
        matomoCookieDomain: "",
        useTrading: true,
        useArbitrage: true,
        useReferrals: false,
        needToApproveUsers: true,
        usePremiumModel: false,
        isDemo: false,
        useUnlock: false,
        unlockNetwork: "",
        unlockContracts: "",
        usePostHog: false,
        primaryColor: "#21d4fd",
        primaryColorGradientEnd: "#2152ff"
    },
    {
        name: "academyx10",
        url: "https://academyx10.tradingplatformcustom.com",
        email: "info@ctrading.io",
        emailName: "AcademyX10",
        domain: "tradingplatformcustom.com",
        useMatomo: false,
        matomoId: 0,
        matomoCookieDomain: "",
        useTrading: true,
        useArbitrage: true,
        useReferrals: false,
        needToApproveUsers: true,
        usePremiumModel: false,
        isDemo: false,
        useUnlock: false,
        unlockNetwork: "",
        unlockContracts: "",
        usePostHog: false,
        primaryColor: "#750AFB",
        primaryColorGradientEnd: "#b070ff"
    }
]
