import { STATUS } from "constants/fetchStatus"
import { useSelector } from "react-redux"

export default function useAllWalletStatus() {
    const wallets = useSelector(state => state.wallets)
    let allWalletStatus = STATUS.PENDING
    const someWalletLoaded = Object.values(wallets).some(wallet => wallet.status === STATUS.OK)
    const someWalletLoading = Object.values(wallets).some(wallet => wallet.status !== STATUS.OK)
    const allWalletsLoaded = Object.values(wallets).every(wallet => wallet.status === STATUS.OK) && Object.values(wallets).length > 0;
    if (allWalletsLoaded) allWalletStatus = STATUS.OK
    if (!allWalletsLoaded && someWalletLoaded) allWalletStatus = STATUS.LOADING
    if (someWalletLoading) allWalletStatus = STATUS.LOADING
    return { allWalletStatus, someWalletLoaded }
}