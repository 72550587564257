import { fetchExchangePairs } from "api/pairs"
import { BASE_COIN } from "constants"
import { meaningfulAmountDecimals } from "./meaningfulAmountDecimals"

export default async function feedWalletWithPrices(wallet, exchange) {
    const getUSDTValues = (pairInfo, pair, total, locked, free, baseCoinValue, walletType) => {
        const price = 1
        if (baseCoinValue < 1) return null
        return {
            symbol: pair.symbol,
            total: meaningfulAmountDecimals(total, price),
            free: meaningfulAmountDecimals(free, price),
            locked: meaningfulAmountDecimals(locked, price),
            changePct24Hour: 0,
            exchange: pairInfo?.exchange || "",
            id: pairInfo?.id || -1,
            imageUrl: "/media/37746338/usdt.png",
            price,
            volume24Hour: pairInfo?.volume24Hour || 0,
            baseCoinValue: Number((total * price)?.toFixed(2)),
            walletType
        }
    }
    return fetchExchangePairs(exchange)
        .then(res => {
            const pairsInfo = res.data
            let walletBaseCoinValue = 0
            const feededWallet = wallet.map(pair => {
                const walletType = pair?.walletType || "spot";
                const pairInfo = pairsInfo.find(pairInfo => pairInfo.name === `${pair.symbol}-${BASE_COIN}`)
                const price = Number(pairInfo?.price) || 0
                const total = pair?.total || 0
                const free = pair?.free || 0
                const locked = pair?.locked || 0
                const baseCoinValue = Number((total * price)?.toFixed(2))
                walletBaseCoinValue += baseCoinValue
                if (pair.symbol === "USDT") return getUSDTValues(pairInfo, pair, total, locked, free, baseCoinValue, walletType)
                if (!price) return null
                if (baseCoinValue < 1) return null
                return {
                    symbol: pair.symbol,
                    total: meaningfulAmountDecimals(total, price),
                    free: meaningfulAmountDecimals(free, price),
                    locked: meaningfulAmountDecimals(locked, price),
                    changePct24Hour: Number(pairInfo?.changePct24Hour).toFixed(2) || 0,
                    exchange: pairInfo?.exchange || "",
                    id: pairInfo?.id || -1,
                    imageUrl: pairInfo?.imageUrl || "",
                    price,
                    volume24Hour: pairInfo?.volume24Hour || 0,
                    baseCoinValue,
                    walletType
                }
            })
            const filteredAndSortedFeededWallet = feededWallet
                .filter(pair => pair !== null)
                .sort((a, b) => b.baseCoinValue - a.baseCoinValue);

            return {
                pairs: filteredAndSortedFeededWallet,
                walletBaseCoinValue: walletBaseCoinValue.toFixed(2)
            };
        })
        .catch(err => console.log(err))
}
