import Loader from "components/Loader";
import SoftBox from "components/SoftBox"
import SoftTypography from "components/SoftTypography"
import { STATUS } from "constants/fetchStatus";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import CollapsibleTable from "./ActionsTable";

export default function Actions() {
    const { t } = useTranslation();
    const { data: actions, status } = useSelector(state => state.arbitration.actions)
    return <SoftBox my={2}>
        <SoftTypography fontSize="medium">
            {t('These are your triggered actions')}
        </SoftTypography>
        <SoftBox mt={2}>
            {status === STATUS.LOADING && <Loader />}
            {status === STATUS.ERROR && "Something failed"}
            {status === STATUS.OK && actions.length > 0 && <CollapsibleTable actions={actions} />}
            {status === STATUS.OK && actions.length === 0 && <SoftTypography>{t('No actions yet!')}</SoftTypography>}
        </SoftBox>
    </SoftBox>
}