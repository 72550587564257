import Date from "layouts/Common/Orders/components/TableItems/Date";
import ActionCell from "../Components/ActionCell";

export const getUsersDataTableData = (
  users = [],
  label = "",
  handleEdit,
  handleRemove
) => {
  const columns = [
    { Header: "id", accessor: "id" },
    { Header: "email", accessor: "email" },
    { Header: "user name", accessor: "userName" },
    { Header: "full name", accessor: "fullName" },
    { Header: "status", accessor: "status" },
    { Header: "phone", accessor: "phone" },
    { Header: "country", accessor: "country" },
    { Header: "register date", accessor: "registerDate" },
    { Header: "action", accessor: "action" },
  ];

  const rows = users.map((user) => ({
    id: user.id,
    email: user.email,
    userName: user.userName,
    fullName: user.fullName,
    status: user.status,
    phone: user.phone,
    country: user.country,
    registerDate: <Date timestamp={user.registerDate} />,
    action: (
      <ActionCell
        label={label}
        handleEdit={handleEdit}
        handleRemove={handleRemove}
        elmentData={user}
      />
    ),
  }));

  return { columns, rows };
};

export const getMessagesDataTableData = (
  messages = [],
  label = "",
  handleEdit,
  handleRemove
) => {
  const columns = [
    { Header: "id", accessor: "id" },
    { Header: "author", accessor: "author" },
    { Header: "content", accessor: "content" },
    { Header: "create date", accessor: "createDate" },
    { Header: "action", accessor: "action" },
  ];

  const rows = messages.map((message) => ({
    id: message.id,
    author: message.author,
    content: message.content,
    createDate: <Date timestamp={message.create_date} />,
    action: (
      <ActionCell
        label={label}
        handleEdit={handleEdit}
        handleRemove={handleRemove}
        elmentData={message}
      />
    ),
  }));

  return { columns, rows };
};

export const getVideosDataTableData = (
  videos = [],
  label = "",
  handleEdit,
  handleRemove
) => {
  const columns = [
    { Header: "id", accessor: "id" },
    { Header: "title", accessor: "title" },
    { Header: "url", accessor: "url" },
    { Header: "section", accessor: "section" },
    { Header: "create date", accessor: "createDate" },
    { Header: "action", accessor: "action" },
  ];

  const rows = videos.map((video) => ({
    id: video.id,
    title: video.title,
    url: video.url,
    section: video.section,
    createDate: <Date timestamp={video.create_date} />,
    action: (
      <ActionCell
        label={label}
        handleEdit={handleEdit}
        handleRemove={handleRemove}
        elmentData={video}
      />
    ),
  }));

  return { columns, rows };
};
