import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftButton from "components/SoftButton";

export default function AdminButton({
  children,
  variant = "gradient",
  color = "info",
  size = "small",
  ...rest
}) {
  return (
    <SoftButton variant={variant} color={color} size={size} {...rest}>
      {children}
    </SoftButton>
  );
}

AdminButton.propTypes = {
  children: PropTypes.node,
};
