import SoftTypography from "components/SoftTypography";
import DataTable from "examples/Tables/DataTable";
import Actions from "./Actions";
import CryptoIcon from "componentsCustom/CryptoIcon";
import { filterTradingPairs } from "utils/filterTradingPairs";
import { useSelector } from "react-redux";

export default function PairsTable({ unfilteredPairs, selectedExchange, tradePair, filterCriteria, favPairs }) {

    const wallets = useSelector(state => state.wallets)
    const pairs = filterTradingPairs(wallets, filterCriteria, unfilteredPairs, favPairs)
    
    const Change24hs = ({ number }) => {
        return <SoftTypography color={number > 0 ? "success" : "error"} className="table-percentage">
            {number} %
        </SoftTypography>
    }
    const Volume = ({ number }) => {
        return <SoftTypography className="table-number">
            {number} $
        </SoftTypography>
    }
    const Price = ({ price }) => {
        return <SoftTypography className="table-number">
            {price}
        </SoftTypography>
    }

    const Available = ({ available }) => {
        return <SoftTypography className="table-number">
            {available} $
        </SoftTypography>
    }

    const entriesPerPage = {
        defaultValue: 10,
        entries: [5, 10, 15, 50, 100]
    }

    const columns = [
        {
            Header: "actions",
            accessor: "actions.isFav",
            Cell: ({ row }) => <Actions pair={row.original.pair.name} tradePair={row.original.actions.tradePair} exchange={row.original.actions.selectedExchange} isFav={row.original.pair.isFav} />,
            width: "12%"
        },
        {
            Header: "pair",
            accessor: "pair.name",
            Cell: ({ row }) => <CryptoIcon pair={row.original.pair.name} imageUrl={row.original.pair.imageUrl} />
        },
        {
            Header: "change 24hs",
            accessor: "pair.changePct24Hour",
            Cell: ({ row }) => <Change24hs number={row.original.pair.changePct24Hour} />,
            width: "14%"
        },
        {
            Header: "price",
            accessor: "pair.price",
            Cell: ({ row }) => <Price price={row.original.pair.price} />,
        },
        {
            Header: "volume 24hs",
            accessor: "pair.volume24Hour",
            Cell: ({ row }) => <Volume number={row.original.pair.volume24Hour} />
        },
        {
            Header: "available",
            accessor: "pair.baseCoinValue",
            Cell: ({ row }) => <Available available={row.original.pair.baseCoinValue} />
        }
    ];

    const getTableData = () => {
        const rows = pairs.map(pair => {
            return {
                pair,
                actions: { pair, tradePair, selectedExchange, isFav: pair.isFav }
            };
        });
        return { columns, rows }
    }

    const tableData = getTableData()

    return (
        <>
            <DataTable
                entriesPerPage={entriesPerPage}
                showTotalEntries={true}
                isSorted={true}
                canSearch={true}
                table={tableData}
                defaultSortBy={"pair.baseCoinValue"} />
        </>
    )
}