import { fetchWallet } from "api/wallets"
import { WALLET_STATUS } from "constants/actionTypes"
import { SET_WALLET_DATA } from "constants/actionTypes"
import { STATUS } from "constants/fetchStatus"
import feedWalletWithPrices from "utils/feedWalletsWithPrices"
import { getActiveKeys } from "utils/getActiveKeys"
import { REMOVE_WALLET } from "constants/actionTypes"
import { CLEAR_WALLETS } from "constants/actionTypes"

/***** Redux actions that end up in the store *****/

const setExchangeData = (dispatch, exchange) => {
  const dispatchStatus = (wallet, status) => {
    dispatch({ type: WALLET_STATUS, payload: { status, wallet } })
  }
  const params = {
    public_key: exchange.publicKey,
    private_key: exchange.privateKey,
    password: exchange.password,
    exchange: exchange.exchange
  };

  dispatchStatus(exchange.exchange, STATUS.LOADING);

  // Returning the fetchWallet promise
  return fetchWallet(params)
    .then(res => {
      feedWalletWithPrices(res.data, exchange.exchange)
        .then(feededWallet => {
          dispatch({ type: SET_WALLET_DATA, payload: { wallet: exchange.exchange, data: feededWallet } });
          dispatchStatus(exchange.exchange, STATUS.OK);
        })
    })
    .catch(() => dispatchStatus(exchange.exchange, STATUS.ERROR));
}

export const getWallets = () => async dispatch => {
  const keys = getActiveKeys()
  keys.map(exchange => setExchangeData(dispatch, exchange));
  if (keys.length === 0) dispatch({ type: CLEAR_WALLETS })
}

export const getWallet = selectedExchange => async dispatch => {
  const exchangesKeys = getActiveKeys()
  const isWalletInRedux = exchangesKeys.some(exchangekey => exchangekey.exchange === selectedExchange)
  if (!isWalletInRedux) {
    // Remove added wallets if their keys were removed
    dispatch({ type: REMOVE_WALLET, payload: { exchange: selectedExchange } })
  } else {
    const selectedExchageKeys = exchangesKeys.find(exchange => exchange.exchange === selectedExchange)
    setExchangeData(dispatch, selectedExchageKeys)
  }
}
