// Soft UI Dashboard React component
import SoftTypography from 'components/SoftTypography';

// Example components
import BasicLink from './BasicLink';

// Constants
import { ROUTES } from "constants";

// Translate
import { useTranslation } from 'react-i18next';

export default function TextLink({ text, labelLink, route, handle }) {
    const { t } = useTranslation();
    return (
        <SoftTypography variant="button" color="text" fontWeight="regular">
            {t(text)}&nbsp;
            <BasicLink label={labelLink} route={route} handleClick={handle} />
        </SoftTypography>
    );
};

// Setting default values for the props of CoverLayout
TextLink.defaultProps = {
    text: "Already have an account?",
    route: ROUTES.LOGIN,
    labelLink: "Sign in",
};
