import { STATUS } from "constants/fetchStatus";
import { meaningfulAmountDecimals } from "./meaningfulAmountDecimals";

export default function getAllWallet(wallets) {
    const allPairs = [];
    let allWalletBaseCoinValue = 0

    // Flatten the pairs array
    Object.values(wallets).forEach(wallet => {
        allWalletBaseCoinValue += Number(wallet.walletBaseCoinValue)
        wallet.pairs?.forEach(pair => {
            allPairs.push(pair);
        })
    });

    // Create an object to store the accumulated values for each symbol
    const sumBySymbol = {};

    allPairs.forEach(pair => {

        const { symbol, baseCoinValue, total, free, locked, changePct24Hour, imageUrl, price } = pair;

        // Convert baseCoinValue and price to numbers
        const value = baseCoinValue;
        const priceValue = price;

        // Check if the symbol already exists in sumBySymbol
        if (sumBySymbol[symbol]) {
            // If it exists, update the values
            sumBySymbol[symbol].count += 1;
            sumBySymbol[symbol].baseCoinValue += value;
            sumBySymbol[symbol].total += total;
            sumBySymbol[symbol].free += free;
            sumBySymbol[symbol].locked += locked;
            sumBySymbol[symbol].changePct24Hour += Number(changePct24Hour);
            sumBySymbol[symbol].price += priceValue;
        } else {
            // If it doesn't exist, create a new entry
            sumBySymbol[symbol] = {
                count: 1,
                symbol,
                baseCoinValue: value,
                total,
                free,
                locked,
                changePct24Hour: Number(changePct24Hour),
                imageUrl,
                price: priceValue,
            };
        }
    });

    // Convert the accumulated values back into an array of objects
    const result = Object.values(sumBySymbol).map(entry => {
        const baseCoinValue = Number(entry.baseCoinValue)
        const formattedResult = {
            symbol: entry.symbol,
            total: meaningfulAmountDecimals(entry.total, entry.price),
            free: meaningfulAmountDecimals(entry.free, entry.price),
            locked: meaningfulAmountDecimals(entry.locked, entry.price),
            changePct24Hour: (entry.changePct24Hour / entry.count).toFixed(2),
            exchange: entry.exchange,
            id: entry.id,
            imageUrl: entry.imageUrl,
            name: entry.exchange,
            price: (entry.price / entry.count).toFixed(2),
            baseCoinValue: Number(baseCoinValue.toFixed(2))
        };

        return formattedResult;
    });

    // Sort the filtered result array by baseCoinValue
    result.sort((a, b) => parseFloat(b.baseCoinValue) - parseFloat(a.baseCoinValue));

    return ({
        all: {
            pairs: result,
            walletBaseCoinValue: allWalletBaseCoinValue.toFixed(2),
            status: STATUS.OK
        }
    });
}

