import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import ReactPlayer from "react-player";

import Avatar from "./Avatar";
import Action from "./Buttons/Action";
import NavigationVideos from "./Buttons/NavigationVideos";
import Close from "./Buttons/Close";
import Back from "./Buttons/Back";

export default function VideoPlaying({
  videos,
  playing,
  setPlaying,
  showPlayList,
  setShowPlayList,
  currentVideo,
  setCurrentVideo,
  toggleVideoPlaying,
}) {
  const [progress, setProgress] = useState(0);
  const { data } = videos;

  const handlePrev = () => {
    data.forEach(({ url }, index) => {
      if (url === currentVideo) {
        const videoData = data[index - 1] || data[data.length - 1];
        setPlaying(false);
        setProgress(0);
        setCurrentVideo(videoData.url);
      }
    });
  };

  const handleNext = () => {
    data.forEach(({ url }, index) => {
      if (url === currentVideo) {
        const videoData = data[index + 1] || data[0];
        setPlaying(false);
        setProgress(0);
        setCurrentVideo(videoData.url);
      }
    });
  };

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox
        height="100%"
        bgColor="info"
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <SoftBox height="100%" sx={{ position: "relative" }}>
          <SoftBox
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              position: "absolute",
              px: "0.5vw",
              py: "1vh",
            }}
          >
            <Back
              handle={() => {
                setShowPlayList(!showPlayList);
                setPlaying(false);
              }}
            />
            <Close handle={toggleVideoPlaying} />
          </SoftBox>
          <ReactPlayer
            controls
            playing={playing}
            url={currentVideo}
            width="100%"
            height="100%"
            onReady={() => setPlaying(true)}
            onProgress={({ played }) => playing && setProgress(played * 100)}
            onEnded={handleNext}
          />
        </SoftBox>
        <SoftBox py="0.4rem" pl="0.4rem" pr="2rem" display="flex" justifyContent="space-between" height="4rem">
          <Avatar />
          <SoftBox
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap={4}
          >
            <SoftBox display="flex" alignItems="center" gap={2}>
              <NavigationVideos prev handle={handlePrev} />
              <NavigationVideos handle={handleNext} />
            </SoftBox>
            <Action progress={progress} playing={playing} handle={() => setPlaying(!playing)} />
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}
