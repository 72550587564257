import { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'

// Translate
import { useTranslation } from "react-i18next";

const videos = {
    en: "https://www.youtube.com/watch?v=s-uU6kqH7ks",
    es: "https://www.youtube.com/watch?v=s-uU6kqH7ks"
}

const TourVideo = ({ children, ...props }) => {
    const { t } = useTranslation();
    const { language } = useSelector((state) => state.currentUser.data);
    const [videoUrl, setVideoUrl] = useState(videos.en);

    useEffect(() => {
        setVideoUrl(videos[language] || videos.en)
    }, [language])

    return (
        <SoftBox height={500} width={800} mb="2rem">
            <SoftTypography component="span" variant="h2" color="dark" mb="20rem">
                {t(children)}
            </SoftTypography>
            <ReactPlayer
                controls
                playing
                width="100%"
                height="100%"
                url={videoUrl}
                {...props}
            />
        </SoftBox>
    )
}

export default TourVideo