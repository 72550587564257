// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// Translate
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import OpportunitiesTable from "layouts/Common/Arbitration/components/Opportunities/OpportunitiesTable";

function Projects() {
  const { t } = useTranslation();
  const opportunities = useSelector(
    (state) => state.arbitration.opportunities.data
  );

  return (
    <SoftBox display="flex" justifyContent="center" alignItems="center" p={1}>
      <SoftBox id="arbitrage">
        <SoftTypography variant="h6" gutterBottom>
          {t(`Last arbitrage opportunity found`)}
        </SoftTypography>
        {opportunities.length > 0 && (
          <OpportunitiesTable opportunities={[opportunities[0]]} />
        )}
      </SoftBox>
    </SoftBox>
  );
}

export default Projects;
