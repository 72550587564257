// @mui material components
import { useSelector } from 'react-redux';
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
// Components
import Data from "./Data";
import Explanation from "./Explanation";
import ReferralsTable from "./ReferralsTable";

export default function ReferralTab() {

    const { data: currentUser } = useSelector(state => state.currentUser)
    const referrals = currentUser.referrals
    
    return (
        <SoftBox mt={5} mb={3}>
            <Explanation />
            <Data referrals={referrals} />
            <ReferralsTable referrals={referrals} />
        </SoftBox>
    )
}

