import { useState, useEffect } from "react";

import { useSelector } from "react-redux";

// react-router-dom components
import { useLocation, NavLink, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard React examples
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Soft UI Dashboard React context
import { useSoftUIController, setMiniSidenav } from "context";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SidenavVideoCard from "./SidenavVideoCard";

// Constans
import { ROUTES } from "constants";

// Translate
import { useTranslation } from "react-i18next";
import clientConfig from "utils/clientConfig";
import useIsUserFreemium from "utils/useUserSubscriptionStatus";

function Sidenav({
  isVideoPlaying,
  toggleVideoPlaying,
  selectedVideo,
  setSelectedVideo,
  videosData,
  color,
  brand,
  brandName,
  routes,
  ...rest
}) {
  const { t } = useTranslation();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const [anchorEl, setAnchorEl] = useState(null);
  const isUserFreemium = useIsUserFreemium();
  const open = Boolean(anchorEl);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, route, href, tab }) => {
      let returnValue;
      if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            state={{ tab }}
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              color={color}
              name={t(name)}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink to={route} key={key} state={{ tab }}>
            <SidenavCollapse
              color={color}
              key={key}
              name={t(name)}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </NavLink>
        );
      } else if (type === "dropdown") {
        returnValue = (
          <>
            <NavLink
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleOpen}
              to={route}
              key={key}
              state={{ tab }}
            >
              <SidenavCollapse
                color={color}
                key={key}
                name={t(name)}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </NavLink>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              style={{ marginTop: 28, marginLeft: 16 }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertial: "top",
                horizontal: "left",
              }}
            >
              <MenuItem component={"a"} onClick={handleClose} href={"/profile"}>
                Profile
              </MenuItem>
              <MenuItem
                component={"a"}
                onClick={handleClose}
                href={"/auth/login"}
              >
                Log out
              </MenuItem>
            </Menu>
          </>
        );
      } else if (type === "title") {
        returnValue = (
          <SoftTypography
            key={key}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            opacity={0.6}
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </SoftTypography>
        );
      } else if (type === "divider") {
        returnValue = <Divider key={key} />;
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, miniSidenav }}
    >
      <SoftBox pt={3} pb={1} px={4} textAlign="center">
        <SoftBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <SoftTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </SoftTypography>
        </SoftBox>
        {clientConfig.isDemo && (
          <SoftBox
            component={NavLink}
            to="/"
            display="flex"
            alignItems="center"
          >
            {brand && (
              <SoftBox
                component="img"
                src={brand}
                alt="Soft UI Logo"
                width="2rem"
              />
            )}
            <SoftBox
              width={!brandName && "100%"}
              sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
            >
              <SoftTypography
                component="h6"
                variant="button"
                fontWeight="medium"
                sx={{ color: "#d68128" }}
              >
                {brandName}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        )}
      </SoftBox>
      {clientConfig.isDemo && <Divider />}
      <List>{renderRoutes}</List>
      <Divider />
      <SoftBox id="videos" mx={2}>
        <SidenavVideoCard
          isVideoPlaying={isVideoPlaying}
          toggleVideoPlaying={toggleVideoPlaying}
          selectedVideo={selectedVideo}
          setSelectedVideo={setSelectedVideo}
          videosData={videosData}
        />
      </SoftBox>
      {isUserFreemium && (
        <>
          <Divider />
          <SoftButton
            component={Link}
            to={ROUTES.PROFILE}
            variant="gradient"
            color="dark"
            fullWidth
            state={{ tab: "plans" }}
          >
            {t("upgrade to premium")}
          </SoftButton>
        </>
      )}
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
