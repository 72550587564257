/***** We receive an action and set de data to the store *****/

import { SET_WALLET_DATA, WALLET_STATUS, REMOVE_WALLET, CLEAR_WALLETS } from "constants/actionTypes"
import { STATUS } from "constants/fetchStatus"
import getAllWallet from "utils/getAllWallet"

export default (wallets = [], action) => {
    switch (action.type) {
        case CLEAR_WALLETS:
            return {
                all: {
                    pairs: [],
                    walletBaseCoinValue: "0",
                    status: STATUS.OK
                }
            }
        case SET_WALLET_DATA:
            const updatedWallets = {
                ...wallets,
                [action.payload.wallet]: {
                    ...wallets[action.payload.wallet],
                    ...action.payload.data
                }
            }
            // Create a new object excluding the "all" wallet
            const walletsWithoutAll = Object.keys(updatedWallets)
                .filter(wallet => wallet !== "all")
                .reduce((result, wallet) => {
                    result[wallet] = updatedWallets[wallet];
                    return result;
                }, {});
            const allWallet = getAllWallet(walletsWithoutAll)
            return {
                ...updatedWallets,
                ...allWallet
            }
        case REMOVE_WALLET:
            const walletsWithoutSelected = Object.keys(wallets)
                .filter(wallet => wallet !== action.payload.exchange)
                .reduce((result, wallet) => {
                    result[wallet] = wallets[wallet];
                    return result;
                }, {});
            const walletsWithoutAll2 = Object.keys(walletsWithoutSelected)
                .filter(wallet => wallet !== "all")
                .reduce((result, wallet) => {
                    result[wallet] = walletsWithoutSelected[wallet];
                    return result;
                }, {});
            const allWallet2 = getAllWallet(walletsWithoutAll2)
            return {
                ...walletsWithoutAll2,
                ...allWallet2
            }
        case WALLET_STATUS:
            // Sets data to {} if there was nothing previously
            const hasData = wallets.hasOwnProperty(action.payload.wallet) && wallets[action.payload.wallet]?.pairs
            const newData = hasData ? wallets[action.payload.wallet].pairs : []
            return {
                ...wallets,
                [action.payload.wallet]: {
                    ...wallets[action.payload.wallet],
                    pairs: newData,
                    status: action.payload.status
                }
            }
        default:
            return wallets
    }
}
