export const es = {
  translation: {
    // Generals
    Activate: "Activar",
    "You need to activate an exchange": "Necesita activar un exchange",
    "entries per page": "entradas por página",
    "Search...": "Buscar...",
    actions: "acciones",
    pair: "par",
    "change 24hs": "variación 24hs",
    price: "precio",
    "volume 24hs": "volumen 24hs",
    symbol: "símbolo",
    side: "orden",
    amount: "cantidad",
    cost: "costo",
    type: "tipo",
    date: "fecha",
    Showing: "Mostrando del",
    to: "al",
    of: "de",
    entries: "entradas",
    Distribution: "Distribución",
    "Fiat value": "Valor Fiat",
    Free: "Disponible",
    Locked: "Retenido",
    profit: "beneficios",
    "%profit": "% Beneficios",
    cancel: "cancelar",
    save: "guardar",
    "Select...": "Seleccionar...",
    "change data!": "¡Cambiar datos!",
    "content manager": "gestionar contenido",
    "Coming soon!": "Próximamente!",
    all: "todos",
    "Trade closed": "Operación cerrada",
    Notifications: "Notificaciones",

    //SideNavbar
    Home: "Home",
    Trade: "Operar",
    Orders: "Ordenes",
    Wallet: "Billetera",
    Arbitration: "Arbitraje",
    Markets: "Mercados",
    News: "Noticias",
    Admin: "Administacion",
    Profile: "Perfil",
    "upgrade to premium": "cámbiate a premium",

    //DashboardNavbar
    balance: "saldo",

    // Home
    "Trades (open)": "Operaciones (abiertas)",
    "Trades (closed)": "Operaciones (cerradas)",
    Arbitrations: "Arbitraje",
    Portfolio: "Portafolio",
    "Admin messages": "Mensajes del administrador",
    "Oops, something went wrong...": "Oops, algo salió mal...",
    "Last arbitrage opportunity found":
      "Última oportunidad de arbitraje encontrada",
    today: "hoy",
    "Read More": "Leer más",

    // Trade
    Buy: "Comprar",
    Sell: "Vender",
    Market: "Mercado",
    Limit: "Limite",
    Amount: "Cantidad",
    Available: "Disponible",
    Total: "Total",
    Price: "Precio",
    "buy market": "comprar a mercado",
    "sell market": "vender a mercado",
    "buy limit": "comprar a límite",
    "sell limit": "vender a límite",
    favorites: "favoritos",

    // Orders
    "No trades yet!": "¡Aún no hay operaciones!",
    "No opportunities yet!": "¡Aún no hay oportunidades!",
    Closed: "Cerradas",
    Opened: "Abiertas",
    Arbitrage: "Arbitraje",

    // Wallet
    "Something failed with this wallet, try refreshing the wallets and verify your keys.":
      "Algo ha fallado con esta billetera, intente actualizar las billeteras y verifique sus claves.",
    futures: "futuros",

    // Arbitration
    "No strategies yet!": "¡Aún no hay estrategias!",
    "All Strategies": "Todas las estrategias",
    "new strategy": "nueva estrategia",
    "Strategy Information": "Información de la estrategia",
    Opportunities: "Oportunidades",
    Strategies: "Estrategias",
    Actions: "Acciones",
    "If an opportunity is found and it meets one of your strategies criteria, 2 orders will be created":
      "Si se encuentra una oportunidad que cumpla tus estrategias, 2 órdenes serán creadas",
    "These are the last price differences found. Create strategies to profit from the next ones!":
      "Estas son las últimas diferencias de precios encontradas. Crea estrategias para sacar provecho de las siguientes!",
    "These are your triggered strategies":
      "Estas son tus estrategias ejecutadas",
    "Trades triggered": "Órdenes ejecutadas",
    Pair: "Par",
    Side: "Side",
    Amount: "Cantidad",
    Price: "Precio",
    Exchange: "Exchange",
    Date: "Fecha",
    "Exchange 1": "Exchange 1",
    "Exchange 2": "Exchange 2",
    Profit: "Beneficio",
    Gained: "Ganado",
    "Required balances": "Balances requeridos",
    "Ok!": "Ok!",
    "You need the premium version to add more!":
      "Necesitas la versión premium para añadir más!",
    "You have reached your limit": "Has alcanzado tu límite",
    "Please enter a percentage of at least 0.5% to ensure fees do not impact profitability.": "Por favor, ingrese un porcentaje de al menos 0.5% para asegurarse de que las comisiones no afecten la rentabilidad.",

    // Top Coins
    "Today's top cryptocurrencies by market capitalization":
      "Las principales criptomonedas de hoy por capitalización de mercado",
    Source: "Fuente",
    "USD Price": "Precio USD",
    "24H Volume": "Volumen 24H",
    "Market Cap": "Capitalización",
    heatmap: "mapa de calor",

    // Profile
    settings: "ajustes",
    profile: "perfil",
    exchanges: "exchanges",
    plans: "planes",
    "sign out": "cerrar sesión",
    Settings: "Ajustes",
    security: "seguridad",
    Platform: "Plataforma",
    "Remove small balances": "Eliminar saldos pequeños",
    "Base token": "Token base",
    "Trade opened": "Operación abierta",
    "Arbitrage opportunity found": "Oportunidad de arbitraje encontrada",
    "profile information": "información del perfil",
    "edit profile": "editar perfil",
    username: "Nombre de usuario",
    "full name": "Nombre completo",
    email: "Correo electrónico",
    country: "País",
    phone: "Teléfono",
    Enabled: "Habilitado",
    Disabled: "Deshabilitado",
    "Public Key": "Clave Pública",
    "Private Key": "Clave Privada",
    Password: "Contraseña",
    Open: "Abrir",
    "Save API Keys": "Guardar API Keys",
    "You need to add keys": "Necesitas agregar claves",
    "basic information": "Información básica",
    "change password": "Cambiar contraseña",
    "Current Password": "Contraseña actual",
    "current password": "contraseña actual",
    "New Password": "Nueva contraseña",
    "new password": "nueva contraseña",
    "Confirm New Password": "Confirmar nueva contraseña",
    "confirm new password": "confirmar nueva contraseña",
    "Password requirements": "Requisitos de contraseña",
    "Please follow this guide for a strong password":
      "Por favor, sigue esta guía para una contraseña segura",
    "One capital letter": "Una letra mayúscula",
    "Min 8 characters": "Mínimo 8 caracteres",
    "One number (2 are recommended)": "Un número (se recomiendan 2)",
    "Change it often": "Cámbiala con frecuencia",
    change: "cambiar",
    "My referrals": "Mis referidos",
    "These are the referrals that are currently making you money":
      "Estos son los referidos que te están generando dinero",
    earnings: "ganancias",
    "avg. user value": "valor medio usuario",
    "How to use": "Guía",
    "Integrate your referral code in 3 easy steps":
      "Integra tu código de referido en 3 pasos",
    "1. Share your code for people to register":
      "1. Comparte tu link de registro",
    "2. Get paid monthly for each referral":
      "2. Recibe dinero por cada referido",
    "3. Second and third level referrals are also rewarded!":
      "3. Los referidos de segundo y tercer nivel también son recompensados!",
    Rewards: "Recompensas",
    "This is how much you will get paid":
      "Estas son las cantidades que ganarás",
    "usdc / MO": "usdc / mes",
    "Level 1": "Nivel 1",
    "Level 2": "Nivel 2",
    "Level 3": "Nivel 3",
    monthly: "al mes",
    "Referral code": "Código referido",
    "Make sure people register with the following code":
      "Asegúrate que se registran con el código siguiente",
    copy: "copiar",
    "Top referred users": "Top referidos",
    "No referrals yet!": "Todavía no tienes referidos!",
    "paid users": "usuarios de pago",
    referrals: "referidos",
    user: "usuario",
    lvl: "nivel",
    "contract count": "num de contratos",
    "Required fields": "Campos requeridos",
    "Password must contain at least 8 characters, 1 uppercase, and 1 number.":
      "La contraseña debe contener al menos 8 caracteres, 1 mayúscula y 1 número.",
    "Passwords do not match. Please try again.":
      "Las contraseñas no coinciden. Por favor, intente de nuevo.",
    "Invalid current password": "Contraseña actual inválida",

    // Lock
    "Your session has expired, please enter your account password.":
      "Tu sesión ha expirado, por favor ingresa la contraseña de tu cuenta.",
    unlock: "Desbloquear",
    "Is this your account?": "¿Esta es tu cuenta?",
    "Sign in": "Iniciar sesión",

    // Admin
    users: "usuarios",
    messages: "mensajes",
    videos: "videos",
    status: "estado",
    "register date": "fecha de registro",
    action: "acción",
    author: "autor",
    content: "contenido",
    "create date": "fecha de creación",
    title: "título",
    url: "URL",
    "Editing user: ": "Editando usuario: ",
    remove: "eliminar",
    "Personal information": "Información personal",
    Email: "Email",
    "User Name": "Nombre de usuario",
    "Full Name": "Nombre completo",
    Status: "Estado",
    Phone: "Teléfono",
    Country: "País",
    "Register Date": "Fecha de registro",
    Registered: "Registrado",
    Activated: "Activado",
    "User earnings: ": "Ganancias del usuario: ",
    "Paid to user: work in progress": "Pagado al usuario: trabajo en progreso",
    "Delete user": "Eliminar usuario",
    "You won't be able to revert this!": "¡No podrás revertir esto!",
    "Yes, DELETE!": "¡Sí, ELIMINAR!",
    "Deleted!": "¡Eliminado!",
    User: "Usuario",
    "has been deleted": "ha sido eliminado",
    Error: "Error",
    "could not be deleted": "no se pudo eliminar",
    "+ new Message": "+ nuevo mensaje",
    "Editing message:": "Editando mensaje:",
    "Message information": "Información del mensaje",
    Author: "Autor",
    Content: "Contenido",
    "Create Date": "Fecha de creación",
    "New message": "Nuevo mensaje",
    "Delete Message?": "¿Eliminar mensaje?",
    "Message has been deleted": "El mensaje ha sido eliminado",
    "Message could not be deleted": "El mensaje no se pudo eliminar",
    "+ new Video": "+ nuevo video",
    "Editing video:": "Editando video:",
    "Video information": "Información del video",
    Title: "Título",
    "Youtube URL": "URL de YouTube",
    "Invalid YouTube URL": "URL de YouTube no válida",
    "New video": "Nuevo video",
    "Delete Video?": "¿Eliminar video?",
    "Video has been deleted": "El video ha sido eliminado",
    "Video could not be deleted": "El video no se pudo eliminar",
    Yes: "Si",

    // Notifications
    "View all": "Ver todo",
    "Mark as read": "Marcar como leído",
    "unread notifications": "notificaciones sin leer",
    Authorize: "Autorizar",
    authorization: "autorización",
    trading: "operando",
    "No notifications!": "Sin notificaciones!",

    // Plans
    "Plans & pricing": "Planes y precios",
    "Free Plan": "Plan Gratis",
    "Premium Plan": "Plan Premium",
    "All prices on this website are excluding VAT (if applicable)":
      "Todos los precios de este sitio web son sin IVA (si procede)",
    Monthly: "Mensual",
    free: "gratis",
    premium: "premium",
    corporate: "corporativo",
    "Connect x2 Exchanges": "Conectar 2 exchanges",
    "Connect x9 Exchanges": "Conectar 9 exchanges",
    "30 Operations Per Month": "30 Operaciones Por Mes",
    "Unlimited Operations": "Operaciones Ilimitadas",
    "50 Operations Download (Historical)":
      "Descarga De 50 Operaciones (Histórico)",
    "Unlimited Operations Download (Historical)":
      "Descarga Ilimitada De Operaciones (Histórico)",
    "x2 Auto Arbitrage Strategies": "2 Estrategias de arbitraje automático",
    "x10 Auto Arbitrage Strategies": "10 Estrategias de arbitraje automático",
    "Trading Bots": "Bots Activos",
    "Multilevel Referral Plan": "Plan De Referidos Multinivel",
    "Market Trends & News": "Tendencias del mercado y noticias",
    "Active Education": "Educación activa",
    "Limit & Market Trading": "Operaciones a Límite y Mercado",
    "start now": "comenzar ahora",
    "Contact us": "Contáctanos",
    "cancel suscription": "cancelar suscripción",
    "Are you sure?": "¿Estás seguro?",
    "Do you really want to unsubscribe?": "¿Realmente quieres darte de baja?",
    "Yes, unsubscribe!": "¡Sí, darme de baja!",
    "No, keep my subscription": "No, mantener mi suscripción",
    "Unsubscribed!": "¡Dado de baja!",
    "Your subscription has been successfully canceled. We're sorry to see you go!":
      "Tu suscripción ha sido cancelada con éxito. ¡Lamentamos verte ir!",
    "Oops...": "Vaya...",
    "Something went wrong! Please try again later.":
      "¡Algo salió mal! Por favor, inténtalo de nuevo más tarde.",
    "Message sent!": "¡Mensaje enviado!",
    "Thank you for contacting us!": "¡Gracias por contactarnos!",
    "We will be in touch soon.": "Nos pondremos en contacto pronto.",
    "Something went wrong.": "Algo salió mal.",
    "Please try again.": "Por favor, inténtelo de nuevo.",
    "By clicking Talk to CTrading, I acknowledge I have read and understand the ":
      "Al hacer clic en Hablar con CTrading, reconozco que he leído y entiendo la ",
    "Privacy Notice.": "Política de Privacidad.",
    "Talk to CTrading": "Hablar con CTrading",
    "Company email": "Correo electrónico de la empresa",
    "Email address": "Dirección de correo electrónico",
    "Your name": "Tu nombre",
    "Phone number": "Número de teléfono",
    "Phone number (optional)": "Número de teléfono (opcional)",
    "How can we help?": "¿Cómo podemos ayudar?",
    "Your company needs": "Las necesidades de tu empresa",
    "Trading Infrastructure": "Infraestructura de Comercio",
    "Customized Services": "Servicios Personalizados",
    "Account Reconciliation": "Conciliación de Cuentas",
    "Deposit and Withdrawal System": "Sistema de Depósitos y Retiros",
    "User Management Dashboard": "Panel de Gestión de Usuarios",
    "Statistical Charts": "Gráficos Estadísticos",
    "White Label": "Marca Blanca",
    "Let's Talk": "Hablemos",
    "Error redirecting to Stripe Checkout. Please try again or contact support if the issue persists.":
      "Error al redirigir a Stripe Checkout. Por favor, inténtelo de nuevo o contacte con el soporte si el problema persiste.",
    "Your subscription has been successfully processed. Thank you for subscribing!":
      "Su suscripción ha sido procesada con éxito. ¡Gracias por suscribirse!",
    "There was an error processing your charge. Please contact support for assistance.":
      "Hubo un error al procesar su cargo. Por favor, contacte con el soporte para obtener ayuda.",

    // Auth
    "Welcome back!": "¡Bienvenido de nuevo!",
    "Enter your email and password to sign in":
      "Ingrese su correo electrónico y contraseña para iniciar sesión",
    "sign in": "iniciar sesión",
    "sign up": "registrarse",
    "Remember me": "Recordarme",
    "Forgot your password?": "¿Olvidó su contraseña?",
    "Full Name": "Nombre completo",
    "User Name": "Nombre de usuario",
    Password: "Contraseña",
    "Phone Number (optional)": "Número de teléfono (opcional)",
    Country: "País",
    "Already have an account?": "¿Ya tienes una cuenta?",
    "Reset Password": "Restablecer contraseña",
    "You will receive an e-mail in maximum 60 seconds":
      "Recibirá un correo electrónico en un máximo de 60 segundos",
    reset: "restablecer",
    "Enter your e-mail": "Ingrese su correo electrónico",
    "Confirmed Password": "Confirmar contraseña",
    unlock: "desbloquear",
    "Is this your account?": "¿Es esta su cuenta?",
    "2-Step Verification": "Verificación en dos pasos",
    "send code": "enviar código",
    "Is this your account? If not, please sign in":
      "¿Es esta su cuenta? Si no, por favor inicie sesión",
    "here.": "aquí.",
    "Not all needed params were provided":
      "No se proporcionaron todos los parámetros necesarios",
    "User does not exist": "El usuario no existe",
    "Invalid password": "Contraseña inválida",
    "Internal server error": "Error interno del servidor",
    "User is not active and requires confirmation from the administrator":
      "El usuario no está activo y requiere la confirmación del administrador",
    "Invalid 2FA code, please try again.":
      "Código 2FA inválido, por favor intente de nuevo.",
    "You will receive a password recovery link at your email address in a few minutes.":
      "Recibirá un enlace de recuperación de contraseña en su dirección de correo electrónico en unos minutos.",
    "Your password has been reset.": "Su contraseña ha sido restablecida.",
    "User not found": "Usuario no encontrado",
    "Pasted value exceeds 6 characters.":
      "El valor pegado excede los 6 caracteres.",
    "Email is required.": "Se requiere correo electrónico.",
    "Password is required.": "Se requiere contraseña.",
    "Email and Password are required.":
      "Se requieren correo electrónico y contraseña.",
    "Input fields are required.": "Se requieren campos de entrada.",
    "Fields are required.": "Se requieren campos.",
    "User successfully created. Log in to get a contract.":
      "Usuario creado con éxito. Inicie sesión para obtener un contrato.",
    "User successfully created. Check your email for verification link.":
      "Usuario creado con éxito. Revise su correo electrónico para el enlace de verificación.",
    "Email or Username are already taken":
      "El correo electrónico o el nombre de usuario ya están en uso",
    "Unauthorized: No token provided": "No autorizado: No se proporcionó token",
    "Your subscription has been updated. Please log in again to continue.":
      "Su suscripción ha sido actualizada. Por favor, inicie sesión nuevamente para continuar.",
    "Unauthorized: Action not allowed": "No autorizado: Acción no permitida",

    // Tours
    skip: "omitir",
    back: "atrás",
    next: "siguiente",
    close: "cerrar",
    "We're glad to have you here. Let's take a quick tour to get you started.": "Nos alegra tenerte aquí. Hagamos un recorrido rápido para que empieces.",
    "Manage your trades efficiently with our easy-to-use interface. View information about your trades and arbitrage actions. This section provides details on your trades.": "Gestiona tus operaciones de manera eficiente con nuestra interfaz fácil de usar. Visualiza información sobre tus operaciones y acciones de arbitraje. Esta sección proporciona detalles sobre tus operaciones.",
    "Keep track of your funds in the wallet section. View your balance for each currency in the wallet section. This section provides a summary of your balances.": "Mantén un seguimiento de tus fondos en la sección de billetera. Visualiza tu saldo para cada moneda en la sección de billetera. Esta sección proporciona un resumen de tus saldos.",
    "Stay updated with important notifications and messages from the admin. Check this section regularly to ensure you don't miss any critical information.": "Mantente actualizado con notificaciones importantes y mensajes del administrador. Revisa esta sección regularmente para asegurarte de no perder información crítica.",
    "Get the latest updates and news about the market. Stay informed with real-time news that can impact your trading decisions.": "Obtén las últimas actualizaciones y noticias sobre el mercado. Mantente informado con noticias en tiempo real que pueden impactar tus decisiones de trading.",
    "Explore arbitrage opportunities to maximize your profits. This section provides tools and insights to help you take advantage of price differences across markets.": "Explora oportunidades de arbitraje para maximizar tus ganancias. Esta sección proporciona herramientas y conocimientos para ayudarte a aprovechar las diferencias de precios entre mercados.",
    "Watch informative and educational videos. Learn more about trading strategies, market analysis, and how to use our platform effectively.": "Mira videos informativos y educativos. Aprende más sobre estrategias de trading, análisis de mercado y cómo usar nuestra plataforma de manera efectiva.",
    "This is the Trader section where you can manage your trades, monitor market trends, and execute trading strategies.": "Esta es la sección Trader donde puedes gestionar tus operaciones, monitorear tendencias del mercado y ejecutar estrategias de trading.",
    "Select the exchange you want to trade on.": "Selecciona el exchange en el que deseas operar.",
    "Toggle between viewing all pairs or only your favorite pairs.": "Alterna entre ver todos los pares o solo tus pares favoritos.",
    "Click the Trade button to execute a trade for the selected pair.": "Haz clic en el botón de Operar para ejecutar una operación para el par seleccionado.",
    "Click the star icon to add or remove a pair from your favorites.": "Haz clic en el icono de estrella para añadir o eliminar un par de tus favoritos.",
    "This is the trading section where you can execute your buy and sell orders.": "Esta es la sección de trading donde puedes ejecutar tus órdenes de compra y venta.",
    "Select whether you want to Buy or Sell the chosen pair.": "Selecciona si deseas Comprar o Vender el par elegido.",
    "Choose the type of order you want to place: Market or Limit.": "Elige el tipo de orden que deseas colocar: Mercado o Límite.",
    "Enter the amount of the asset you want to trade.": "Ingresa la cantidad del activo que deseas negociar.",
    "The total amount will be calculated based on the amount you entered and the current market price.": "El monto total se calculará según la cantidad ingresada y el precio actual del mercado.",
    "Click the button to execute your trade.": "Haz clic en el botón para ejecutar tu operación.",
    "This section allows you to view your orders. You can filter between closed and opened orders.": "Esta sección te permite ver tus órdenes. Puedes filtrar entre órdenes cerradas y abiertas.",
    "Refresh the orders list to see the latest updates.": "Actualiza la lista de órdenes para ver las últimas actualizaciones.",
    "These are your closed orders.": "Estas son tus órdenes cerradas.",
    "These are your opened orders.": "Estas son tus órdenes abiertas.",
    "This section allows you to view and manage your wallet. You can see the distribution of your assets, refresh the page, and filter by different exchanges.": "Esta sección te permite ver y gestionar tu cartera. Puedes ver la distribución de tus activos, actualizar la página y filtrar por diferentes exchanges.",
    "Click here to refresh your wallet data.": "Haz clic aquí para actualizar los datos de tu cartera.",
    "View your assets across all exchanges or filter by specific exchanges.": "Visualiza tus activos en todos los exchanges o filtra por exchanges específicos.",
    "This section allows you to view and manage your arbitration opportunities, strategies, and actions.": "Esta sección te permite ver y gestionar tus oportunidades de arbitraje, estrategias y acciones.",
    "Refresh the arbitrage list to see the latest updates.": "Actualiza la lista de arbitraje para ver las últimas actualizaciones.",
    "View the latest price differences found across exchanges.": "Visualiza las últimas diferencias de precio encontradas entre exchanges.",
    "Create and manage your trading strategies.": "Crea y gestiona tus estrategias de trading.",
    "View your triggered actions.": "Visualiza tus acciones activadas.",
    "Click here to create a new trading strategy.": "Haz clic aquí para crear una nueva estrategia de trading.",
    "This section allows you to input the details for creating a new trading strategy.": "Esta sección te permite ingresar los detalles para crear una nueva estrategia de trading.",
    "Select the first exchange where the trading strategy will be executed.": "Selecciona el primer exchange donde se ejecutará la estrategia de trading.",
    "Select the second exchange where the trading strategy will be executed.": "Selecciona el segundo exchange donde se ejecutará la estrategia de trading.",
    "Choose the trading pair for the strategy.": "Elige el par de trading para la estrategia.",
    "Enter the amount to be traded.": "Ingresa la cantidad a negociar.",
    "Specify the profit percentage you aim to achieve.": "Especifica el porcentaje de ganancia que deseas lograr.",
    "Click here to save the new trading strategy.": "Haz clic aquí para guardar la nueva estrategia de trading.",
    "Click here to cancel and discard the strategy creation.": "Haz clic aquí para cancelar y descartar la creación de la estrategia.",
    "Here, you can see the strategies you have set up. Each strategy includes the exchanges, trading pair, minimum profit, and required balance.": "Aquí puedes ver las estrategias que has configurado. Cada estrategia incluye los exchanges, el par de trading, la ganancia mínima y el saldo requerido.",
    "This column shows the required balance for each strategy.": "Esta columna muestra el saldo requerido para cada estrategia.",
    "Edit an existing strategy.": "Edita una estrategia existente.",
    "Delete a strategy you no longer need.": "Elimina una estrategia que ya no necesitas.",
    "Markets section provides comprehensive tools and data to help you trade and analyze cryptocurrencies effectively.": "La sección de Mercados ofrece herramientas y datos completos para ayudarte a operar y analizar criptomonedas de manera efectiva.",
    "This section lists the top 100 cryptocurrencies by market capitalization.": "Esta sección enumera las 100 principales criptomonedas por capitalización de mercado.",
    "This section provides a visual representation of the market performance of various cryptocurrencies.": "Esta sección proporciona una representación visual del rendimiento del mercado de diversas criptomonedas.",
    "Name and symbol of the cryptocurrency.": "Nombre y símbolo de la criptomoneda.",
    "Current price of the cryptocurrency in USD.": "Precio actual de la criptomoneda en USD.",
    "Price change percentage over the last hour.": "Porcentaje de cambio de precio en la última hora.",
    "Price change percentage over the last 24 hours.": "Porcentaje de cambio de precio en las últimas 24 horas.",
    "Trading volume of the cryptocurrency over the last 24 hours.": "Volumen de trading de la criptomoneda en las últimas 24 horas.",
    "Market capitalization of the cryptocurrency.": "Capitalización de mercado de la criptomoneda.",
    "News section provides the latest updates and articles on various topics related to cryptocurrency. Stay informed about market trends, technological advancements, regulatory changes, and expert analyses to make well-informed decisions in your crypto journey.": "La sección de Noticias proporciona las últimas actualizaciones y artículos sobre diversos temas relacionados con las criptomonedas. Mantente informado sobre tendencias de mercado, avances tecnológicos, cambios regulatorios y análisis de expertos para tomar decisiones informadas en tu viaje cripto.",
    "This section allows you to manage users, messages, and videos in your platform. Here, you can view, edit, and delete user information, handle messages, and manage video content.": "Esta sección te permite gestionar usuarios, mensajes y videos en tu plataforma. Aquí puedes ver, editar y eliminar información de usuarios, manejar mensajes y gestionar contenido de video.",
    "Click here to view and manage the list of users on your platform.": "Haz clic aquí para ver y gestionar la lista de usuarios en tu plataforma.",
    "Click here to view and manage messages. You can also add new messages here.": "Haz clic aquí para ver y gestionar mensajes. También puedes añadir nuevos mensajes aquí.",
    "Click here to view and manage video content. You can also add new videos here.": "Haz clic aquí para ver y gestionar contenido de video. También puedes añadir nuevos videos aquí.",
    "Click here to refresh the data in this section.": "Haz clic aquí para actualizar los datos en esta sección.",
    "Click here to edit a user's information.": "Haz clic aquí para editar la información de un usuario.",
    "Click here to delete user.": "Haz clic aquí para eliminar usuario.",
    "Profile section allows you to view and edit your personal information, change your password, and manage your account settings.": "La sección de Perfil te permite ver y editar tu información personal, cambiar tu contraseña y gestionar la configuración de tu cuenta.",
    "You can edit your personal information and change your password here.": "Puedes editar tu información personal y cambiar tu contraseña aquí.",
    "In the Settings tab, you can enable or disable two-factor authentication (2FA) and manage your tour settings.": "En la pestaña de Configuración, puedes habilitar o deshabilitar la autenticación de dos factores (2FA) y gestionar la configuración de tu recorrido.",
    "Manage your exchanges here. You can enable and disable your preferred exchanges.": "Gestiona tus exchanges aquí. Puedes habilitar y deshabilitar tus exchanges preferidos.",
    "Choose a plan that suits you best. You can opt for monthly or yearly plans.": "Elige el plan que mejor se adapte a ti. Puedes optar por planes mensuales o anuales.",
    "View all your earnings from referrals in this section.": "Visualiza todas tus ganancias por referencias en esta sección.",
    "Click here to log out of your session.": "Haz clic aquí para cerrar sesión.",
    "Welcome to CTrading!": "¡Bienvenido a CTrading!",
    'Before you can interact on our platform, you need to activate an exchange. Click the "Activate" button to proceed.': 'Antes de poder interactuar en nuestra plataforma, necesitas activar un exchange. Haz clic en el botón "Activar" para proceder.',
    'Please select your preferred exchange and then click the "API Keys" button to proceed.': 'Por favor, selecciona tu exchange preferido y luego haz clic en el botón "API Keys" para continuar.',
    'Please fill out all fields and then click the "Save API Keys" button.': 'Por favor, completa todos los campos y luego haz clic en el botón "Guardar API Keys".',

    //Bots
    "Off": "Desactivado",
    "On": "Activo",
    "You need the premium version to use this feature!": "¡Necesitas la versión premium para usar esta función!",
    "Upgrade to premium": "Actualiza a premium",
    "To utilize the Bot, ensure that you have": "Para utilizar el Bot, asegúrate de haber",
    "activated at least one exchange": "activado al menos un exchange",
    "from the list. The bot will operate on the selected pairs across your chosen exchanges. Please note that the minimum amount required to activate the bot is": "de la lista. El bot operará en los pares seleccionados en los exchanges que hayas elegido. Ten en cuenta que la cantidad mínima requerida para activar el bot es",
    "The bot will replicate trading strategies on your behalf, automatically mirroring trades based on the selected pairs on your active exchanges": "El bot replicará estrategias de trading en tu nombre, copiando automáticamente las operaciones basadas en los pares seleccionados en tus exchanges activos"
  },
};
