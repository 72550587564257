// @mui material components
import Icon from "@mui/material/Icon"

export default function EditButton({ id, setShowComponent }) {
  const handleEdit = (event) => {
    event.preventDefault();
    setShowComponent(prev => ({ ...prev, editStrategy: true, table: false, strategyId: id }));
  };

  return (
    <Icon
      className="icon"
      fontSize='30px'
      onClick={handleEdit}
      sx={{ cursor: 'pointer' }}
    >
      edit
    </Icon>
  );
}
