import { useState, useRef, useEffect } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "api/auth";
import { logoutSesion } from "redux/actions/currentUser";
import { setInformativeMessage } from "redux/actions/informativeMessage";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// Authentication layout components
import TextLink from "examples/Buttons/TextLink";
import ActionButton from "examples/Buttons/ActionButton";
import IllustrationLayout from "layouts/Common/IllustrationLayout";

// Constants
import { ROUTES } from "constants";

// Translate
import { useTranslation } from "react-i18next";

function TwoFA() {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.currentUser.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(" ".repeat(6));
  const inputRefs = useRef([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e, index) => {
    const newValue = e.target.value;
    if (/^\d$/.test(newValue)) {
      const updatedValue =
        value.substr(0, index) + newValue + value.substr(index + 1);
      setValue(updatedValue);
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].firstChild.focus();
      } else {
        sendCode(updatedValue);
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (value[index] !== " ") {
        const updatedValue =
          value.substr(0, index) + " " + value.substr(index + 1);
        setValue(updatedValue);
        index > 0 && inputRefs.current[index - 1].firstChild.focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const paste = e.clipboardData.getData("text");
    const textLength =
      paste.length > 0 && paste.length < 7 ? paste.length - 1 : 5;
    if (/^\d{1,6}$/.test(paste)) {
      const updatedValue = paste.padEnd(6, " ");
      setValue(updatedValue);
      updatedValue.split("").forEach((char, index) => {
        inputRefs.current[index].firstChild.value = char !== " " ? char : "";
      });
      inputRefs.current[textLength].firstChild.focus();
      sendCode(updatedValue);
    } else {
      dispatch(
        setInformativeMessage({
          success: false,
          message: "Pasted value exceeds 6 characters.",
        })
      );
    }
  };

  const sendCode = async (code) => {
    const url = await loginUser(code);
    url && navigate(url);
  };

  const handleLogout = () => {
    dispatch(logoutSesion());
    navigate(ROUTES.LOGIN);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    sendCode(value);
    setLoading(false);
  };

  useEffect(() => {
    !currentUser.twoFA?.jwtToken && navigate(ROUTES.LOGIN);
  }, []);

  return (
    <IllustrationLayout
      header={
        <SoftBox px={5} textAlign="center">
          <SoftTypography variant="h2" fontWeight="bold">
            {t("2-Step Verification")}
          </SoftTypography>
        </SoftBox>
      }
    >
      <SoftBox component="form" role="form" onSubmit={handleLogin}>
        <SoftBox mb={2}>
          <Grid container spacing={2}>
            {[...Array(6)].map((_, index) => (
              <Grid key={index} item xs>
                <SoftInput
                  type="text"
                  maxLength="1"
                  value={value[index] !== " " ? value[index] : ""}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onPaste={handlePaste}
                  ref={(el) => (inputRefs.current[index] = el)}
                  required
                />
              </Grid>
            ))}
          </Grid>
        </SoftBox>
        <SoftBox mb={2}>
          <ActionButton loading={loading} label="send code" />
        </SoftBox>
        <SoftBox textAlign="center">
          <TextLink
            text="Is this your account? If not, please sign in"
            labelLink="here."
            handle={handleLogout}
          />
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}

export default TwoFA;
