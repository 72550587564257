/***** We receive an action and set de data to the store *****/

import { SET_BOTS, BOTS_STATUS } from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";

export default (bots = { data: [], status: STATUS.PENDING }, action) => {
    switch (action.type) {
        case SET_BOTS:
            return { ...bots, data: action.payload };
        case BOTS_STATUS:
            return { ...bots, status: action.payload };
        default:
            return bots;
    };
};