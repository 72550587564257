import { LANGUAGES } from "constants";

export const Flags = {
  en: (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      options="[object Object]"
      className="i8-flag"
    >
      <path d="M.666 3.333h14.667v9H.666v-9Z" fill="#ECEFF1" />
      <path
        d="M.667 3.333h14.666v1H.667v-1Zm0 2h14.666v1H.667v-1Zm0 2h14.666v1H.667v-1Zm0 2h14.666v1H.667v-1Zm0 2h14.666v1H.667v-1Z"
        fill="#F44336"
      />
      <path d="M.667 3.333h7v5h-7v-5Z" fill="#3F51B5" />
      <path
        d="m1.417 4 .154.33.346.052-.25.257.059.361-.31-.17-.309.17.06-.361-.25-.257.345-.053L1.417 4ZM2.75 4l.154.33.346.052-.25.257.06.361-.31-.17-.31.17.06-.361-.25-.257.346-.053L2.75 4Zm1.333 0 .155.33.345.052-.25.257.06.361-.31-.17-.309.17.06-.361-.25-.257.345-.053L4.083 4Zm1.334 0 .154.33.346.052-.25.257.059.361-.31-.17-.309.17.06-.361-.25-.257.345-.053L5.417 4Zm1.25 0 .154.33.346.052-.25.257.059.361-.31-.17-.309.17.06-.361-.25-.257.345-.053L6.667 4Zm-5.25 2.667.154.329.346.053-.25.256.059.362-.31-.171-.309.17.06-.36-.25-.257.345-.053.155-.33Zm1.333 0 .154.329.346.053-.25.256.06.362-.31-.171-.31.17.06-.36-.25-.257.346-.053.154-.33Zm1.333 0 .155.329.345.053-.25.256.06.362-.31-.171-.309.17.06-.36-.25-.257.345-.053.154-.33Zm1.334 0 .154.329.346.053-.25.256.059.362-.31-.171-.309.17.06-.36-.25-.257.345-.053.155-.33Zm1.25 0 .154.329.346.053-.25.256.059.362-.31-.171-.309.17.06-.36-.25-.257.345-.053.155-.33ZM1.75 5.333l.154.33.346.052-.25.257.06.361-.31-.17-.31.17.06-.361-.25-.257.346-.052.154-.33Zm1.333 0 .155.33.345.052-.25.257.06.361-.31-.17-.309.17.06-.361-.25-.257.345-.052.154-.33Zm1.334 0 .154.33.346.052-.25.257.059.361-.31-.17-.309.17.06-.361-.25-.257.345-.052.155-.33Zm1.333 0 .154.33.346.052-.25.257.06.361-.31-.17-.31.17.06-.361-.25-.257.346-.052.154-.33Zm1.25 0 .154.33.346.052-.25.257.06.361-.31-.17-.31.17.06-.361-.25-.257.346-.052.154-.33Z"
        fill="#fff"
      />
    </svg>
  ),
  es: (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      options="[object Object]"
      className="i8-flag"
    >
      <path d="M.667 3h14.666v10H.667V3Z" fill="#DD2C00" />
      <path d="M.667 5.667h14.666v4.666H.667V5.667Z" fill="#FFC107" />
    </svg>
  ),
};

export const getFlagIcon = (lang) => {
  switch (lang) {
    case LANGUAGES.EN:
      return Flags.en;

    case LANGUAGES.ES:
      return Flags.es;

    default:
      return Flags.en;
  }
};
