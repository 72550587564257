import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Exchange from 'componentsCustom/TableItems/Exchange';
import Pair from 'componentsCustom/TableItems/Pair';
import Date from 'componentsCustom/TableItems/Date';
import Amount from 'componentsCustom/TableItems/Amount';
import { useTranslation } from 'react-i18next';
import ProfitPerCent from 'componentsCustom/TableItems/ProfitPerCent';

function Row({ action, t }) {
    const { target_profit, buy_order, sell_order } = action;
    const amountGained = (sell_order.amount * sell_order.price) - (buy_order.amount * buy_order.price)
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row"><Exchange exchange={buy_order.exchange} /></TableCell>
                <TableCell align="left"><Exchange exchange={sell_order.exchange} /></TableCell>
                <TableCell align="left"><Pair pair={buy_order.pair} /></TableCell>
                <TableCell align="left"><ProfitPerCent profit={target_profit} /></TableCell>
                <TableCell align="left"><Date timestamp={buy_order.timestamp} /></TableCell>
                <TableCell align="left"><Amount amount={amountGained.toFixed(2)} symbol={buy_order.pair.split("/")[1]} /></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                {t("Trades triggered")}
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead sx={{ display: "table-header-group" }}>
                                    <TableRow>
                                        <TableCell>{t("Pair")}</TableCell>
                                        <TableCell>{t("Side")}</TableCell>
                                        <TableCell align="left">{t("Amount")}</TableCell>
                                        <TableCell align="left">{t("Price")}</TableCell>
                                        <TableCell align="left">{t("Exchange")}</TableCell>
                                        <TableCell align="left">{t("Date")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row"><Pair pair={buy_order.pair} /></TableCell>
                                        <TableCell>{buy_order.side}</TableCell>
                                        <TableCell align="left">{buy_order.amount}</TableCell>
                                        <TableCell align="left">{buy_order.price}</TableCell>
                                        <TableCell align="left"><Exchange exchange={buy_order.exchange} /></TableCell>
                                        <TableCell align="left"><Date timestamp={buy_order.timestamp} /></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row"><Pair pair={sell_order.pair} /></TableCell>
                                        <TableCell>{sell_order.side}</TableCell>
                                        <TableCell align="left">{sell_order.amount}</TableCell>
                                        <TableCell align="left">{sell_order.price}</TableCell>
                                        <TableCell align="left"><Exchange exchange={sell_order.exchange} /></TableCell>
                                        <TableCell align="left"><Date timestamp={sell_order.timestamp} /></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default function CollapsibleTable({ actions }) {
    const { t } = useTranslation();
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead sx={{ display: "table-header-group" }}>
                    <TableRow>
                        <TableCell />
                        <TableCell>{t("Exchange 1")}</TableCell>
                        <TableCell align="left">{t("Exchange 2")}</TableCell>
                        <TableCell align="left">{t("Pair")}</TableCell>
                        <TableCell align="left">{t("Profit")}</TableCell>
                        <TableCell align="left">{t("Date")}</TableCell>
                        <TableCell align="left">{t("Gained")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {actions.map(action => (
                        <Row key={action.arbitrage_order_id} action={action} t={t} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
