import { useState } from "react";

// react-redux
import { useSelector, useDispatch } from "react-redux";
import { getMessages } from "redux/actions/messages";

// sweetalert2 components
import Swal from "sweetalert2";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Admin React components
import AdminDataTable from "../../Components/AdminDataTable";
import { getMessagesDataTableData } from "../../data/dataTableData";

// Utils
import { deleteMessage } from "api/messages";
import EditMessage from "../../Components/EditForm/EditMessage";
import AddMessage from "../../Components/AddForm/AddMessage";

// Translate
import { useTranslation } from "react-i18next";

export default function MessagesTab() {
  const { t } = useTranslation();
  const { data: messages, status } = useSelector((state) => state.messages);
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState({});

  const cancelEdit = () => {
    setIsEditing(false);
    setSelectedMessage({});
  };

  const editClickedMessage = (message) => {
    setSelectedMessage(message);
    setIsEditing(true);
  };

  const newClickedMessage = () => {
    setIsAdding(!isAdding);
  };

  const refreshButtonAction = () => {
    dispatch(getMessages());
  };

  const handleRemove = ({ id: messageId }) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: t("Delete Message?"),
        text: t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("Yes, DELETE!"),
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteMessage(messageId)
            .then(() => {
              cancelEdit();
              refreshButtonAction();
              Swal.fire(
                t("Deleted!"),
                t("Message has been deleted"),
                "success"
              );
            })
            .catch(() => {
              Swal.fire(t("Error"), t("Message could not be deleted"), "error");
            });
        }
      });
  };

  return (
    <SoftBox>
      {isEditing && (
        <EditMessage
          messageData={selectedMessage}
          handleCancel={cancelEdit}
          handleRemove={handleRemove}
          handleRefresh={refreshButtonAction}
        />
      )}
      {isAdding && (
        <AddMessage
          handleCancel={newClickedMessage}
          handleRefresh={refreshButtonAction}
        />
      )}
      {!isEditing && !isAdding && (
        <AdminDataTable
          label="Message"
          dataTableData={getMessagesDataTableData(
            messages,
            "Message",
            editClickedMessage,
            handleRemove
          )}
          dataState={status}
          handleRefresh={refreshButtonAction}
          handleNew={newClickedMessage}
          button
        />
      )}
    </SoftBox>
  );
}
