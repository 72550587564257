import { LANGUAGES } from "constants";
import { useEffect, useState } from "react";

const useUserLanguage = () => {
  const [language, setLanguage] = useState(LANGUAGES.EN);

  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;
    const userSystemLanguage = userLanguage.split("-")[0];

    if (LANGUAGES.ALL.includes(userSystemLanguage)) {
      setLanguage(userSystemLanguage);
    } else {
      setLanguage(LANGUAGES.EN);
    }
  }, []);

  return language;
};

export default useUserLanguage;
