
import {
    createArbitrationStrategy,
    deleteArbitrationStrategy,
    patchArbitrationStrategy
} from "api/arbitration";
import {
    fetchArbitrationActions,
    fetchArbitrationOpportunities,
    fetchArbitrationStrategies,
    fetchArbitrationExchanges,
    fetchArbitrationPairs
} from "api/arbitration";
import {
    SET_ARBITRATION_ACTIONS,
    SET_ARBITRATION_ACTIONS_STATUS,
    SET_ARBITRATION_OPPORTUNITIES,
    SET_ARBITRATION_OPPORTUNITIES_STATUS,
    SET_ARBITRATION_STRATEGIES,
    SET_ARBITRATION_STRATEGIES_STATUS,
    SET_ARBITRATION_EXCHANGES,
    SET_ARBITRATION_EXCHANGES_STATUS,
    SET_ARBITRATION_PAIRS,
    SET_ARBITRATION_PAIRS_STATUS,
    ADD_ARBITRATION_STRATEGY,
    REMOVE_ARBITRATION_STRATEGY,
    UPDATE_ARBITRATION_STRATEGY
} from "constants/actionTypes";

import { STATUS } from "constants/fetchStatus";

/***** Redux actions that end up in the store *****/

export const getArbitrationActions = () => async dispatch => {
    try {
        dispatch({ type: SET_ARBITRATION_ACTIONS_STATUS, payload: STATUS.LOADING });
        const data = await fetchArbitrationActions();
        dispatch({ type: SET_ARBITRATION_ACTIONS, payload: data });
        dispatch({ type: SET_ARBITRATION_ACTIONS_STATUS, payload: STATUS.OK });
    } catch (error) {
        dispatch({ type: SET_ARBITRATION_ACTIONS_STATUS, payload: STATUS.ERROR });
    };
};

export const getArbitrationOpportunities = () => async dispatch => {
    try {
        dispatch({ type: SET_ARBITRATION_OPPORTUNITIES_STATUS, payload: STATUS.LOADING });
        const data = await fetchArbitrationOpportunities();
        dispatch({ type: SET_ARBITRATION_OPPORTUNITIES, payload: data });
        dispatch({ type: SET_ARBITRATION_OPPORTUNITIES_STATUS, payload: STATUS.OK });
    } catch (error) {
        dispatch({ type: SET_ARBITRATION_OPPORTUNITIES_STATUS, payload: STATUS.ERROR });
    };
};

export const getArbitrationStrategies = () => async dispatch => {
    try {
        dispatch({ type: SET_ARBITRATION_STRATEGIES_STATUS, payload: STATUS.LOADING });
        const data = await fetchArbitrationStrategies();
        dispatch({ type: SET_ARBITRATION_STRATEGIES, payload: data });
        dispatch({ type: SET_ARBITRATION_STRATEGIES_STATUS, payload: STATUS.OK });
    } catch (error) {
        dispatch({ type: SET_ARBITRATION_STRATEGIES_STATUS, payload: STATUS.ERROR });
    };
};

export const getArbitrationExchanges = () => async dispatch => {
    try {
        dispatch({ type: SET_ARBITRATION_EXCHANGES_STATUS, payload: STATUS.LOADING });
        const data = await fetchArbitrationExchanges();
        dispatch({ type: SET_ARBITRATION_EXCHANGES, payload: data });
        dispatch({ type: SET_ARBITRATION_EXCHANGES_STATUS, payload: STATUS.OK });
    } catch (error) {
        dispatch({ type: SET_ARBITRATION_EXCHANGES_STATUS, payload: STATUS.ERROR });
    };
};

export const getArbitrationPairs = () => async dispatch => {
    try {
        dispatch({ type: SET_ARBITRATION_PAIRS_STATUS, payload: STATUS.LOADING });
        const data = await fetchArbitrationPairs();
        dispatch({ type: SET_ARBITRATION_PAIRS, payload: data });
        dispatch({ type: SET_ARBITRATION_PAIRS_STATUS, payload: STATUS.OK });
    } catch (error) {
        dispatch({ type: SET_ARBITRATION_PAIRS_STATUS, payload: STATUS.ERROR });
    };
};

export const insertStrategy = strategy => async dispatch => {
    try {
        dispatch({ type: SET_ARBITRATION_STRATEGIES_STATUS, payload: STATUS.LOADING });
        const data = await createArbitrationStrategy(strategy);
        dispatch({ type: ADD_ARBITRATION_STRATEGY, payload: data });
        dispatch({ type: SET_ARBITRATION_STRATEGIES_STATUS, payload: STATUS.OK });
    } catch (error) {
        dispatch({ type: SET_ARBITRATION_STRATEGIES_STATUS, payload: STATUS.ERROR });
    };
};

export const updateStrategy = strategy => async dispatch => {
    try {
        dispatch({ type: SET_ARBITRATION_STRATEGIES_STATUS, payload: STATUS.LOADING });
        await patchArbitrationStrategy(strategy);
        const data = await fetchArbitrationStrategies();
        dispatch({ type: UPDATE_ARBITRATION_STRATEGY, payload: data });
        dispatch({ type: SET_ARBITRATION_STRATEGIES_STATUS, payload: STATUS.OK });
    } catch (error) {
        dispatch({ type: SET_ARBITRATION_STRATEGIES_STATUS, payload: STATUS.ERROR });
    };
};

export const deleteStrategy = strategyId => async dispatch => {
    try {
        dispatch({ type: SET_ARBITRATION_STRATEGIES_STATUS, payload: STATUS.LOADING });
        await deleteArbitrationStrategy(strategyId);
        dispatch({ type: REMOVE_ARBITRATION_STRATEGY, payload: strategyId });
        dispatch({ type: SET_ARBITRATION_STRATEGIES_STATUS, payload: STATUS.OK });
    } catch (error) {
        dispatch({ type: SET_ARBITRATION_STRATEGIES_STATUS, payload: STATUS.ERROR });
    };
};
