// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import ActionButton from "examples/Buttons/ActionButton";
import CancelButton from "examples/Buttons/CancelButton";
import { editForm } from "./schemas/form";
import SoftInput from "components/SoftInput";

// Translate
import { useTranslation } from 'react-i18next';

function ProfileEditCard({ title, info, loading, action }) {
  const { t } = useTranslation();
  const values = [];
  const { handleChange, handleSubmit, handleCancel } = action;

  // Parse user information.
  Object.keys(info).forEach((el) => {
    values.push({ ...editForm[el], value: info[el] });
  });

  // Render the card info items
  const renderItems = values.map((field) => (
    <SoftBox component={Grid} key={field.key} item xs={12} sm={6}>
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
      >
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {t(field.label)}
          </SoftTypography>
        </SoftBox>
        <SoftInput size="small" placeholder={t(field.placeholder)} value={field.value} name={field.name} type={field.type} onChange={handleChange} />
      </SoftBox>
    </SoftBox>
  ));

  return (
    <Card sx={{ overflow: "visible" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5" textTransform="capitalize">
          {t(title)}
        </SoftTypography>
      </SoftBox>
      <SoftBox component="form" onSubmit={handleSubmit} pb={3} px={3}>
        <SoftBox component={Grid} container spacing={3}>
          {renderItems}
        </SoftBox>
        <SoftBox opacity={0.3}>
          <Divider />
        </SoftBox>
        <SoftBox display="flex" gap={3} pt={2}>
          <CancelButton handle={handleCancel} />
          <ActionButton loading={loading} label="change data!" />
        </SoftBox>
      </SoftBox>
    </Card>
  );
};

// Setting default values for the props of CoverLayout
ProfileEditCard.defaultProps = {
  loading: false,
};

// Typechecking props for the ProfileEditCard
ProfileEditCard.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  action: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
  }).isRequired,
};

export default ProfileEditCard;
