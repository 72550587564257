import { useSelector } from "react-redux"
import clientConfig from "./clientConfig";

export default function useIsUserFreemium() {

    const currentUser = useSelector((state) => state.currentUser);
    const isUserFreemium = clientConfig.usePremiumModel && currentUser.data.subscription_status !== "active" && currentUser.data.subscription_status !== "full" && currentUser.data.role !== 'admin'

    return isUserFreemium
}
