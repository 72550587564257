import { v4 as uuidv4 } from "uuid";
// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Card } from "@mui/material";
import { useEffect, useState } from "react";
import Loader from "components/Loader";
import axios from "axios";
import { CRYPTOCOMPARE_API_KEY } from "apiconfig";

// Translate
import { useTranslation } from "react-i18next";

import "../styles.css";

function TopCoins() {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [top100, setTop100] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://min-api.cryptocompare.com/data/top/totaltoptiervolfull?limit=90&tsym=USD&api_key=${CRYPTOCOMPARE_API_KEY}`
      )
      .then((response) => setTop100(response.data.Data))
      .catch((err) => {
        throw err;
      })
      .finally(() => setLoading(false));
  }, []);

  const urlCryptoCompare = "https://cryptocompare.com";

  return (
    <SoftBox mt={2} mb={5}>
      <Card>
        <SoftTypography
          mt={3}
          ml={3}
          mr={3}
          variant="section"
          className="title"
        >
          {t("Today's top cryptocurrencies by market capitalization")}
        </SoftTypography>
        <SoftTypography ml={3} variant="section" className="subtitle">
          {t("Source")}: CryptoCompare
        </SoftTypography>
        {loading && <Loader />}
        {top100.map(({ CoinInfo, RAW }) => (
          <Grid
            key={uuidv4()}
            className="row"
            ml={1}
            mb={4}
            mr={3}
            container
            spacing={3}
          >
            <Grid id="symbol" className="crypto-name" item lg={2}>
              <img
                width={"auto"}
                height={28}
                src={urlCryptoCompare + CoinInfo.ImageUrl}
                alt={CoinInfo.FullName}
              />
              <SoftTypography className="crypto-market-name" variant="section">
                {CoinInfo.Name}
              </SoftTypography>
            </Grid>
            <Grid id="price" className="column" item lg={1.5}>
              <SoftTypography className="crypto-from-exchange price">
                {t("USD Price")}
              </SoftTypography>
              <SoftTypography className="crypto-from-quantity">
                {RAW.USD.PRICE.toFixed(2)} $
              </SoftTypography>
            </Grid>
            <Grid id="priceHour" className="column" item lg={1.5}>
              <SoftTypography className="crypto-from-exchange">
                1h
              </SoftTypography>
              <SoftTypography
                className="crypto-from-quantity percentage"
                color={RAW.USD.CHANGEPCT24HOUR > 0 ? "success" : "error"}
              >
                {RAW.USD.CHANGEPCTHOUR.toFixed(2)}
              </SoftTypography>
            </Grid>
            <Grid id="priceDay" className="column" item lg={1.5}>
              <SoftTypography className="crypto-from-exchange">
                24h
              </SoftTypography>
              <SoftTypography
                className="crypto-from-quantity percentage"
                color={RAW.USD.CHANGEPCT24HOUR > 0 ? "success" : "error"}
              >
                {RAW.USD.CHANGEPCT24HOUR.toFixed(2)}
              </SoftTypography>
            </Grid>
            <Grid id="volumeDay" className="column" item lg={2}>
              <SoftTypography className="crypto-from-exchange">
                {t("24H Volume")}
              </SoftTypography>
              <SoftTypography className="crypto-from-quantity big-number">
                {RAW.USD.VOLUME24HOURTO.toFixed(0)}
              </SoftTypography>
            </Grid>
            <Grid id="capitalization" className="column" item lg={2}>
              <SoftTypography className="crypto-from-exchange">
                {t("Market Cap")}
              </SoftTypography>
              <SoftTypography className="crypto-from-quantity">
                {RAW.USD.MKTCAP.toFixed(0)}
              </SoftTypography>
            </Grid>
          </Grid>
        ))}
      </Card>
    </SoftBox>
  );
}

export default TopCoins;
