// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// Translate
import { useTranslation } from "react-i18next";

export default function ExchangeInput({ name, value, handle, type }) {
  const { t } = useTranslation();

  return (
    <SoftBox>
      <SoftTypography>{t(name)}</SoftTypography>
      <SoftInput
        value={value ? value : ""}
        onChange={handle}
        label={name}
        size="small"
        placeholder={t(name)}
        type={type}
      />
    </SoftBox>
  );
}
