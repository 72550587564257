// @mui material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Custom styles for the SidenavCard
import { card, cardContent, cardIcon } from "examples/Sidenav/styles/sidenavCard";

// Soft UI Dashboard React context
import { useSoftUIController } from "context";

export default function SidenavVideoCard({ isVideoPlaying, toggleVideoPlaying, selectedVideo, setSelectedVideo, videosData }) {

  const [controller] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;

  const updateVideoByTitle = title => {
    const newSelectedVideo = videosData.find(video => video.title === title)
    setSelectedVideo(newSelectedVideo)
  }

  return (
    <>
      <Card sx={(theme) => card(theme, { miniSidenav })} md={{ cursor: "pointer" }} onClick={toggleVideoPlaying} target="_blank" style={{ cursor: "pointer" }}>
        <CardContent sx={(theme) => cardContent(theme, { sidenavColor })}>
          <SoftBox display="inline-flex">
            <SoftBox
              bgColor="white"
              width="2rem"
              height="2rem"
              borderRadius="md"
              shadow="md"
              mr={1.5}
              p={0.5}
            >
              <Icon fontSize="medium" sx={(theme) => cardIcon(theme, { sidenavColor })}>
                {isVideoPlaying ? "close" : "cast"}
              </Icon>
            </SoftBox>
            <SoftBox>
              <SoftTypography variant="h6" color="white" mt={0.4}>
                Videos
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </CardContent>
      </Card>
      <SoftBox mt={1.4}>
        {(selectedVideo && isVideoPlaying) && <Autocomplete
          disablePortal
          value={selectedVideo.title}
          onChange={(e, val) => updateVideoByTitle(val)}
          options={videosData.map(video => video.title)}
          renderInput={params => <TextField {...params} />}
        />}
      </SoftBox>
    </>
  );
}

