import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import reducers from "redux/reducers";
import App from "App";
import { SoftUIControllerProvider } from "context";
import sessionSaverMiddleware from "./utils/sessionSaverMiddleware";
import { runSentry } from "sentry";
import { PostHogProvider } from 'posthog-js/react'

// We use Sentry to receive error warnings
runSentry();

// Load state from local storage (if available)
let initialState = {};
try {
  const savedState = localStorage.getItem("reduxState");
  if (savedState) initialState = JSON.parse(savedState);
} catch (error) {
  console.error("LocalStorage access error:", error);
  // Fallback to an empty initial state or any default state you have
  initialState = {};
}

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sessionSaverMiddleware),
  preloadedState: initialState, // Set the preloadedState with the initial state
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <SoftUIControllerProvider>
        <PostHogProvider
          apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={{ api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST }}
        >
          <App />
        </PostHogProvider>
      </SoftUIControllerProvider>
    </BrowserRouter>
  </Provider>
);
