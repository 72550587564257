// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
// Translate
import { useTranslation } from 'react-i18next'

export default function ReferalButton({ referalLink, label }) {
    const { t } = useTranslation();

    return (
        <Grid item xs={12} md={6} xl={6}>
            <SoftButton variant="outlined" color="info" size="small" fullWidth>
                <SoftTypography
                    component="a"
                    href={referalLink}
                    target="_blank"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                >
                    {`${t("Open")} ${label}`}
                </SoftTypography>
            </SoftButton>
        </Grid>
    );
}