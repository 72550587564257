// @mui material components
import LogoutIcon from "@mui/icons-material/Logout";
import GroupsIcon from '@mui/icons-material/Groups';
import DiamondIcon from "@mui/icons-material/Diamond";
import Cube from "examples/Icons/Cube";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import Settings from "examples/Icons/Settings";
import clientConfig from "utils/clientConfig";

export const getDataTabs = (role = 'client', subscription_status = null) => {
  const { useReferrals, usePremiumModel } = clientConfig;
  const dataTabs = [
    {
      key: "profile",
      name: "profile",
      icon: <AccountCircleRoundedIcon fontSize="medium" />,
      value: 'profile'
    },
    {
      key: "settings",
      name: "settings",
      icon: <Settings fontSize="medium" />,
      value: 'settings'
    },
    {
      key: "exchanges",
      name: "exchanges",
      icon: <Cube fontSize="medium" />,
      value: 'exchanges'
    },
    (usePremiumModel && role !== 'admin' && subscription_status !== "full") && {
      key: "plans",
      name: "plans",
      icon: <DiamondIcon fontSize="medium" />,
      value: 'plans'
    },
    useReferrals && {
      key: "referrals",
      name: "referrals",
      icon: <GroupsIcon fontSize="medium" />,
      value: 'referrals'
    },
    {
      key: "sign-out",
      name: "sign out",
      icon: <LogoutIcon fontSize="medium" />,
      value: 'signout'
    },
  ];
  return dataTabs;
};
