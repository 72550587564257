// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// @mui material components
import Switch from "@mui/material/Switch";

// Translate
import { useTranslation } from "react-i18next";

export default function RememberMeSwitch({ value, onChange }) {
  const { t } = useTranslation();
  const handleChange = ({ target }) => {
    const { checked } = target;
    onChange({ target: { name: "rememberMe", value: checked } });
  };

  return (
    <SoftBox display="flex" alignItems="center">
      <Switch checked={value} onChange={handleChange} />
      <SoftTypography
        variant="button"
        fontWeight="regular"
        onClick={onChange}
        sx={{ cursor: "pointer", userSelect: "none" }}
      >
        &nbsp;&nbsp;{t("Remember me")}
      </SoftTypography>
    </SoftBox>
  );
}

// Setting default values for the props of CoverLayout
RememberMeSwitch.defaultProps = {
  value: true,
};

// Typechecking props for the CoverLayout
RememberMeSwitch.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
