import axios from "axios";
import { BACK_API } from "apiconfig"
import { store } from "index";

export async function fetchArbitrationActions() {
  const { id } = store.getState().currentUser.data;
  const headers = {
    'token': store.getState().currentUser.data.jwtToken,
    'id': store.getState().currentUser.data.id
  };
  const url = `${BACK_API}/arbitration-actions`;
  const params = { userId: id };
  return await axios
    .get(url, { params, headers })
    .then(res => res.data)
    .catch(err => { throw err })
}

export async function fetchArbitrationOpportunities() {
  const headers = {
    'token': store.getState().currentUser.data.jwtToken,
    'id': store.getState().currentUser.data.id
  };
  const url = `${BACK_API}/arbitration-opportunities`;
  const params = {};
  return await axios
    .get(url, { params, headers })
    .then(res => res.data)
    .catch(err => { throw err })
}

export async function fetchArbitrationStrategies() {
  const { id } = store.getState().currentUser.data;
  const headers = {
    'token': store.getState().currentUser.data.jwtToken,
    'id': store.getState().currentUser.data.id
  };
  const url = `${BACK_API}/arbitration-strategies`;
  const params = { userId: id };
  return await axios
    .get(url, { params, headers })
    .then(res => res.data)
    .catch(err => { throw err })
}

export async function fetchArbitrationExchanges() {
  const headers = {
    'token': store.getState().currentUser.data.jwtToken,
    'id': store.getState().currentUser.data.id
  };
  const url = `${BACK_API}/arbitration-exchanges`;
  const params = {};
  return await axios
    .get(url, { params, headers })
    .then(res => res.data)
    .catch(err => { throw err })
}

export async function fetchArbitrationPairs() {
  const headers = {
    'token': store.getState().currentUser.data.jwtToken,
    'id': store.getState().currentUser.data.id
  };
  const url = `${BACK_API}/arbitration-pairs`;
  const params = {};
  return await axios
    .get(url, { params, headers })
    .then(res => res.data)
    .catch(err => { throw err })
}

export async function createArbitrationStrategy(strategy) {
  const { id, jwtToken } = store.getState().currentUser.data
  const headers = { 'token': jwtToken, 'id': id };
  const body = { ...strategy };
  const url = `${BACK_API}/arbitration-strategy`;
  return await axios
    .post(url, body, { headers })
    .then(res => res.data);
}

export async function deleteArbitrationStrategy(strategyId) {
  const { id, jwtToken } = store.getState().currentUser.data
  const headers = { 'token': jwtToken, 'id': id };
  const data = { strategyId };
  const url = `${BACK_API}/arbitration-strategy`;
  return await axios
    .delete(url, { headers, data })
    .then(res => res.data);
}

export async function patchArbitrationStrategy(strategy) {
  const { id, jwtToken } = store.getState().currentUser.data
  const headers = { 'token': jwtToken, 'id': id };
  const data = strategy
  const url = `${BACK_API}/arbitration-strategy`;
  return await axios
    .patch(url, data, { headers })
    .then(res => res.data);
}
