/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftButton from "components/SoftButton";

// Translate
import { useTranslation } from "react-i18next";

function DefaultPricingCard({ badge, price, specifications, action }) {
  const { t } = useTranslation();
  const renderSpecifications = specifications.map(
    ({ label, icon, color, size }) => (
      <SoftBox
        key={label}
        display="flex"
        alignItems="center"
        justifyContent={icon ? "flex-start" : "center"}
        p={1}
        opacity={icon === "close" ? 0.5 : 1}
      >
        {icon && (
          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={size}
            height={size}
            borderRadius="50%"
            shaodw="md"
            bgColor={color}
            variant="gradient"
            mr={2}
          >
            <SoftTypography
              variant="button"
              color="white"
              sx={{ lineHeight: 0 }}
            >
              <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
            </SoftTypography>
          </SoftBox>
        )}
        <SoftTypography variant="body2" color="text">
          {t(label)}
        </SoftTypography>
      </SoftBox>
    )
  );

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox pt={3} pb={2} px={2} textAlign="center">
        <SoftBadge
          variant="contained"
          color={badge.color}
          size="sm"
          badgeContent={t(badge.label)}
          circular
          container
        />
        <SoftBox my={1}>
          <SoftTypography variant="h1">
            <SoftTypography display="inline" component="small" variant="h2">
              {price.currency}
            </SoftTypography>
            {t(price.value)}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox
        height="100%"
        pb={3}
        px={3}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <SoftBox height="90%">{renderSpecifications}</SoftBox>
        <SoftBox height="10%" mt={3}>
          {badge.label === "free" ? null : (
            <SoftButton
              id="submit"
              role="link"
              variant="gradient"
              color={action.color}
              fullWidth
              onClick={action.handle}
              disabled={action.disabled}
            >
              {t(action.label)}&nbsp;
              <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </SoftButton>
          )}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Typechecking props for the DefaultPricingCard
DefaultPricingCard.propTypes = {
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    handle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    disabled: PropTypes.bool.isRequired,
  }).isRequired,
};

export default DefaultPricingCard;
