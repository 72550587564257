import { BACK_API } from "apiconfig";
import axios from "axios";
import { store } from "index";

export const toggleFavPair = async (pair, exchange) => {
    const currentUser = store.getState().currentUser.data
    const headers = {
        'token': store.getState().currentUser.data.jwtToken,
        'id': store.getState().currentUser.data.id
    };
    const url = `${BACK_API}/toggle-fav-pair`;
    const body = { pair, exchange, userId: currentUser.id }
    return await axios
        .post(url, body, { headers })
        .then(res => res.data)
        .catch(err => console.log(err))
}

export const fetchFavPairs = async () => {
    const currentUser = store.getState().currentUser.data
    const headers = {
        'token': store.getState().currentUser.data.jwtToken,
        'id': store.getState().currentUser.data.id
    };
    const url = `${BACK_API}/fav-pairs`;
    const params = { userId: currentUser.id }
    return await axios
        .get(url, { params, headers })
        .then(res => res.data)
        .catch(err => { throw err })
}

export const fetchExchangePairs = async exchange => {
    const headers = {
        'token': store.getState().currentUser.data.jwtToken,
        'id': store.getState().currentUser.data.id
    };
    const url = `${BACK_API}/exchange-pairs`;
    const params = { exchange }
    return await axios
        .get(url, { params, headers })
        .then(res => res)
        .catch(err => { throw err })
}

export const fetchExchangePair = async (exchange, pair) => {
    const headers = {
        'token': store.getState().currentUser.data.jwtToken,
        'id': store.getState().currentUser.data.id
    };
    const url = `${BACK_API}/exchange-pair`;
    const params = { exchange, pair }
    return await axios
        .get(url, { params, headers })
        .then(res => res)
        .catch(err => { throw err })
}