// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

export const contactUsForm = [
  {
    id: uuidv4(),
    name: "user_email",
    containerProps: { xs: 12 },
    label: "Company email",
    placeholder: "Email address",
    inputProps: {
      type: "email",
      size: "medium",
      required: true,
    },
  },
  {
    id: uuidv4(),
    name: "user_name",
    containerProps: { xs: 12, lg: 6 },
    label: "Your name",
    placeholder: "Your name",
    inputProps: {
      size: "medium",
      required: true,
    },
  },
  {
    id: uuidv4(),
    name: "user_phone",
    containerProps: { xs: 12, lg: 6 },
    label: "Phone number",
    placeholder: "Phone number (optional)",
    inputProps: {
      type: "tel",
      size: "medium",
      required: false,
    },
  },
  {
    id: uuidv4(),
    name: "user_message",
    containerProps: { xs: 12 },
    label: "How can we help?",
    placeholder: "Your company needs",
    inputProps: {
      size: "medium",
      multiline: true,
      rows: 10,
      required: true,
    },
  },
];
