import { useEffect } from 'react';


import { useParams } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from "react-redux";
import { clearInformativeMessage } from 'redux/actions/informativeMessage';


// Local components
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Register from './components/Register';
import Message from 'layouts/Common/Status/Message';
import TwoFA from './components/2FA';
import Error404 from 'layouts/Common/error/404';

export default function AuthPage() {

  const { message } = useSelector(state => state.informativeMessage);
  const dispatch = useDispatch();

  let { authType } = useParams();
  let componentToRender = null;

  switch (authType) {
    case 'login':
      componentToRender = <Login />;
      break;

    case 'forgot-password':
      componentToRender = <ForgotPassword />;
      break;

    case 'reset-password':
      componentToRender = <ResetPassword />;
      break;

    case 'register':
      componentToRender = <Register />;
      break;

    case '2fa':
      componentToRender = <TwoFA />;
      break;

    default:
      componentToRender = <Error404 />;
      break;
  };

  useEffect(() => {
    return () => {
      dispatch(clearInformativeMessage());
    };
  }, []);

  return (
    <>
      {message && <Message />}
      {componentToRender}
    </>
  );
};
