import SoftSnackbar from "components/SoftSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "redux/actions/toast";

export default function Toast() {

  const dispatch = useDispatch()
  const { open, title, body } = useSelector((state) => state.toast);

  const clearToast = () => {
    dispatch(setToast(false, "", ""))
  };

  return (
    <SoftSnackbar
      icon="notifications"
      title={title}
      content={body}
      open={open}
      close={clearToast}
    />
  );
}
