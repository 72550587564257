import PropTypes from 'prop-types'
import Icon from "@mui/material/Icon";
import colors from "assets/theme/base/colors";

function Back({ handle }) {
    return (
        <Icon
            fontSize="large"
            sx={{
                color: colors.white.main,
                cursor: "pointer",
                zIndex: 999999,
                "&:hover": {
                    transform: "scale(1.2)",
                },
            }}
            onClick={handle}
        >
            arrow_back
        </Icon>
    )
}

Back.propTypes = {
    handle: PropTypes.func
}

export default Back