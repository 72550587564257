import { useEffect } from 'react'
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import TradingChart from "./components/TradingChart";
import TradeModal from "./components/TradeModal";
import usePairData from "utils/usePairData";
import './index.scss';
import useTour from 'hooks/tours/useTour';

export default function TradingPage({ setChartOpened, selectedExchange, selectedPair }) {
    const pairData = usePairData(selectedPair, selectedExchange)

    const { TourComponent, setTourSection } = useTour();

    useEffect(() => {
        setTourSection("tradeModal")
    }, []);

    return (
        <div id="tradeModal">
            {TourComponent}
            <SoftBox>
                <TradeModal setChartOpened={setChartOpened} pairData={pairData} />
            </SoftBox>
            <SoftBox>
                <TradingChart selectedPair={selectedPair} selectedExchange={selectedExchange} />
            </SoftBox>
        </div>
    );
}

