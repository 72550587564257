// Soft UI Dashboard React components
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TradesTable from "./components/TradesTable/index";
import { getOrdersFromExchanges } from "redux/actions/orders";
import Loader from "components/Loader";
import RefreshButton from "./components/RefreshButton/index";
import WalletsAddedGuard from "componentsCustom/WalletsAddedGuard";
// Translate
import { useTranslation } from 'react-i18next';
import SoftBox from "components/SoftBox";

function Orders() {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const trades = useSelector(state => state.orders.data.trades)
  const tradesLoading = useSelector(state => state.orders.loading)
  const [selectedOrderType, setSelectedOrderType] = useState("closed")
  const selectedTrades = trades.filter(trade => trade.status === selectedOrderType)
  const openTradesLength = trades.filter(trade => trade.status === "open").length

  const getInitialTrades = () => dispatch(getOrdersFromExchanges())
  const handleOrderType = e => setSelectedOrderType(e.target.value)
  const tradesSelected = selectedOrderType === "open" || selectedOrderType === "closed"

  return (
    <DashboardLayout>
      <WalletsAddedGuard>
        <SoftBox id="ordersSection">
          <RefreshButton isLoading={tradesLoading} action={getInitialTrades} />
          <SoftButton id="closedOrders" key="closed" className={selectedOrderType === "closed" ? "selected" : "notSelected"} variant="text" color="dark" value="closed" onClick={handleOrderType}>{t('Closed')}</SoftButton>
          <SoftButton id="openedOrders" key="open" className={selectedOrderType === "open" ? "selected" : "notSelected"} variant="text" color="dark" value="open" onClick={handleOrderType}>{`${t('Opened')} (${openTradesLength})`}</SoftButton>
          {tradesLoading && <Loader />}
          {tradesSelected && <TradesTable trades={selectedTrades} selectedOrderType={selectedOrderType} />}
        </SoftBox>
      </WalletsAddedGuard>
    </DashboardLayout>
  );
}

export default Orders;