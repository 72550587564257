import success from "assets/animation/success.json";
import failed from "assets/animation/failed.json";
import { STATUS } from "constants/fetchStatus";
import Loader from "components/Loader";
import Lottie from "lottie-react";

export default function StatusAnimations({
  status,
  setStatusFunction,
  afterOKAnimationAction,
}) {
  const animationCompleted = () => {
    setStatusFunction(STATUS.PENDING);
    if (status === STATUS.OK && afterOKAnimationAction) {
      afterOKAnimationAction();
    }
  };

  const getAnimationDataByStatus = () => {
    switch (status) {
      case STATUS.OK:
        return success;
      case STATUS.ERROR:
        return failed;
      default:
        return failed;
    }
  };

  return (
    <>
      {status === STATUS.LOADING && <Loader />}
      {(status === STATUS.OK || status === STATUS.ERROR) && (
        <center>
          <Lottie
            loop={false}
            style={{
              display: "inline-block",
              padding: "0px !important",
              margin: "0px !important",
              width: 54,
              height: "auto",
            }}
            onComplete={animationCompleted}
            animationData={getAnimationDataByStatus()}
          />
        </center>
      )}
    </>
  );
}
