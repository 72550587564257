// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Overview page components
import PlatformSettings from "layouts/Common/Profile/components/PlatformSettings";

export default function SettingsTab() {
    return (
        <SoftBox mt={5} mb={3}>
            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={6} xl={4}>
                    <PlatformSettings />
                </Grid>
            </Grid>
        </SoftBox>
    )
}