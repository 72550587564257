// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// Local Components
import TopCoins from "./components/TopCoins";
import { useState } from "react";
import MarketSelector from "./components/MarketSelector";
import HeatMap from "./components/HeatMap";
import SoftBox from "components/SoftBox";

export default function Markets() {
  const markets = ["top100", "heatmap"];
  const [selectedMarket, setSelectedMarket] = useState(markets[0]);

  return (
    <DashboardLayout>
      <MarketSelector
        selectedMarket={selectedMarket}
        setSelectedMarket={setSelectedMarket}
        markets={markets}
      />
      <SoftBox id="markets" mt={2}>
        {selectedMarket === "heatmap" && <HeatMap />}
        {selectedMarket === "top100" && <TopCoins />}
      </SoftBox>
    </DashboardLayout>
  );
}
