/***** We receive an action and set de data to the store *****/

import { SET_MESSAGES, MESSAGES_STATUS } from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";

export default (messages = { data: [], status: STATUS.PENDING }, action) => {
  switch (action.type) {
    case SET_MESSAGES:
      return { ...messages, data: action.payload };
    case MESSAGES_STATUS:
      return { ...messages, status: action.payload };
    default:
      return messages;
  }
};
