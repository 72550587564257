const pairsFilteredByFavs = (unfilteredPairs, favPairs) => {
    const filteredByFav = unfilteredPairs.filter(pair => favPairs.some(favPair => favPair.name === pair.name))
    const mappedIsFav = filteredByFav.map(pair => ({ ...pair, isFav: true }))
    return mappedIsFav
}

const pairsFilteredByInWallet = (wallets, unfilteredPairs, favPairs) => {
    const filteredPairs = unfilteredPairs.filter(pair => wallets[pair.exchange].pairs.some(walletPair => pair.name.split("-")[0] === walletPair.symbol)).map(pair => ({ ...pair, isFav: favPairs.some(favPair => favPair.name === pair.name) }))
    return filteredPairs
}

const pairsUnfiltered = (unfilteredPairs, favPairs) => {
    return unfilteredPairs.map(pair => ({ ...pair, isFav: favPairs.some(favPair => favPair.name === pair.name) }))
}

const addAmountToPairs = (pairs, wallets) => {
    const mappedPairs = pairs.map(pair => {
        const walletPair = wallets[pair.exchange].pairs.find(walletPair => walletPair.symbol === pair.name.split("-")[0])
        return {
             ...pair,
             baseCoinValue: walletPair ? walletPair.baseCoinValue : 0
        }
    })
    return mappedPairs
}

export const filterTradingPairs = (wallets, filterCriteria, unfilteredPairs, favPairs) => {
    switch (filterCriteria) {
        case "favs":
            return addAmountToPairs(pairsFilteredByFavs(unfilteredPairs, favPairs), wallets)
        case "in-wallet":
            return addAmountToPairs(pairsFilteredByInWallet(wallets, unfilteredPairs, favPairs), wallets)
        default:
            return addAmountToPairs(pairsUnfiltered(unfilteredPairs, favPairs), wallets)
    }
}
