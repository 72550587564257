import { SET_FAV_PAIRS, FAV_PAIRS_STATUS } from "constants/actionTypes"
import { fetchFavPairs, toggleFavPair } from "api/pairs"
import { STATUS } from "constants/fetchStatus"

/***** Redux actions that end up in the store *****/

export const getFavPairs = () => async dispatch => {
    try {
        dispatch({ type: FAV_PAIRS_STATUS, payload: STATUS.LOADING })
        const favPairs = await fetchFavPairs()
        dispatch({ type: SET_FAV_PAIRS, payload: favPairs })
        dispatch({ type: FAV_PAIRS_STATUS, payload: STATUS.OK })
    } catch (error) {
        dispatch({ type: FAV_PAIRS_STATUS, payload: STATUS.ERROR })
    }
}

export const toggleFavPairAction = (pair, exchange) => async dispatch => {
    try {
        dispatch({ type: FAV_PAIRS_STATUS, payload: STATUS.LOADING })
        const togglePairResult = await toggleFavPair(pair, exchange)
        dispatch({ type: SET_FAV_PAIRS, payload: togglePairResult })
        dispatch({ type: FAV_PAIRS_STATUS, payload: STATUS.OK })
    } catch (error) {
        dispatch({ type: FAV_PAIRS_STATUS, payload: STATUS.ERROR })
    }
}
