import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from "@mui/material/List";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React Base Styles
import colors from "assets/theme/base/colors";

const { gradients } = colors;

const AccordionVideo = ({ setShowPlayList, showPlayList, setCurrentVideo, section, videos, index }) => {
    return (
        <Accordion
            defaultExpanded={index === 0 ? true : false}
            sx={{
                bgcolor: "#ffffff00",
                border: "1px solid",
                borderColor: "#ffffff00",
                boxShadow: 0,
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: colors.white.main }} />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                    borderRadius: "10px",
                    "&:hover": {
                        backgroundColor: gradients.info.main,
                    },
                }}
            >
                <SoftTypography fontWeight="bold" color="white" fontSize="0.8rem">
                    {section}
                </SoftTypography>
            </AccordionSummary>
            <AccordionDetails>
                <List subheader={<li />}>
                    {videos.map((video, index) => {
                        const { title, duration, url, author } = video;
                        return (
                            <ListItem
                                key={`item-${index}`}
                                secondaryAction={
                                    <SoftTypography
                                        sx={{ display: "inline", fontSize: "0.8rem" }}
                                        variant="caption"
                                        px="1rem"
                                        color="white"
                                    >
                                        {duration}
                                    </SoftTypography>
                                }
                                sx={{
                                    cursor: "pointer",
                                    borderRadius: "10px",
                                    "&:hover": {
                                        backgroundColor: gradients.info.main,
                                    },
                                }}
                                onClick={() => {
                                    setShowPlayList(!showPlayList);
                                    setCurrentVideo(url);
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <SoftBox
                                            display="flex"
                                            alignItems="center"
                                            gap={2}
                                            p="1rem"
                                        >
                                            <SoftBox>
                                                <SoftTypography
                                                    sx={{ display: "inline" }}
                                                    component="span"
                                                    variant="caption"
                                                    color="white"
                                                >
                                                    {index + 1}
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="center"
                                            >
                                                <SoftTypography
                                                    sx={{
                                                        display: "inline-block",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        maxWidth: "30ch",
                                                    }}
                                                    component="span"
                                                    variant="button"
                                                    color="white"
                                                >
                                                    {title}
                                                </SoftTypography>
                                                <SoftTypography
                                                    sx={{
                                                        display: "inline-block",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        maxWidth: "30ch",
                                                    }}
                                                    component="span"
                                                    variant="caption"
                                                    color="white"
                                                >
                                                    {author}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    }
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </AccordionDetails>
        </Accordion>
    )
}

export default AccordionVideo