import PropTypes from 'prop-types'
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";
import SoftBox from 'components/SoftBox'
import colors from "assets/theme/base/colors";

function Action({ progress = 0, playing, handle }) {
    return (
        <SoftBox sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
                variant="determinate"
                value={progress}
                size="2.4rem"
                sx={{ color: colors.white.main }}
            />
            <SoftBox
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                onClick={handle}
            >
                <Icon
                    fontSize="large"
                    sx={{
                        color: colors.white.main,
                        cursor: "pointer",
                        "&:hover": {
                            transform: "scale(1.2)",
                        },
                    }}
                >
                    {playing ? "pause" : "play_circle"}
                </Icon>
            </SoftBox>
        </SoftBox>
    )
}

Action.propTypes = {
    progress: PropTypes.number,
    playing: PropTypes.bool,
    handle: PropTypes.func
}

export default Action
