import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import clientConfig from 'utils/clientConfig'
import PropTypes from 'prop-types'
import useAssets from 'hooks/useAssets'

function Avatar({ playList }) {
    const { logo } = useAssets()

    return (
        <SoftBox p={playList ? "1rem" : "0.3rem"} display="flex" justifyContent="flex-start" alignItems="center" width="100%">
            <SoftBox
                p="0.5rem"
                height="100%"
                maxHeight={playList ? "60px" : "40px"}
                width="100%"
                maxWidth={playList ? "60px" : "40px"}
                bgColor="dark"
                variant="gradient"
                borderRadius="10px"
                mr="0.5rem"
                display="flex"
                justifyContent="flex-center"
            >
                <img src={logo} alt={`${clientConfig.emailName}_icon`} width="100%" objectFit="fill" />
            </SoftBox>
            <SoftBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
            >
                <SoftTypography fontWeight="bold" fontSize={playList ? "1rem" : "0.7rem"} color="white">
                    Deep Focus
                </SoftTypography>
                <SoftTypography fontSize={playList ? "0.8rem" : "0.5rem"} color="white">
                    Crypto
                </SoftTypography>
            </SoftBox>
        </SoftBox>
    )
}

Avatar.propTypes = {
    playList: PropTypes.bool
}

export default Avatar

