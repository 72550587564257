import { LOADING_ORDERS, SET_ORDERS, UPDATE_ORDERS_BY_EXCHANGE } from "constants/actionTypes"
import { fetchOrdersFromExchange, fetchOrdersFromExchanges } from "api/orders"
import { parseActiveExchangesFromKeys } from "utils/parseActiveExchangesFromKeys"
import { store } from "index"

/***** Redux actions that end up in the store *****/

export const getOrdersFromExchange = exchange => async dispatch => {
    try {
        dispatch({ type: LOADING_ORDERS, payload: true })
        const orders = await fetchOrdersFromExchange(exchange)
        dispatch({ type: UPDATE_ORDERS_BY_EXCHANGE, payload: { fetchedOrders: orders, exchange } })
        dispatch({ type: LOADING_ORDERS, payload: false })
    } catch (error) {
        console.log(error.message)
    }
}

export const getOrdersFromExchanges = () => async dispatch => {
    try {
        dispatch({ type: LOADING_ORDERS, payload: true })
        const state = store.getState()
        const keys = state.keys.data
        const exchanges = parseActiveExchangesFromKeys(keys)
        const orders = await fetchOrdersFromExchanges(exchanges)
        dispatch({ type: SET_ORDERS, payload: { orders } })
        dispatch({ type: LOADING_ORDERS, payload: false })
    } catch (error) {
        console.log(error.message)
    }
}
