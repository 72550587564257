/// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// Translate
import { useTranslation } from "react-i18next";

export default function AmountInput({
  priceValue,
  inputValue,
  setStrategy,
  focus = false,
}) {
  const { t } = useTranslation();

  const handledChange = ({ target }) => {
    const { value } = target;
    setStrategy((prev) => ({ ...prev, amount: value }));
  };

  return (
    <SoftBox id="amountInput" mb={3}>
      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {t("amount")}
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
            color="error"
          >
            *
          </SoftTypography>
        </SoftTypography>
      </SoftBox>
      <SoftInput
        className="inputs"
        name="amount"
        size="small"
        type="number"
        placeholder={t("Amount")}
        value={inputValue || ""}
        onChange={handledChange}
        autoFocus={focus}
        required
      />
      <SoftBox mt={0.75}>
        <SoftTypography
          component="div"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
          color="dark"
        >
          {priceValue && inputValue > 0 ? `${t("equivalent")}: $${(inputValue * priceValue).toFixed(2)}` : "\u00A0"}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}
