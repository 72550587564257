import axios from "axios";
import { BACK_API } from "apiconfig";
import { store } from "index";

export const fetchPricing = async () => {
    const url = `${BACK_API}/pricing`;
    const headers = {
        token: store.getState().currentUser.data.jwtToken,
        id: store.getState().currentUser.data.id,
    };
    return await axios
        .get(url, { headers })
        .then(({ data }) => data)
        .catch((err) => {
            throw err;
        });
};
