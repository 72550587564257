// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// Soft UI Dashboard PRO React example components
import OutlinedCounterCard from "examples/Cards/CounterCards/OutlinedCounterCard";
import { useTranslation } from 'react-i18next';

export default function Data({ referrals }) {
    const { t } = useTranslation();
    return (
        <SoftBox mb={2}>
            <Card>
                <SoftBox lineHeight={1} px={2} pt={2}>
                    <SoftTypography variant="h6" fontWeight="medium">
                        {t('My referrals')}
                    </SoftTypography>
                    <SoftTypography variant="button" fontWeight="regular" color="text">
                        {t('These are the referrals that are currently making you money')}
                    </SoftTypography>
                </SoftBox>
                <SoftBox p={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={6} lg={3}>
                            <OutlinedCounterCard count={referrals.totalEarnings} suffix={"$"} title={t('earnings')} useDecimals={true} />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <OutlinedCounterCard count={referrals.users.length} title={t('referrals')} useDecimals={false} />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <OutlinedCounterCard count={referrals.avgUserValue} suffix={"$"} title={t('avg. user value')} useDecimals={true} />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <OutlinedCounterCard count={referrals.paidUsersPct} suffix="%" title={t('paid users')} useDecimals={false} />
                        </Grid>
                    </Grid>
                </SoftBox>
                <SoftBox p={2}>
                    {/*  <ReferralTree referrals={referrals} /> */}
                    {/* tree wip */}
                </SoftBox>
            </Card>
        </SoftBox>
    )
}