// @mui material components
import { Grid } from "@mui/material";
import Icon from "@mui/material/Icon";
import { useState } from "react";
// Soft UI Dashboard React components
import SoftButton from "components/SoftButton";
import { cancelOrder } from "api/orders";
import { STATUS } from "constants/fetchStatus";
import StatusAnimations from "componentsCustom/StatusAnimations";
import { useDispatch } from "react-redux";
import { getOrdersFromExchange } from "redux/actions/orders";
import '../../index.css';

export default function CancelButton({ trade }) {

    const dispatch = useDispatch()
    const [deleteStatus, setDeleteStatus] = useState(STATUS.PENDING)
    const handleCancelOrder = async () => {
        setDeleteStatus(STATUS.LOADING)
        cancelOrder(trade)
            .then(() => setDeleteStatus(STATUS.OK))
            .catch(() => setDeleteStatus(STATUS.ERROR))
    }
    
    const updateRedux = () => dispatch(getOrdersFromExchange(trade.exchange))

    return (
        <Grid item lg={1}>
            <StatusAnimations status={deleteStatus} setStatusFunction={setDeleteStatus} afterOKAnimationAction={updateRedux} />
            {deleteStatus === STATUS.PENDING && <SoftButton onClick={handleCancelOrder} className="crypto-close-order" iconOnly variant="gradient" size="small" color="error">
                <Icon>close</Icon>
            </SoftButton>}

        </Grid>
    )
}