/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useSelector } from "react-redux";
// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem from "examples/Timeline/TimelineItem";
import SoftTypography from "components/SoftTypography";

// Data
import { timestampToDateTime } from "utils/timestampToDateTime";

// Translate
import { useTranslation } from "react-i18next";

function Notifications() {
  const { t } = useTranslation();
  const notifications = useSelector((state) => state.notifications);
  const renderTimelineItems = notifications.map((notification, i) => {
    const { id, title, body, status, timestamp } = notification;
    const getColor = () => {
      switch (body) {
        case "canceled":
          return "error";
        default:
          return status;
      }
    };
    return (
      <TimelineItem
        key={id}
        color={getColor()}
        icon={title === "Subscription Status" ? "lock" : "payment"}
        title={t(title)}
        dateTime={timestampToDateTime(timestamp)}
        description={body}
        badges={[
          title === "Subscription Status" ? t("subscription") : t("trading"),
        ]}
        lastItem={i === notifications.length - 1}
      />
    );
  });

  /* const notificationsExample = [
    {
      color: "success",
      icon: "notifications",
      title: "$2400, Design changes",
      dateTime: "22 DEC 7:20 PM",
      description:
        "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
      badges: ["design"],
    },
    {
      color: "error",
      icon: "inventory_2",
      title: "New order #1832412",
      dateTime: "21 DEC 11 PM",
      description:
        "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
      badges: ["order", "#1832412"],
    },
    {
      color: "info",
      icon: "shopping_cart",
      title: "Server payments for April",
      dateTime: "21 DEC 9:34 PM",
      description:
        "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
      badges: ["server", "payments"],
    },
    {
      color: "warning",
      icon: "payment",
      title: "New card added for order #4395133",
      dateTime: "20 DEC 2:20 AM",
      description:
        "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
      badges: ["card", "#4395133", "priority"],
    },
    {
      color: "primary",
      icon: "vpn_key",
      title: "Unlock packages for development",
      dateTime: "18 DEC 4:54 AM",
      description:
        "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
      badges: ["development"],
    },
    {
      color: "success",
      icon: "inbox",
      title: "New message unread",
      dateTime: "16 DEC",
      description:
        "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
      badges: ["message"],
    },
    {
      color: "info",
      icon: "campaign",
      title: "Notifications unread",
      dateTime: "15 DEC",
      description:
        "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
    },
    {
      color: "warning",
      icon: "archive",
      title: "New request",
      dateTime: "14 DEC",
      description:
        "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
      badges: ["request", "priority"],
    },
    {
      color: "secondary",
      icon: "sports_esports",
      title: "Controller issues",
      dateTime: "13 DEC",
      description:
        "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
      badges: ["controller"],
      lastItem: true,
    },
  ]; */

  return (
    <DashboardLayout>
      <SoftBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}></Grid>
          <Grid item xs={12} lg={6}>
            {notifications.length > 0 && (
              <TimelineList title={t("Notifications")}>
                {renderTimelineItems}
              </TimelineList>
            )}
            {notifications.length === 0 && (
              <SoftTypography variant="text" fontWeight="medium">
                {t("No notifications!")}
              </SoftTypography>
            )}
          </Grid>
          <Grid item xs={12} lg={3}></Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Notifications;
