// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftButton from "components/SoftButton";

// Translate
import { useTranslation } from "react-i18next";

export default function RedirectButton({ route, label }) {
  const { t } = useTranslation();
  return (
    <SoftButton
      component={Link}
      to={route}
      variant="gradient"
      color="dark"
      fullWidth
    >
      {t(label)}
    </SoftButton>
  );
}

// Setting default values for the props of CoverLayout
RedirectButton.defaultProps = {
  route: "/",
  label: "action",
};

// Typechecking props for the CoverLayout
RedirectButton.propTypes = {
  route: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
