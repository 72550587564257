import { STATUS } from 'constants/fetchStatus';
import { useState, useEffect } from 'react';

const useFetch = (fetchFunction, ...params) => {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState(STATUS.PENDING);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      try {
        setStatus(STATUS.LOADING);
        const res = await fetchFunction(...params, { signal });
        if (res.status < 200 || res.status >= 300) {
          throw new Error(`HTTP status ${res.status}`);
        }
        setData(res.data);
        setStatus(STATUS.OK);
      } catch (error) {
        if (!abortController.signal.aborted) {
          setStatus(STATUS.ERROR);
        }
      }
    };

    fetchData();

    return () => {
      abortController.abort();
    };
  }, [...params]);

  return { data, status };
};

export default useFetch;
