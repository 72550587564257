// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// Data
import { useState } from "react";
// @mui material components
import TradingPage from "./components/TradingPage";
import WalletsAddedGuard from "componentsCustom/WalletsAddedGuard";
import PairsTable from "./components/PairsTable";
import useFetch from "hooks/useFetch";
import Grid from "@mui/material/Grid";
import { fetchExchangePairs } from "api/pairs";
import { parseActiveExchangesFromKeys } from "utils/parseActiveExchangesFromKeys";
import { STATUS } from "constants/fetchStatus";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
// Components
import TransparentTabs from "componentsCustom/TransparentTabs";

function Trade() {
  const exchanges = parseActiveExchangesFromKeys();
  const [selectedExchange, setSelectedExchange] = useState(exchanges[0]);
  const { data: favPairs } = useSelector((state) => state.favPairs);
  const { data, status } = useFetch(fetchExchangePairs, selectedExchange);

  const favPairsAdded = favPairs.length > 0;
  const filterCriteriaOptions = [
    { value: "all", label: "all" },
    { value: "favs", label: "favorites" },
  ];

  const [selectedPair, setSelectedPair] = useState("");
  const [chartOpened, setChartOpened] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState(
    favPairsAdded ? filterCriteriaOptions[1] : filterCriteriaOptions[0]
  );

  const tradePair = (pair) => {
    setSelectedPair(pair);
    setChartOpened(true);
  };

  return (
    <DashboardLayout>
      <WalletsAddedGuard>
        <SoftBox id="traderSection">
          {!chartOpened && (
            <>
              <Grid container mb={2} justifyContent="center">
                <Grid item sm={12} md={8}>
                  <SoftBox>
                    <TransparentTabs
                      id="exchangesTabsTrade"
                      selectedOption={{
                        value: selectedExchange,
                        label: selectedExchange,
                      }}
                      options={exchanges.map((exchange) => ({
                        value: exchange,
                        label: exchange,
                      }))}
                      updateValue={(val) => setSelectedExchange(val.value)}
                    />
                  </SoftBox>
                </Grid>
                <Grid
                  item
                  sm={12}
                  md={4}
                  display="inline-flex"
                  justifyContent="flex-end"
                >
                  <SoftBox>
                    <TransparentTabs
                      id="filterTabsTrade"
                      selectedOption={filterCriteria}
                      options={filterCriteriaOptions}
                      updateValue={setFilterCriteria}
                    />
                  </SoftBox>
                </Grid>
              </Grid>
              {status === STATUS.ERROR && "ERROR"}
              {status === STATUS.LOADING && <Loader />}
              {status === STATUS.OK && (
                <SoftBox>
                  <PairsTable
                    unfilteredPairs={data}
                    selectedExchange={selectedExchange}
                    tradePair={tradePair}
                    filterCriteria={filterCriteria.value}
                    favPairs={favPairs}
                  />
                </SoftBox>
              )}
            </>
          )}
          {chartOpened && (
            <TradingPage
              setChartOpened={setChartOpened}
              selectedExchange={selectedExchange}
              selectedPair={selectedPair}
            />
          )}
        </SoftBox>
      </WalletsAddedGuard>
    </DashboardLayout>
  );
}

export default Trade;
