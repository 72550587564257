// Soft UI Dashboard PRO React components
import SoftButton from "components/SoftButton";

// @mui material components
import CircularProgress from '@mui/material/CircularProgress';

// Translate
import { useTranslation } from 'react-i18next';

export default function CancelButton({ loading, label, color, handle }) {
    const { t } = useTranslation();
    return (
        <SoftButton
            variant="gradient"
            color={color}
            disabled={loading}
            onClick={handle}
            fullWidth
        >
            {loading ? <CircularProgress color="inherit" size={15} /> : t(label)}
        </SoftButton>
    );
};

// Setting default values for the props of CoverLayout
CancelButton.defaultProps = {
    loading: false,
    label: "cancel",
    color: "error"
};
