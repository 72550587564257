import { v4 as uuidv4 } from "uuid";
import { Flags } from "./flags";
import { LANGUAGES } from "constants";

export const Languages = [
  {
    key: uuidv4(),
    label: "English",
    icon: Flags.en,
    value: LANGUAGES.EN,
  },
  {
    key: uuidv4(),
    label: "Spanish",
    icon: Flags.es,
    value: LANGUAGES.ES,
  },
];
