import "./styles.scss";

export default function Pair({ pair }) {
  const coin1 = pair.split(/[-/]/)[0].toLowerCase(); // Split by "-" or "/"
  const icon = `https://cdn.jsdelivr.net/npm/cryptocurrency-icons@0.18.1/32/icon/${coin1}.png`;

  return (
    <div className="cp-container">
      <img className="icon" src={icon} alt={coin1} />
      <div className="name">{pair}</div>
    </div>
  );
}
