// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import ReactModal from 'react-modal-resizable-draggable';
import Icon from "@mui/material/Icon";
import { useEffect, useState } from "react";
import SoftTypography from "components/SoftTypography";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { useDispatch } from "react-redux"
import OpenInFull from '@mui/icons-material/OpenInFull';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { getWallet } from "redux/actions/wallets";
import { getOrdersFromExchange } from "redux/actions/orders";
import SliderAmount from "./SliderAmount";
import { meaningfulAmountDecimals } from "utils/meaningfulAmountDecimals";
import { STATUS } from "constants/fetchStatus";
import { postTrade } from "api/trade";
import StatusAnimations from "componentsCustom/StatusAnimations";
import Loader from "components/Loader";
import '../index.scss';

// Translate
import { useTranslation } from 'react-i18next';
import { exchangeMinValue } from "api/orders";
import { trimDecimalZeros } from "utils/trimDecimalZeros";

export default function TradeModal({ setChartOpened, pairData }) {

    const { firstSymbol, secondSymbol, name: pairName, price: pairPrice, status: pairStatus, exchange } = pairData
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [orderType, setOrderType] = useState("market")
    const [orderStatus, setOrderStatus] = useState(STATUS.PENDING)
    const [minimized, setMinimized] = useState(false)
    const [amount, setAmount] = useState(0)
    const [total, setTotal] = useState(0)
    const [price, setPrice] = useState(0)
    const [side, setSide] = useState("buy")
    const [minAmount, setMinAmount] = useState("-")
    const [minCost, setMinCost] = useState("-")

    const getPriceOnGive = newPrice => {
        if (orderType === "limit") {
            return side === "buy" ? 1 / newPrice : newPrice
        } else return side === "buy" ? 1 / pairPrice : pairPrice
    }

    const getPrice = () => {
        if (orderType === "limit") {
            return price
        } else return pairPrice
    }

    const step = side === "buy" ? (secondSymbol / 12).toFixed(2) : (firstSymbol.free / 12).toFixed(2)
    const calcTotal = (newAmount, newPrice) => {
        return meaningfulAmountDecimals(getPriceOnGive(newPrice) * newAmount, secondSymbol.price)
    }
    const calcAmount = newTotal => meaningfulAmountDecimals((1 / getPrice()) * newTotal, pairPrice)
    const canTrade = () => {
        if (orderType === "market") {
            return amount > 0
        } else {
            return amount > 0 && price > 0
        }
    }

    const getModalClassName = () => {
        if (minimized) return "minimized-modal"
        if (orderType === "market") return "market-modal"
        if (orderType === "limit") return "limit-modal"
    }

    const setFullAmount = () => {
        if (side === "buy") updateTotal(secondSymbol.free)
        if (side === "sell") updateAmount(firstSymbol.free)
    }

    const setQuarter = () => {
        if (side === "buy") updateTotal(secondSymbol.free / 4)
        if (side === "sell") updateAmount(firstSymbol.free / 4)
    }

    const updateAmount = (amount, newPrice = getPrice()) => {
        const newAmount = meaningfulAmountDecimals(amount, firstSymbol.price)
        if (side === "buy") {
            const newTotal = newAmount * newPrice
            const parsedTotal = meaningfulAmountDecimals(newTotal, secondSymbol.price)
            setAmount(newAmount)
            setTotal(parsedTotal)
        }
        if (side === "sell") {
            const parsedTotal = meaningfulAmountDecimals(calcTotal(newAmount, newPrice), secondSymbol.price)
            setAmount(newAmount)
            setTotal(parsedTotal)
        }
    }

    const updateTotal = newTotal => {
        if (side === "buy" || side === "sell") {
            const parsedTotal = meaningfulAmountDecimals(newTotal, secondSymbol.price)
            setTotal(parsedTotal)
            setAmount(calcAmount(newTotal))
        }
    }

    const handleAmount = e => {
        const newAmount = Number(e.target.value)
        const newTotal = newAmount * getPrice()
        if (side === "buy" && newTotal <= secondSymbol.free) {
            updateAmount(newAmount)
        }
        if (side === "sell" && newAmount <= firstSymbol.free) {
            updateAmount(newAmount)
        }
        if (e.target.value === "") {
            setAmount("")
        } else if (newAmount === 0) {
            setAmount(0)
            setTotal(0)
        }
    }

    const handleTotal = e => {
        const newTotal = Number(e.target.value)
        if (side === "buy" || side === "sell") {
            updateTotal(newTotal)
        }
        if (e.target.value === "") {
            setTotal("")
        } else if (newTotal === 0) {
            setAmount(0)
            setTotal(0)
        }
    }

    const handleTrade = async () => {
        const pair = pairName.replace("-", "/")
        setOrderStatus(STATUS.LOADING)
        await postTrade(exchange, pair, amount, price, side, orderType)
            .then(() => {
                setOrderStatus(STATUS.OK)
                updateRedux()
            })
            .catch(() => setOrderStatus(STATUS.ERROR))
    }

    const updateRedux = () => {
        dispatch(getOrdersFromExchange(exchange))
        dispatch(getWallet(exchange))
    }

    const updatePrice = e => {
        const newPrice = Number(e.target.value)
        if (newPrice > 0) {
            setPrice(newPrice)
            updateAmount(amount, newPrice)
        }
        if (e.target.value === "") {
            setPrice("")
        } else if (newPrice === 0) {
            setPrice(0)
            setAmount(0)
            setTotal(0)
        }
    }

    useEffect(() => {
        if (pairStatus === STATUS.OK) {
            setPrice(pairPrice)
            setQuarter()
        }
    }, [pairStatus, side, pairPrice])

    useEffect(() => {
        const getMinvalue = async () => {
            if (exchange && pairName) {
                const data = await exchangeMinValue({
                    exchange,
                    symbol: pairName,
                })
                if (data) {
                    setMinAmount(trimDecimalZeros(data.min_amount))
                    setMinCost(trimDecimalZeros(data.min_cost))
                }
            }
        }
        getMinvalue()
    }, [exchange, pairName, setMinAmount, setMinCost])

    return (
        <ReactModal
            disableResize
            initWidth={290}
            initHeight={350}
            isOpen={true}
            onRequestClose={() => setChartOpened(false)}
            className={getModalClassName()}
        >
            {pairStatus === STATUS.LOADING && <Loader />}
            {pairStatus === STATUS.OK &&
                <SoftBox p={2}>
                    <SoftBox className="header" mb={3}>
                        <SoftButton fullWidth variant="gradient" color="warning" onClick={() => setChartOpened(false)} iconOnly circular className="header-back-button" m={0}>
                            <KeyboardReturnIcon />
                        </SoftButton>
                        <SoftTypography color="secondary" variant="p" fontSize="medium" fontWeight="bold">
                            {pairName}
                        </SoftTypography>
                        <SoftButton fullWidth variant="gradient" color="secondary" onClick={() => setMinimized(prevState => !prevState)} iconOnly circular className="header-minimize-button">
                            <Icon>{minimized ? <OpenInFull /> : <CloseFullscreenIcon />}</Icon>
                        </SoftButton>
                    </SoftBox>
                    {!minimized && <>
                        <SoftBox className="order-type-container" mb={1}>
                            <SoftBox id="buySellTabs" className="order-type-container">
                                <SoftButton
                                    fullWidth
                                    onClick={() => setSide("buy")}
                                    variant={side === "buy" ? "gradient" : "outlined"}
                                    color="success"
                                    size="small"
                                    className={"side-button"}
                                >
                                    {t('Buy')}
                                </SoftButton>
                                <SoftButton
                                    fullWidth
                                    onClick={() => setSide("sell")}
                                    variant={side === "sell" ? "gradient" : "outlined"}
                                    color="error"
                                    size="small"
                                    className={"side-button"}
                                >
                                    {t('Sell')}
                                </SoftButton>
                            </SoftBox>
                            <SoftBox id="orderType" className="order-type-container">
                                <SoftButton
                                    fullWidth
                                    onClick={() => setOrderType("market")}
                                    variant={orderType === "market" ? "gradient" : "outlined"}
                                    color="secondary"
                                    size="small"
                                    className={"order-type-button"}
                                >
                                    {t('Market')}
                                </SoftButton>
                                <SoftButton
                                    fullWidth
                                    onClick={() => setOrderType("limit")}
                                    variant={orderType === "limit" ? "gradient" : "outlined"}
                                    color="secondary"
                                    size="small"
                                    className={"order-type-button"}
                                >
                                    {t('Limit')}
                                </SoftButton>
                            </SoftBox>
                        </SoftBox>
                        <SoftBox my={1}>
                            <SoftBox id="amountTrade">
                                <SoftBox mt={1} mb={-0.5} onClick={setFullAmount} display="flex" justifyContent="space-between">
                                    <SoftTypography variant="p" color="secondary" fontSize="small" fontWeight="bold" textAlign="center">
                                        {t('Amount')} {firstSymbol.name}
                                    </SoftTypography>
                                    {side === "sell" &&
                                        <SoftTypography variant="p" color="secondary" fontSize="small" fontWeight="bold" textAlign="center" className="pointer">
                                            {t('Available')}: {`${firstSymbol.free}`}
                                        </SoftTypography>
                                    }
                                </SoftBox>
                                <input value={amount} onInput={handleAmount} className="amount-input" type="number" placeholder="Amount" size="small" />
                            </SoftBox>
                            <SoftBox p={0} display="flex" justifyContent="space-center">
                                <SliderAmount amount={amount} total={total} handleAmount={handleAmount} handleTotal={handleTotal} firstSymbol={firstSymbol} secondSymbol={secondSymbol} side={side} />
                            </SoftBox>
                            <SoftBox
                                id="totalTrade"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                gap={1}
                            >
                                <SoftBox mb={-0.5} onClick={setFullAmount} display="flex" justifyContent="space-between" width="100%">
                                    <SoftTypography variant="p" color="secondary" fontSize="small" fontWeight="bold" textAlign="center">
                                        {t('Total')} {secondSymbol.name}
                                    </SoftTypography>
                                    {side === "buy" &&
                                        <SoftTypography variant="p" color="secondary" fontSize="small" fontWeight="bold" textAlign="center" className="pointer">
                                            {t('Available')}: {`${secondSymbol.free}`}
                                        </SoftTypography>
                                    }
                                </SoftBox>
                                <input value={total} onInput={handleTotal} className="amount-input" type="number" placeholder="Total" size="small" step={step} />
                                {orderType === "market" &&
                                    <SoftBox display="flex" flexDirection="column" alignItems="flex-start" width="100%">
                                        <SoftTypography variant="p" fontWeight="bold" fontSize="10px" textAlign="center" sx={{ color: '#56667d' }}>
                                            {`Minimum Amount: ${minAmount}`}
                                        </SoftTypography>
                                        <SoftTypography variant="p" fontWeight="bold" fontSize="10px" textAlign="center" sx={{ color: '#56667d' }}>
                                            {`Minimum Cost: ${minCost}`}
                                        </SoftTypography>
                                    </SoftBox>
                                }
                            </SoftBox>
                            {orderType === "limit" &&
                                <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    gap={1}
                                >
                                    <SoftBox mb={-0.5} width="100%">
                                        <SoftTypography variant="p" color="secondary" fontSize="small" fontWeight="bold" textAlign="center">
                                            {t('Price')}
                                        </SoftTypography>
                                    </SoftBox>
                                    <input value={price} onInput={updatePrice} className="amount-price" type="number" placeholder={"Price"} />
                                    <SoftBox display="flex" flexDirection="column" alignItems="flex-start" width="100%">
                                        <SoftTypography variant="p" fontWeight="bold" fontSize="10px" textAlign="center" sx={{ color: '#56667d' }}>
                                            {`Minimum Amount: ${minAmount}`}
                                        </SoftTypography>
                                        <SoftTypography variant="p" fontWeight="bold" fontSize="10px" textAlign="center" sx={{ color: '#56667d' }}>
                                            {`Minimum Cost: ${minCost}`}
                                        </SoftTypography>
                                    </SoftBox>
                                </SoftBox>
                            }
                        </SoftBox>
                        <SoftBox id="buttonTrade" mt={3} px={3}>
                            {orderStatus === STATUS.PENDING && <SoftButton disabled={!canTrade()} onClick={handleTrade} fullWidth variant="gradient" color="info">
                                {t(`${side} ${orderType}`)}
                            </SoftButton>}
                            <StatusAnimations status={orderStatus} setStatusFunction={setOrderStatus} />
                        </SoftBox>
                    </>}
                </SoftBox>
            }
        </ReactModal>
    )
}
