/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DataTable from "examples/Tables/DataTable";

// React admin components
import AdminButton from "../AdminButton";
import RefreshButton from "layouts/Common/Orders/components/RefreshButton";
import { STATUS } from "constants/fetchStatus";
import Loader from "components/Loader";

// Translate
import { useTranslation } from "react-i18next";

function AdminDataTable({
  label,
  dataTableData,
  handleNew,
  button = false,
  dataState,
  handleRefresh,
}) {
  const { t } = useTranslation();
  return (
    <SoftBox>
      {dataState === STATUS.LOADING && <Loader />}
      {dataState === STATUS.ERROR && (
        <Stack spacing={1} direction="row" alignItems="center">
          <SoftTypography variant="h5" fontWeight="medium">
            {t("Oops, something went wrong!")}
          </SoftTypography>
        </Stack>
      )}
      {dataState === STATUS.OK && (
        <SoftBox my={3}>
          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              p={3}
            >
              <SoftBox id="refreshButton" lineHeight={1}>
                <RefreshButton
                  isLoading={dataState === STATUS.LOADING}
                  action={handleRefresh}
                />
              </SoftBox>
              {button && (
                <Stack spacing={1} direction="row">
                  <AdminButton onClick={handleNew}>
                    {t(`+ new ${label}`)}
                  </AdminButton>
                </Stack>
              )}
            </SoftBox>
            <DataTable
              table={dataTableData}
              entriesPerPage={{
                defaultValue: 10,
                entries: [5, 10, 15, 50, 100],
              }}
              canSearch
              showTotalEntries
              isSorted
              defaultSortBy="id"
            />
          </Card>
        </SoftBox>
      )}
    </SoftBox>
  );
}

export default AdminDataTable;
