// Redux
import { useDispatch } from "react-redux";
import { deleteStrategy } from "redux/actions/arbitration";

// @mui material components
import Icon from "@mui/material/Icon"


export default function DeleteButton({ id }) {

    const dispatch = useDispatch();
    const handleDelete = e => {
        e.preventDefault();
        dispatch(deleteStrategy(id));
    };

    return (
        <Icon
            className="icon"
            fontSize='30px'
            onClick={handleDelete}
            sx={{ cursor: 'pointer' }}
        >
            delete
        </Icon>
    );
}
