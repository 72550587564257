// KEYS
export const SET_KEYS = "SET_KEYS";
export const SET_KEYS_COUNT = "SET_KEYS_COUNT";
export const SET_KEYS_STATUS = "SET_KEYS_STATUS";
export const UPDATE_KEY_ACTIVE = "UPDATE_KEY_ACTIVE";
export const SET_EXCHANGE_KEYS_STATUS = "SET_EXCHANGE_KEYS_STATUS";
export const ADD_OR_UPDATE_EXCHANGE_KEYS = "ADD_OR_UPDATE_EXCHANGE_KEYS";

// ORDERS
export const ADD_ORDERS = "ADD_ORDERS";
export const SET_ORDERS = "SET_ORDERS";
export const LOADING_ORDERS = "LOADING_ORDERS";
export const UPDATE_ORDERS_BY_EXCHANGE = "UPDATE_ORDERS_BY_EXCHANGE";

// WALLET
export const CLEAR_WALLETS = "CLEAR_WALLETS";
export const SET_WALLET_DATA = "SET_WALLET_DATA";
export const WALLET_STATUS = "WALLET_STATUS";
export const REMOVE_WALLET = "REMOVE_WALLET";

// USERS
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_LANGUAGE_USER = "SET_CURRENT_LANGUAGE_USER";
export const SET_REQUIRE_TWO_FA_USER = "SET_REQUIRE_TWO_FA_USER";
export const SET_REQUIRE_TOURS_USER = "SET_REQUIRE_TOURS_USER";
export const SET_SUBSCRIPTION_STATUS_USER = "SET_SUBSCRIPTION_STATUS_USER";
export const SET_CURRENT_USER_STATUS = "SET_CURRENT_USER_STATUS";
export const SET_USERS_STATUS = "SET_USERS_STATUS";
export const FETCH_USERS = "FETCH_USERS";
export const LOGOUT = "LOGOUT";

// ARBITRATION
export const SET_ARBITRATION_ACTIONS = "SET_ARBITRATION_ACTIONS";
export const SET_ARBITRATION_ACTIONS_STATUS = "SET_ARBITRATION_ACTIONS_STATUS";
export const SET_ARBITRATION_OPPORTUNITIES = "SET_ARBITRATION_OPPORTUNITIES";
export const SET_ARBITRATION_OPPORTUNITIES_STATUS =
  "SET_ARBITRATION_OPPORTUNITIES_STATUS";
export const SET_ARBITRATION_STRATEGIES = "SET_ARBITRATION_STRATEGIES";
export const SET_ARBITRATION_STRATEGIES_STATUS =
  "SET_ARBITRATION_STRATEGIES_STATUS";
export const SET_ARBITRATION_EXCHANGES = "SET_ARBITRATION_EXCHANGES";
export const SET_ARBITRATION_EXCHANGES_STATUS =
  "SET_ARBITRATION_EXCHANGES_STATUS";
export const SET_ARBITRATION_PAIRS = "SET_ARBITRATION_PAIRS";
export const SET_ARBITRATION_PAIRS_STATUS = "SET_ARBITRATION_PAIRS_STATUS";
export const ADD_ARBITRATION_STRATEGY = "ADD_ARBITRATION_STRATEGY";
export const REMOVE_ARBITRATION_STRATEGY = "REMOVE_ARBITRATION_STRATEGY";
export const UPDATE_ARBITRATION_STRATEGY = "UPDATE_ARBITRATION_STRATEGY";

// MESSAGES
export const SET_INFORMATIVE_MESSAGE = "SET_INFORMATIVE_MESSAGE";
export const INFORMATIVE_MESSAGE_STATUS = "INFORMATIVE_MESSAGE_STATUS";
export const CLEAR_INFORMATIVE_MESSAGE = "CLEAR_INFORMATIVE_MESSAGE";

// EXCHANGES
export const SET_EXCHANGES = "SET_EXCHANGES";
export const EXCHANGES_STATUS = "EXCHANGES_STATUS";

// FAV_PAIRS
export const SET_FAV_PAIRS = "SET_FAV_PAIRS";
export const FAV_PAIRS_STATUS = "FAV_PAIRS_STATUS";

// TOAST
export const SET_TOAST = "SET_TOAST";

// NOTIFICATIONS
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";

// NEWS
export const SET_NEWS = "SET_NEWS";
export const NEWS_STATUS = "NEWS_STATUS";

// VIDEOS
export const SET_VIDEOS = "SET_VIDEOS";
export const VIDEOS_STATUS = "VIDEOS_STATUS";

// ADMIN MESSAGES
export const SET_MESSAGES = "SET_MESSAGES";
export const MESSAGES_STATUS = "MESSAGES_STATUS";

// ADMIN MESSAGES
export const SET_PRICING = "SET_PRICING";
export const PRICING_STATUS = "PRICING_STATUS";

// BOTS
export const SET_BOTS = "SET_BOTS";
export const BOTS_STATUS = "BOTS_STATUS";
