// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox';

// P2P components
import SkipShift from "./components/SkipShift";

export default function P2P() {
  return (
    <DashboardLayout>
      <SoftBox component={Grid} containerp={{ xs: 1, lg: 4 }} spacing={3} minHeight="80vh">
        <SoftBox component={Grid} item xs={12} p={{ xs: 1, lg: 4 }}>
          <SkipShift />
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}
