/***** We receive an action and set de data to the store *****/

import { SET_NEWS, NEWS_STATUS } from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";

export default (news = { data: [], status: STATUS.PENDING }, action) => {
    switch (action.type) {
        case SET_NEWS:
            return { ...news, data: action.payload };
        case NEWS_STATUS:
            return { ...news, status: action.payload };
        default:
            return news;
    };
};