// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-redux
import { useSelector, useDispatch } from 'react-redux';
import { changeLanguage } from 'redux/actions/currentUser';

// @mui material components
import Menu from "@mui/material/Menu";
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import CheckIcon from '@mui/icons-material/Check';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Flags
import { Languages } from "../data/Languages";

function LanguageMenu({ anchorEl, open, handleClose }) {

    const dispatch = useDispatch();
    const { language, id } = useSelector(state => state.currentUser.data);

    const handleClick = (value) => {
        dispatch(changeLanguage(id, value));
        handleClose();
    }

    return (
        <Menu
            anchorEl={anchorEl}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={open}
            onClose={handleClose}
            sx={{ mt: 3 }}
        >
            {Languages.map(({ key, label, icon, value }) => (
                <MenuItem key={key} onClick={() => handleClick(value)}>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
                        <SoftBox display="flex" justifyContent="flex-start" alignItems="center" gap={1}>
                            <SvgIcon>
                                {icon}
                            </SvgIcon>
                            <SoftTypography variant="caption" fontWeight={language === value ? 'bold' : false}>{label}</SoftTypography>
                        </SoftBox>
                        {language === value && <CheckIcon />}
                    </SoftBox>
                </MenuItem>
            ))}
        </Menu >
    )
}

// Setting default values for the props of LanguageMenu
LanguageMenu.defaultProps = {
};

// Typechecking props for the LanguageMenu
LanguageMenu.propTypes = {
    anchorEl: PropTypes.any,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default LanguageMenu;