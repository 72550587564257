import SoftButton from "components/SoftButton";
// Translate
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

export default function TabSelector({ tabValue, setTabValue }) {

    const { t } = useTranslation();
    const strategies = useSelector(state => state.arbitration.strategies.data)
    const actions = useSelector(state => state.arbitration.actions.data)
    const handleTabChange = e => setTabValue(e.target.value)

    return (
        <>
            <SoftButton
                id="opportunitiesTab"
                key="opportunities"
                className={tabValue === "opportunities" ? "selected" : "notSelected"}
                variant="text"
                color="dark"
                value="opportunities"
                onClick={handleTabChange}>
                {t('Opportunities')}
            </SoftButton>
            <SoftButton
                id="strategiesTab"
                key="strategies"
                className={tabValue === "strategies" ? "selected" : "notSelected"}
                variant="text"
                color="dark"
                value="strategies"
                onClick={handleTabChange}>
                {`${t('Strategies')} (${strategies.length})`}
            </SoftButton>
            <SoftButton
                id="actionsTab"
                key="actions"
                className={tabValue === "actions" ? "selected" : "notSelected"}
                variant="text"
                color="dark"
                value="actions"
                onClick={handleTabChange}>
                {`${t('Actions')} (${actions.length})`}
            </SoftButton>
        </>
    )
}