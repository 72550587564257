import SoftButton from "components/SoftButton";

// Translate
import { useTranslation } from "react-i18next";

export default function MarketSelector({
  selectedMarket,
  setSelectedMarket,
  markets,
}) {
  const { t } = useTranslation();

  const handleClick = ({ target }) => {
    setSelectedMarket(target.value);
  };
  return (
    <>
      {markets.map((market) => {
        return (
          <SoftButton
            id={market}
            key={market}
            className={selectedMarket === market ? "selected" : "notSelected"}
            variant="text"
            color="dark"
            value={market}
            onClick={handleClick}
          >
            {t(market)}
          </SoftButton>
        );
      })}
    </>
  );
}
