import SoftTypography from "components/SoftTypography";

export default function Side({ side }) {
    return (side === "buy" ? (
        <SoftTypography color={"success"} className="table-percentage">
            Buy
        </SoftTypography>) : (
        <SoftTypography color={"error"} className="table-percentage">
            Sell
        </SoftTypography>)
    )
}