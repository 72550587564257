// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Translate
import { useTranslation } from 'react-i18next';

function ExchangeSwitch({ activeLoading, active, submitActive }) {
    const { t } = useTranslation();
    return (
        <SoftBox display="flex" justifyContent="flex-start" alignItems="center" gap={1} >
            <SoftBox>
                <Switch disabled={activeLoading} checked={active} onChange={submitActive} />
            </SoftBox>
            <SoftTypography fontWeight="bold" fontSize={{ xs: 0, md: 14 }} variant="section">
                {active ? t("Enabled") : t("Disabled")}
            </SoftTypography>
        </SoftBox>
    );
};

export default ExchangeSwitch;