import typography from "assets/theme/base/typography";
import theme from "assets/theme";

const { palette, functions } = theme;
const { gradients } = palette;
const { linearGradient } = functions;

export const getSyles = () => {
    const textStyles = {
        color: "dark",
        fontWeight: "800",
        fontSize: "0.76rem",
        fontFamily: typography.fontFamily,
        textTransform: "uppercase",
        background: "transparent",
        borderRadius: "6px",
        opacity: 1,
        px: 4,
        py: "0.7rem",
        bgColor: 'transparent',
        width: "5rem"
    };

    const gradientStyles = {
        color: "white",
        fontWeight: "800",
        fontSize: "0.76rem",
        fontFamily: typography.fontFamily,
        textTransform: "uppercase",
        background: linearGradient(gradients["info"].main, gradients["info"].state),
        borderRadius: "6px",
        shadow: "lg",
        opacity: 1,
        px: 4,
        py: "0.7rem",
        width: "5rem"
    };

    return {
        buttonSkip: {
            ...textStyles
        },
        buttonBack: {
            ...textStyles,
        },
        buttonNext: {
            ...gradientStyles,
        },
        buttonLast: {
            ...gradientStyles,
        },
    };
}