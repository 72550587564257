import { fetchBots } from "api/bots";
import { SET_BOTS } from "constants/actionTypes";
import { BOTS_STATUS } from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";

/***** Redux actions that end up in the store *****/
export const getBots = () => async (dispatch) => {
  try {
    dispatch({ type: BOTS_STATUS, payload: STATUS.LOADING });
    const data = await fetchBots();
    dispatch({ type: SET_BOTS, payload: data });
    dispatch({ type: BOTS_STATUS, payload: STATUS.OK });
  } catch (error) {
    dispatch({ type: BOTS_STATUS, payload: STATUS.ERROR });
  }
};
