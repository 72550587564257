// Soft UI Dashboard React components
import SoftBox from 'components/SoftBox';
import SoftTypography from "components/SoftTypography";

export default function SkipShift() {
    return (
        <SoftBox
            display="flex"
            flexDirection={{ xs: "column", lg: "row" }}
            justifyContent="center"
            alignItems="center"
            gap={{ xs: 4, lg: 0 }}
        >
            <SoftBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="end"
                width={{ xs: "100%", lg: "50%" }}
            >
                <SoftTypography
                    variant='h3'
                    fontSize={{ xs: 40, lg: 70 }}
                    sx={{
                        fontWeight: 600,
                        textAlign: 'right'
                    }}
                >
                    Skip the questions.
                    <br />
                    Shift control.
                </SoftTypography>
                <SoftTypography
                    variant="overline"
                    fontSize={{ xs: 10, lg: 15 }}
                    sx={{
                        lineHeight: '29px',
                    }}
                >
                    As often, as much, and from anywhere: SkipShift allows anyone to swap fiat to crypto and back using 100% decentralized infrastructure.
                    <br />
                    That means no more KYC, no more selfies, and no more limits.
                </SoftTypography>
            </SoftBox>
            <SoftBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width={{ xs: "100%", lg: "50%" }}
            >
                <iframe
                    id="_RBX_SKIPSHIFT_WIDGET"
                    className="skippyWidget"
                    style={{
                        border: '0px',
                        width: '100%',
                        height: '100%',
                        minHeight: '635px',
                        maxWidth: '440px',
                    }}
                    src="https://skipshift.io/?skipwidget&ref_wallet=0x348B292A710F4cf52DeddB2Cd2813D6F978cb5Ec"
                    title="SkipShift Widget"
                />
            </SoftBox>
        </SoftBox>
    )
}
