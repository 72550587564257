import Binance from 'assets/logos/Binance.png';
import HitBtc from 'assets/logos/HitBtc.png';
import AscendEx from 'assets/logos/AscendEx.png';
import KuCoin from 'assets/logos/KuCoin.png';
import OKEx from 'assets/logos/OKEx.png';
import ProBit from 'assets/logos/ProBit.png';
import ByBit from 'assets/logos/ByBit.png';
import Kraken from 'assets/logos/Kraken.png';

function getIcon(exchange) {
    let icon = null;

    switch (exchange) {
        case "binance":
            icon = Binance;
            break;
        case "hitbtc":
            icon = HitBtc;
            break;
        case "ascendex":
            icon = AscendEx;
            break;
        case "okx":
            icon = OKEx;
            break;
        case "probit":
            icon = ProBit;
            break;
        case "bybit":
            icon = ByBit;
            break;
        case "kucoin":
            icon = KuCoin;
            break;
        case "kraken":
            icon = Kraken;
            break;
        default:
            break;
    }
    return icon
};

export default getIcon;