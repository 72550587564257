// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import ProfileEditCard from 'examples/Cards/InfoCards/ProfileEditCard';
import SidenavProfile from "examples/Sidenav/SidenavProfile";
import ProfileChangePass from "examples/Cards/InfoCards/ProfileChangePass";

function ProfileEditTab({ tabValue, setTabValue, infoUser, newPass, loading, handle }) {
    const getCurrentTab = () => {
        switch (tabValue) {
            case 0:
                return (
                    <ProfileEditCard
                        title="basic information"
                        info={infoUser}
                        loading={loading}
                        action={{ ...handle }}
                    />
                );

            case 1:
                return <ProfileChangePass info={newPass} loading={loading} action={{ ...handle }} />;

            default:
                return (
                    <ProfileEditCard
                        title="basic information"
                        info={infoUser}
                        loading={loading}
                        action={{ ...handle }}
                    />
                );
        }
    };

    return (
        <SoftBox mt={4} mx={{ xs: 0, lg: 4 }} >
            <Grid container spacing={3}>
                <Grid item xs={12} lg={3}>
                    <SidenavProfile setTabValue={setTabValue} />
                </Grid>
                <Grid item xs={12} lg={9}>
                    <SoftBox mb={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {getCurrentTab()}
                            </Grid>
                        </Grid>
                    </SoftBox>
                </Grid>
            </Grid>
        </SoftBox>
    )
};

// Typechecking props for the ProfileEditTab
ProfileEditTab.propTypes = {
    infoUser: PropTypes.objectOf(PropTypes.string).isRequired,
    handle: PropTypes.shape({
        handleChange: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        handleCancel: PropTypes.func.isRequired,
    }).isRequired,
};

export default ProfileEditTab;