import { useState, useEffect } from "react";
// @mui material components
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// Soft UI Dashboard React components
import SoftTypography from "components/SoftTypography";
// Soft UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";
// Translate
import { useTranslation } from 'react-i18next';

export default function TransparentTabs({ id, selectedOption, options, updateValue }) {

    const initialTabValue = options.map(option => option.value).indexOf(selectedOption.value)
    const [tabValue, setTabValue] = useState(initialTabValue);
    const { t } = useTranslation();
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");

    useEffect(() => {
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }
        window.addEventListener("resize", handleTabsOrientation);
        handleTabsOrientation();
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    const handleSetTabValue = (e, newValue) => setTabValue(newValue);

    return (
        <AppBar id={id} position="static">
            <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ background: "transparent", maxWidth: `${(options.length * 125)}px`, minWidth: "250px" }}
                TabIndicatorProps={{
                    sx: { backgroundColor: "lightgray" }
                }}
            >
                {options.map(option => (
                    <Tab
                        key={option.value}
                        name={option.value}
                        sx={{ background: selectedOption.value === option.value && "black" }}
                        label={
                            <SoftTypography variant="button" color={selectedOption.value === option.value ? "white" : "secondary"} fontWeight="medium" textTransform="capitalize">
                                {t(option.label)}
                            </SoftTypography>
                        }
                        onClick={() => updateValue(option)}
                    />
                ))}
            </Tabs>
        </AppBar>
    )
}
