import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';

export default function InfoModal({ button, requiredBalances }) {
  const { t } = useTranslation();
  const { exchange1Symbol1, exchange2Symbol1, exchange1Symbol2, exchange2Symbol2 } = requiredBalances
  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });
    newSwal.fire({
      title: t("Required balances"),
      icon: "info",
      html: `
        <div style="text-align: left; margin: auto; max-width: 350px">
          ${exchange1Symbol1}</br>
          ${exchange2Symbol1}</br>
          ${exchange1Symbol2}</br>
          ${exchange2Symbol2}
        </div>
      `,
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: `<span class="material-icons-round">thumb_up</span> ${t("Ok!")}`
    });
  };

  return <div id="requiredBalance" onClick={showAlert}>
    {button}
  </div>;
}

