/***** We receive an action and set de data to the store *****/
import { SET_INFORMATIVE_MESSAGE, CLEAR_INFORMATIVE_MESSAGE, INFORMATIVE_MESSAGE_STATUS } from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";

export default (informativeMessage = { message: "", status: STATUS.PENDING }, action) => {
    switch (action.type) {
        case INFORMATIVE_MESSAGE_STATUS:
            return { ...informativeMessage, status: action.payload };
        case SET_INFORMATIVE_MESSAGE:
            return { ...informativeMessage, message: action.payload };
        case CLEAR_INFORMATIVE_MESSAGE:
            return { message: "", status: STATUS.PENDING };
        default:
            return informativeMessage;
    };
};