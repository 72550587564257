import axios from "axios"
import { BACK_API } from "apiconfig";
import { store } from "index";

export const fetchNotifications = async () => {
    const headers = {
        'token': store.getState().currentUser.data.jwtToken,
        'id': store.getState().currentUser.data.id
    };
    const url = `${BACK_API}/notifications`;
    const params = {};

    return await axios
        .get(url, { params, headers })
        .then(res => res.data)
        .catch(err => { throw err })
}

export const updateNotificationsAsRead = async () => {
    const headers = {
        'token': store.getState().currentUser.data.jwtToken,
        'id': store.getState().currentUser.data.id
    };
    const url = `${BACK_API}/notifications-as-read`;
    const body = {}
    return await axios
        .post(url, body, { headers })
        .then(res => res.data)
        .catch(err => { throw err })
}