import { useState } from "react";

// react-redux
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentUser } from "redux/actions/currentUser";
import { updateUserInfo } from "api/users";
import { updateUserPassword } from "api/users";
import { setInformativeMessage } from "redux/actions/informativeMessage";

// Common components
import Animation from "layouts/Common/Status/Animation";
import ProfileEditTab from "./edit";
import ProfileInfoTab from "./info";

export default function ProfileTab() {
    const dispatch = useDispatch();
    const { id, username, fullName, email, country, phone } = useSelector(state => state.currentUser.data);
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    const [status, setStatus] = useState({
        isCompleted: false,
        success: false,
        error: false,
        message: "",
    });

    const [infoUser, setInfoUser] = useState(
        {
            username,
            fullName,
            email,
            country,
            phone,
        }
    );

    const [newPassword, setNewPassword] = useState(
        {
            id,
            current: "",
            newPass: "",
            confirm: "",
            showPassword: false,
        }
    );

    const handleEdit = () => setIsEdit(prev => !prev);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setInfoUser(prev => ({ ...prev, [name]: value }));
    };

    const handleChangePassword = ({ target }) => setNewPassword({ ...newPassword, [target.name]: target.value });

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const response = await updateUserInfo(infoUser);
        const { isCompleted, success, error, data } = response;
        if (success) {
            dispatch(setCurrentUser(data));
            handleEdit();
        } else {
            setTabValue(0);
        };
        setStatus(prev => ({ ...prev, isCompleted, success, error }));
        setLoading(false);
    };

    const handleSubmitChangePassword = async (event) => {
        setLoading(true);
        event.preventDefault();
        const response = await updateUserPassword(newPassword);
        const { isCompleted, success, error, message } = response;
        dispatch(setInformativeMessage({ success, message }));
        if (success) {
            handleEdit();
        } else {
            setTabValue(1);
        };
        setStatus(prev => ({ ...prev, isCompleted, success, error }));
        setNewPassword({
            id,
            current: "",
            newPass: "",
            confirm: "",
            showPassword: false,
        });
        setLoading(false);
    };

    return (
        <>
            {status.isCompleted ?
                <Animation
                    status={status}
                    setStatus={setStatus}
                /> :
                (
                    <>
                        {
                            isEdit ?
                                <ProfileEditTab
                                    tabValue={tabValue}
                                    setTabValue={setTabValue}
                                    infoUser={infoUser}
                                    newPass={newPassword}
                                    loading={loading}
                                    handle={{ handleCancel: handleEdit, handleChange, handleSubmit, handleChangePassword, handleSubmitChangePassword }}
                                /> :
                                <ProfileInfoTab infoUser={infoUser} handle={handleEdit} />
                        }
                    </>
                )
            }
        </>
    );
};