/***** We receive an action and set de data to the store *****/

import { SET_KEYS, SET_KEYS_COUNT, SET_KEYS_STATUS, UPDATE_KEY_ACTIVE, SET_EXCHANGE_KEYS_STATUS, ADD_OR_UPDATE_EXCHANGE_KEYS } from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";

export default (keys = { data: [], count: 0, status: STATUS.PENDING }, action) => {
    switch (action.type) {
        case SET_KEYS:
            const keysWithStatus = action.payload.map(exchange => {
                return { ...exchange, status: STATUS.OK }
            })
            return { ...keys, data: keysWithStatus }
        case SET_KEYS_COUNT:
            return { ...keys, count: action.payload }
        case SET_KEYS_STATUS:
            return { ...keys, status: action.payload }
        case UPDATE_KEY_ACTIVE: {
            const updatedExchanges = keys.data.map(exchange => {
                const isSelectedExchange = exchange.exchange === action.payload.exchange
                if (isSelectedExchange) {
                    return {
                        ...exchange,
                        isActive: action.payload.active
                    }
                } else return exchange
            })
            return { ...keys, data: updatedExchanges }
        }
        case ADD_OR_UPDATE_EXCHANGE_KEYS:
            const wereKeysAdded = keys.data.some(exchange => exchange.exchange === action.payload.exchange)
            if (wereKeysAdded) {
                const updatedExchanges = keys.data.map(exchange => {
                    const isSelectedExchange = exchange.exchange === action.payload.exchange
                    if (isSelectedExchange) {
                        return { ...action.payload, status: STATUS.OK }
                    } else return exchange
                })
                return { ...keys, data: updatedExchanges }
            } else {
                return { ...keys, data: [...keys.data, action.payload] }
            }
        case SET_EXCHANGE_KEYS_STATUS: {
            const wereKeysAdded = keys.data.some(exchange => exchange.exchange === action.payload.exchange)
            if (wereKeysAdded) {
                const updatedExchanges = keys.data.map(exchange => {
                    const isSelectedExchange = exchange.exchange === action.payload.exchange
                    if (isSelectedExchange) {
                        return { ...exchange, status: action.payload.status }
                    } else return exchange
                })
                return { ...keys, data: updatedExchanges }
            } else {
                return { ...keys, data: [...keys.data, action.payload] }
            }
        }
        default:
            return keys;
    };
};