import getIcon from "utils/getIcons";
import "./styles.scss";

export default function Exchange({ exchange }) {
  const icon = getIcon(exchange.toLowerCase());

  return (
    <div className="cp-container">
      <img className="icon" src={icon} alt={exchange} />
      <div className="name">{exchange}</div>
    </div>
  );
}
