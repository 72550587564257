// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Card } from "@mui/material";
import { STATUS } from "constants/fetchStatus";
import CryptoIcon from "componentsCustom/CryptoIcon";
import Tooltip from "@mui/material/Tooltip";
import SoftBadge from "components/SoftBadge";
import { useSelector } from "react-redux"
import { useState, useEffect } from "react";

// Translate
import { useTranslation } from 'react-i18next';

export default function PairsBox({ selectedWallet, walletStatus }) {
    const { t } = useTranslation();
    const wallets = useSelector(state => state.wallets)
    const [currentWallet, setCurrentWallet] = useState(wallets[selectedWallet])
    useEffect(() => {
        setCurrentWallet(wallets[selectedWallet])
    }, [selectedWallet])

    return (
        <Grid container spacing={3}>
            {currentWallet.pairs.map(({ free, locked, baseCoinValue, symbol, changePct24Hour, imageUrl, walletType }, index) => (
                <Grid item xs={6} md={4} lg={3} >
                    <Card key={symbol + index} style={{ opacity: walletStatus === STATUS.LOADING ? 0.3 : 1 }} sx={{ height: "100%" }}>
                        <SoftBox mx={2} my={1.5}>
                            <SoftBox style={{ width: "100%" }} display="inline-flex" justifyContent="space-between">
                                <CryptoIcon pair={symbol} imageUrl={imageUrl} />
                                <Tooltip title="24hs percentage diff" placement="top" sx={{ cursor: "pointer" }}>
                                    <SoftTypography variant="p" fontSize="medium" fontWeight="bold" mt={0.8} color={changePct24Hour > 0 ? "success" : "error"}>
                                        {changePct24Hour} %
                                    </SoftTypography>
                                </Tooltip>
                            </SoftBox>
                            <SoftTypography className="amount-card">
                                {t('Free')}:
                                <span className="amount-crypto">
                                    {` ${free}`}
                                </span>
                                <br></br>
                                {t('Locked')}:
                                <span className="amount-crypto">
                                    &nbsp;{locked || 0}
                                </span>
                            </SoftTypography>
                            <SoftBox style={{ width: "100%" }} display="flex" itemContent="center" justifyContent="space-between">
                                <SoftTypography className="amount-card">
                                    $: <span className="amount-fiat">{baseCoinValue}</span>
                                </SoftTypography>
                                {walletType && walletType !== "all" && (
                                    <SoftBox>
                                        <SoftBadge badgeContent={walletType === "swap" ? t("futures") : "spot"} size="xs" color={walletType === "spot" ? "info" : "success"} />
                                    </SoftBox>
                                )}
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}
