import { ROUTES, USER_TYPES } from "constants"
import clientConfig from "./clientConfig"

// If url is /, RootUrl component will redirect to the third allowed url
const USER_PERMISSIONS = {
    [USER_TYPES.CLIENT]: [
        ROUTES.ROOT,
        ROUTES.TYC,
        ROUTES.PPC,
        ROUTES.AUTH,
        // ROUTES.LOCK,
        ROUTES.HOME,
        ROUTES.TRADE,
        ROUTES.BOTS,
        ROUTES.ORDERS,
        ROUTES.WALLET,
        ROUTES.P2P,
        ROUTES.ARBITRATION,
        ROUTES.MARKETS,
        ROUTES.NEWS,
        ROUTES.PROFILE,
        ROUTES.NOTIFICATIONS
    ],
    [USER_TYPES.ADMIN]: [
        ROUTES.ROOT,
        ROUTES.TYC,
        ROUTES.PPC,
        ROUTES.AUTH,
        // ROUTES.LOCK,
        ROUTES.HOME,
        ROUTES.TRADE,
        ROUTES.BOTS,
        ROUTES.ORDERS,
        ROUTES.WALLET,
        ROUTES.P2P,
        ROUTES.ARBITRATION,
        ROUTES.MARKETS,
        ROUTES.NEWS,
        ROUTES.PROFILE,
        ROUTES.ADMIN,
        ROUTES.NOTIFICATIONS
    ]
}

const getAllowedRoutes = userType => {
    return USER_PERMISSIONS[userType || USER_TYPES.CLIENT]
}

export default function filterRoutes(routes, userType) {
    let filteredRoutes = routes
    filteredRoutes = getAllowedRoutes(userType)
    filteredRoutes = routes.filter(route => filteredRoutes.some(allowedRoute => allowedRoute === route.route))
    if (!clientConfig.useTrading) filteredRoutes = filteredRoutes.filter(route => route.key !== "trade")
    if (!clientConfig.useArbitrage) filteredRoutes = filteredRoutes.filter(route => route.key !== "arbitration")
    return filteredRoutes
}