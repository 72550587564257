import TourContent from "../components/TourContent";
import TourVideo from "../components/TourVideo";
import { getSyles } from "./styles";

const videoBasicStyle = (children) => {
    return {
        content: (
            <TourVideo>
                {children}
            </TourVideo>
        ),
        locale: {
            skip: 'Skip',
            back: 'Back',
            next: 'Next',
            last: 'Close',
        },
        styles: {
            ...getSyles(),
            options: {
                width: "100",
            },
        },
    };
};

const basicStyles = (children) => {
    return {
        content: <TourContent>{children}</TourContent>,
        locale: {
            skip: 'Skip',
            back: 'Back',
            next: 'Next',
            last: 'Close',
        },
        styles: getSyles()
    };
};


export const steps = {
    home: [
        {
            target: "#home",
            disableBeacon: true,
            placement: "center",
            ...basicStyles(
                "We're glad to have you here. Let's take a quick tour to get you started."
            ),
        },
        {
            target: "#operations",
            disableBeacon: true,
            ...basicStyles(
                "Manage your trades efficiently with our easy-to-use interface. View information about your trades and arbitrage actions. This section provides details on your trades."
            ),
        },
        {
            target: "#wallet",
            ...basicStyles(
                "Keep track of your funds in the wallet section. View your balance for each currency in the wallet section. This section provides a summary of your balances."
            ),
        },
        {
            target: "#adminMessages",
            ...basicStyles(
                "Stay updated with important notifications and messages from the admin. Check this section regularly to ensure you don't miss any critical information."
            ),
        },
        {
            target: "#news",
            ...basicStyles(
                "Get the latest updates and news about the market. Stay informed with real-time news that can impact your trading decisions."
            ),
        },
        {
            target: "#arbitrage",
            ...basicStyles(
                "Explore arbitrage opportunities to maximize your profits. This section provides tools and insights to help you take advantage of price differences across markets."
            ),
        },
        {
            target: "#videos",
            ...basicStyles(
                "Watch informative and educational videos. Learn more about trading strategies, market analysis, and how to use our platform effectively."
            ),
        },
    ],
    trade: [
        {
            target: "#traderSection",
            placement: "center",
            ...basicStyles(
                "This is the Trader section where you can manage your trades, monitor market trends, and execute trading strategies."
            ),
        },
        {
            target: "#exchangesTabsTrade",
            disableBeacon: true,
            ...basicStyles("Select the exchange you want to trade on."),
        },
        {
            target: "#filterTabsTrade",
            disableBeacon: true,
            ...basicStyles(
                "Toggle between viewing all pairs or only your favorite pairs."
            ),
        },
        {
            target: "#tradeButton",
            disableBeacon: true,
            ...basicStyles(
                "Click the Trade button to execute a trade for the selected pair."
            ),
        },
        {
            target: "#favoriteButton",
            disableBeacon: true,
            ...basicStyles(
                "Click the star icon to add or remove a pair from your favorites."
            ),
        },
    ],
    tradeModal: [
        {
            target: "#tradeModal",
            disableBeacon: true,
            placement: "center",
            ...basicStyles(
                "This is the trading section where you can execute your buy and sell orders."
            ),
        },
        {
            target: "#buySellTabs",
            disableBeacon: true,
            ...basicStyles(
                "Select whether you want to Buy or Sell the chosen pair."
            ),
        },
        {
            target: "#orderType",
            disableBeacon: true,
            ...basicStyles(
                "Choose the type of order you want to place: Market or Limit."
            ),
        },
        {
            target: "#amountTrade",
            disableBeacon: true,
            ...basicStyles(
                "Enter the amount of the asset you want to trade."
            ),
        },
        {
            target: "#totalTrade",
            disableBeacon: true,
            ...basicStyles(
                "The total amount will be calculated based on the amount you entered and the current market price."
            ),
        },
        {
            target: "#buttonTrade",
            disableBeacon: true,
            ...basicStyles(
                "Click the button to execute your trade."
            ),
        },
    ],
    orders: [
        {
            target: "#ordersSection",
            disableBeacon: true,
            placement: "center",
            ...basicStyles(
                "This section allows you to view your orders. You can filter between closed and opened orders."
            ),
        },
        {
            target: "#refresh",
            disableBeacon: true,
            ...basicStyles(
                "Refresh the orders list to see the latest updates."
            ),
        },
        {
            target: "#closedOrders",
            disableBeacon: true,
            ...basicStyles(
                "These are your closed orders."
            ),
        },
        {
            target: "#openedOrders",
            disableBeacon: true,
            ...basicStyles(
                "These are your opened orders."
            ),
        },
    ],
    wallet: [
        {
            target: "#walletSection",
            disableBeacon: true,
            placement: "center",
            ...basicStyles(
                "This section allows you to view and manage your wallet. You can see the distribution of your assets, refresh the page, and filter by different exchanges."
            ),
        },
        {
            target: "#refreshWallet",
            disableBeacon: true,
            ...basicStyles(
                "Click here to refresh your wallet data."
            ),
        },
        {
            target: "#exchangeFilters",
            disableBeacon: true,
            ...basicStyles(
                "View your assets across all exchanges or filter by specific exchanges."
            ),
        }
    ],
    arbitration: [
        {
            target: "#arbitrationSection",
            disableBeacon: true,
            placement: "center",
            ...basicStyles(
                "This section allows you to view and manage your arbitration opportunities, strategies, and actions."
            ),
        },
        {
            target: "#refresh",
            disableBeacon: true,
            ...basicStyles(
                "Refresh the arbitrage list to see the latest updates."
            ),
        },
        {
            target: "#opportunitiesTab",
            disableBeacon: true,
            ...basicStyles(
                "View the latest price differences found across exchanges."
            ),
        },
        {
            target: "#strategiesTab",
            disableBeacon: true,
            ...basicStyles(
                "Create and manage your trading strategies."
            ),
        },
        {
            target: "#actionsTab",
            disableBeacon: true,
            ...basicStyles(
                "View your triggered actions."
            ),
        }
    ],
    addStrategy: [
        {
            target: "#newStrategyButton",
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            spotlightClicks: true,
            ...basicStyles(
                "Click here to create a new trading strategy."
            ),
        },
        {
            target: "#strategyFormSection",
            disableBeacon: true,
            ...basicStyles(
                "This section allows you to input the details for creating a new trading strategy."
            ),
        },
        {
            target: "#exchange1Select",
            disableBeacon: true,
            ...basicStyles(
                "Select the first exchange where the trading strategy will be executed."
            ),
        },
        {
            target: "#exchange2Select",
            disableBeacon: true,
            ...basicStyles(
                "Select the second exchange where the trading strategy will be executed."
            ),
        },
        {
            target: "#pairSelect",
            disableBeacon: true,
            ...basicStyles(
                "Choose the trading pair for the strategy."
            ),
        },
        {
            target: "#amountInput",
            disableBeacon: true,
            ...basicStyles(
                "Enter the amount to be traded."
            ),
        },
        {
            target: "#profitInput",
            disableBeacon: true,
            ...basicStyles(
                "Specify the profit percentage you aim to achieve."
            ),
        },
        {
            target: "#saveButton",
            disableBeacon: true,
            ...basicStyles(
                "Click here to save the new trading strategy."
            ),
        },
        {
            target: "#cancelButton",
            disableBeacon: true,
            ...basicStyles(
                "Click here to cancel and discard the strategy creation."
            ),
        },
    ],
    strategies: [
        {
            target: "#strategiesHeader",
            disableBeacon: true,
            ...basicStyles(
                "Here, you can see the strategies you have set up. Each strategy includes the exchanges, trading pair, minimum profit, and required balance."
            ),
        },
        {
            target: "#requiredBalance",
            disableBeacon: true,
            ...basicStyles(
                "This column shows the required balance for each strategy."
            ),
        },
        {
            target: "#editStrategyButton",
            disableBeacon: true,
            ...basicStyles(
                "Edit an existing strategy."
            ),
        },
        {
            target: "#deleteStrategyButton",
            disableBeacon: true,
            ...basicStyles(
                "Delete a strategy you no longer need."
            ),
        }
    ],
    markets: [
        {
            target: "#markets",
            disableBeacon: true,
            placement: "center",
            ...basicStyles(
                "Markets section provides comprehensive tools and data to help you trade and analyze cryptocurrencies effectively."
            ),
        },
        {
            target: "#top100",
            disableBeacon: true,
            ...basicStyles(
                "This section lists the top 100 cryptocurrencies by market capitalization."
            ),
        },
        {
            target: "#heatmap",
            disableBeacon: true,
            ...basicStyles(
                "This section provides a visual representation of the market performance of various cryptocurrencies."
            ),
        },
        {
            target: "#symbol",
            disableBeacon: true,
            ...basicStyles("Name and symbol of the cryptocurrency."),
        },
        {
            target: "#price",
            disableBeacon: true,
            ...basicStyles("Current price of the cryptocurrency in USD."),
        },
        {
            target: "#priceHour",
            disableBeacon: true,
            ...basicStyles("Price change percentage over the last hour."),
        },
        {
            target: "#priceDay",
            disableBeacon: true,
            ...basicStyles("Price change percentage over the last 24 hours."),
        },
        {
            target: "#volumeDay",
            disableBeacon: true,
            ...basicStyles(
                "Trading volume of the cryptocurrency over the last 24 hours."
            ),
        },
        {
            target: "#capitalization",
            disableBeacon: true,
            ...basicStyles("Market capitalization of the cryptocurrency."),
        },
    ],
    news: [
        {
            target: "#news",
            disableBeacon: true,
            placement: "center",
            ...basicStyles(
                "News section provides the latest updates and articles on various topics related to cryptocurrency. Stay informed about market trends, technological advancements, regulatory changes, and expert analyses to make well-informed decisions in your crypto journey."
            ),
        },
    ],
    admin: [
        {
            target: "#adminSection",
            disableBeacon: true,
            placement: "center",
            ...basicStyles(
                "This section allows you to manage users, messages, and videos in your platform. Here, you can view, edit, and delete user information, handle messages, and manage video content."
            ),
        },
        {
            target: "#usersTab",
            disableBeacon: true,
            ...basicStyles(
                "Click here to view and manage the list of users on your platform."
            ),
        },
        {
            target: "#messagesTab",
            disableBeacon: true,
            ...basicStyles(
                "Click here to view and manage messages. You can also add new messages here."
            ),
        },
        {
            target: "#videosTab",
            disableBeacon: true,
            ...basicStyles(
                "Click here to view and manage video content. You can also add new videos here."
            ),
        },
        {
            target: "#refreshButton",
            disableBeacon: true,
            ...basicStyles(
                "Click here to refresh the data in this section."
            ),
        },
        {
            target: "#editButton",
            disableBeacon: true,
            ...basicStyles(
                "Click here to edit a user's information."
            ),
        },
        {
            target: "#deleteButton",
            disableBeacon: true,
            ...basicStyles(
                "Click here to delete user."
            ),
        }
    ],
    profile: [
        {
            target: "#profileSection",
            disableBeacon: true,
            placement: "center",
            ...basicStyles(
                "Profile section allows you to view and edit your personal information, change your password, and manage your account settings."
            ),
        },
        {
            target: "#profile",
            disableBeacon: true,
            ...basicStyles(
                "You can edit your personal information and change your password here."
            ),
        },
        {
            target: "#settings",
            disableBeacon: true,
            ...basicStyles(
                "In the Settings tab, you can enable or disable two-factor authentication (2FA) and manage your tour settings."
            ),
        },
        {
            target: "#exchanges",
            disableBeacon: true,
            ...basicStyles(
                "Manage your exchanges here. You can enable and disable your preferred exchanges."
            ),
        },
        {
            target: "#plans",
            disableBeacon: true,
            ...basicStyles(
                "Choose a plan that suits you best. You can opt for monthly or yearly plans."
            ),
        },
        {
            target: "#referrals",
            disableBeacon: true,
            ...basicStyles("View all your earnings from referrals in this section."),
        },
        {
            target: "#signout",
            disableBeacon: true,
            ...basicStyles("Click here to log out of your session."),
        },
    ],
    addExchange: [
        {
            target: "#home",
            disableBeacon: true,
            placement: "center",
            ...videoBasicStyle("Welcome to CTrading!"),
        },
        {
            target: "#addExchange",
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            spotlightClicks: true,
            ...basicStyles(
                'Before you can interact on our platform, you need to activate an exchange. Click the "Activate" button to proceed.'
            ),
        },
        {
            target: "#exchangesSection",
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
            ...basicStyles(
                'Please select your preferred exchange and then click the "API Keys" button to proceed.'
            ),
        },
        {
            target: "#selectedExchanges",
            disableBeacon: true,
            disableOverlayClose: true,
            hideFooter: true,
            spotlightClicks: true,
            ...basicStyles(
                `Please fill out all fields and then click the "Save API Keys" button.`
            ),
        },
    ],
};
