import "./styles.scss"

export default function ProfitPerOne({ profit }) {
const profitPercentage = profit * 100
  return (
    <div className="cp-container">
      <div className="name">{profitPercentage.toFixed(2)} %</div>
    </div>
  )
};
