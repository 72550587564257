import axios from "axios"
import { BACK_API } from "apiconfig"
import { store } from "index";

export async function fetchOrdersFromExchange(exchange) {
    const userId = store.getState().currentUser.data.id
    const headers = {
        'token': store.getState().currentUser.data.jwtToken,
        'id': userId
    };
    // By email with every active exchange
    const url = `${BACK_API}/orders-from-exchange`
    const body = {
        exchange: exchange,
        userId: userId
    }

    return await axios
        .post(url, body, { headers })
        .then(res => {
            return res.data.rows.map(row => {
                return {
                    id: row.id_order,
                    exchange_order_id: row.exchange_order_id,
                    pair: row.pair,
                    side: row.side,
                    type: row.type,
                    amount: row.amount,
                    price: row.price,
                    timestamp: row.timestamp,
                    status: row.status,
                    exchange: row.exchange,
                    submittedBy: row.submitted_by
                }
            });
        })
        .catch(error => {
            console.error(error);
        });
}

export async function fetchOrdersFromExchanges() {
    const userId = store.getState().currentUser.data.id
    const headers = {
        'token': store.getState().currentUser.data.jwtToken,
        'id': userId
    };
    const url = `${BACK_API}/orders-from-exchanges`
    const body = { userId: userId }

    return await axios.post(url, body, { headers })
        .then(res => {
            return res.data.rows.map(row => {
                return {
                    id: row.id,
                    exchange_order_id: row.exchange_order_id,
                    pair: row.pair,
                    side: row.side,
                    type: row.type,
                    amount: row.amount,
                    price: row.price,
                    timestamp: row.timestamp,
                    status: row.status,
                    exchange: row.exchange,
                    submittedBy: row.submitted_by
                }
            });
        })
        .catch(error => {
            console.error(error);
        });
}

export async function cancelOrder(order) {
    const keys = store.getState().keys.data
    const selectedExchageKeys = keys.find(exchange => exchange.exchange === order.exchange)
    const headers = {
        'token': store.getState().currentUser.data.jwtToken,
        'id': store.getState().currentUser.data.id
    };
    const url = `${BACK_API}/cancel-order`
    try {
        await axios
            .post(url, {
                exchange: order.exchange.toLowerCase(),
                public_key: selectedExchageKeys.publicKey,
                private_key: selectedExchageKeys.privateKey,
                password: selectedExchageKeys.password,
                exchange_order_id: order.exchange_order_id,
                pair: order.pair
            }, { headers })
    } catch (error) {
        console.log(error)
        throw Error(error)
    }
}

export async function exchangeMinValue({ exchange, symbol }) {

    const options = {
        url: `${BACK_API}/min-value`,
        method: "POST",
        headers: {
            'token': store.getState().currentUser.data.jwtToken,
            'id': store.getState().currentUser.data.id
        },
        data: { exchange, symbol },
    }

    try {
        return await axios
            .request(options)
            .then(({ data }) => {
                if (data.length) {
                    return data[0];
                }
            })
            .catch(err => console.log(err))
    } catch (error) {
        console.log(error)
        throw Error(error)
    }
}