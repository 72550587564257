import { NETWORKS } from "constants";
import clientConfig from "utils/clientConfig";

// Config at .env when deploying: 
export const SMARTS_CONTRACTS = clientConfig.unlockContracts.split(","); // Unlock login (add contracts at .env as needed)
export const ENV = process.env.REACT_APP_NODE_ENV;

export const CLIENT_NAME = clientConfig.name;
export const DOMAIN = clientConfig.domain;
export const API = `https://${CLIENT_NAME}.${DOMAIN}/api`;
export const BACK_API = process.env.REACT_APP_NODE_BACK_API;
export const NETWORK = NETWORKS[clientConfig.unlockNetwork];
export const CRYPTOCOMPARE_API_KEY = process.env.REACT_APP_CRYPTOCOMPARE_APIKEY
