import Lottie from "lottie-react";

//Animations
import success from "assets/animation/success.json"
import failed from "assets/animation/failed.json"

import './styles.css';

export default function Animation({status, setStatus}) { 
    const { error } = status;

    const handleComplete = () => {
        setStatus({
            isCompleted: false,
            success: false,
            error: false,
            message: "",
        });
    }

    return (  
        <center>
            <Lottie 
                className="animation"
                loop={false} 
                onComplete={handleComplete} 
                animationData={error ? failed : success} 
            />
        </center>      
    );     
};
