// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SummaryChartBars from "./components/Charts/SummaryChartBars";
import SummaryChartPie from "./components/Charts/SummaryChartPie";
import SoftButton from "components/SoftButton";
import Loader from "components/Loader";
import WalletsAddedGuard from "componentsCustom/WalletsAddedGuard";
import { useDispatch, useSelector } from "react-redux"
import WalletError from "./components/WalletError";
import { getWallets } from "redux/actions/wallets";
import WalletSelector from "./components/WalletSelector";
import Icon from "@mui/material/Icon";
import { STATUS } from "constants/fetchStatus";
import useAllWalletStatus from "utils/useAllWalletStatus";
import "./styles.css";
import PairsBox from "./components/PairsBox";


export default function Wallet() {

  const dispatch = useDispatch()
  const updateWallets = () => dispatch(getWallets())
  const wallets = useSelector(state => state.wallets)
  const [selectedWallet, setSelectedWallet] = useState("all")
  const fullSelectedWallet = wallets[selectedWallet]
  const { allWalletStatus, someWalletLoaded } = useAllWalletStatus()
  const walletStatus = selectedWallet === "all" ? allWalletStatus : fullSelectedWallet.status

  return (
    <DashboardLayout>
      <WalletsAddedGuard>
        <SoftBox id="walletSection">
          <SoftBox
            display="flex"
            alignItems="center"
          >
            <SoftButton id="refreshWallet" disabled={walletStatus === STATUS.LOADING} className="refresh-button" onClick={updateWallets}>
              {walletStatus === STATUS.LOADING ? <Loader size={"sm"} /> : <SoftTypography
                component="span"
                variant="h2"
                className="refresh-icon"
              >
                <Icon>cached</Icon>
              </SoftTypography>}
            </SoftButton>
            <WalletSelector selectedWallet={selectedWallet} setSelectedWallet={setSelectedWallet} wallets={wallets} />
          </SoftBox>
          {walletStatus === STATUS.ERROR && <WalletError />}
          {fullSelectedWallet?.pairs.length === 0 && <SoftBox mt={2}>
            <SoftTypography variant="p">This wallet is empty</SoftTypography>
          </SoftBox>}
          {(someWalletLoaded && fullSelectedWallet.pairs.length > 0) &&
            <SoftBox mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <SummaryChartPie fullSelectedWallet={fullSelectedWallet} walletStatus={walletStatus} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SummaryChartBars fullSelectedWallet={fullSelectedWallet} walletStatus={walletStatus} />
                </Grid>
                <Grid item xs={12} md={12}>
                  <PairsBox selectedWallet={selectedWallet} walletStatus={walletStatus} />
                </Grid>
              </Grid>
            </SoftBox>
          }
        </SoftBox>
      </WalletsAddedGuard>
    </DashboardLayout>
  );
}
