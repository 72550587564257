import "./styles.scss"

export default function Amount({ amount, symbol }) {
  const coin1 = symbol.toLowerCase();
  const icon = `https://cdn.jsdelivr.net/npm/cryptocurrency-icons@0.18.1/32/icon/${coin1}.png`

  return (
    <div className="cp-container">
      <img
        className="icon"
        src={icon}
      />
      <div className="name">{amount}</div>
    </div>
  )
};
