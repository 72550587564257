import { useState, useEffect } from 'react'
// Redux
import { useDispatch } from "react-redux";
import { insertStrategy } from "redux/actions/arbitration";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
// NewProduct page components
import StrategyInfo from "./components/StrategyInfo";
// Translate
import { useTranslation } from 'react-i18next';
import useTour from 'hooks/tours/useTour';

function NewStrategy({ setShowComponent }) {
  const { t } = useTranslation();
  const { TourComponent, setTourSection } = useTour();
  const dispatch = useDispatch();

  const [newStrategy, setNewStrategy] = useState({
    exchange1: "",
    exchange2: "",
    pair: "",
    amount: "",
    profit: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    await dispatch(insertStrategy(newStrategy));
    setShowComponent(prev => ({ ...prev, newStrategy: false, table: true }));
  };

  const handleCancel = (event) => {
    event.preventDefault();
    setShowComponent(prev => ({ ...prev, newStrategy: false, table: true }));
  };

  useEffect(() => {
    setTourSection("addStrategy");
  }, []);

  return (
    <SoftBox mt={1} mb={20}>
      {TourComponent}
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={8}>
          <Card id="strategyFormSection" sx={{ overflow: "visible" }}>
            <SoftBox p={2}>
              <SoftBox component="form" onSubmit={handleSubmit}>
                <StrategyInfo {...newStrategy} setNewStrategy={setNewStrategy} />
                <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                  <SoftButton id="cancelButton" variant="gradient" color="error" onClick={handleCancel} >
                    {t('cancel')}
                  </SoftButton>
                  <SoftButton id="saveButton" variant="gradient" color="info" type="submit">
                    {t('save')}
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default NewStrategy;
