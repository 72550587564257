// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-redux
import { useSelector } from 'react-redux';

// @mui material components
import SvgIcon from '@mui/material/SvgIcon';

// Flags
import { getFlagIcon } from "../data/flags";

function FlagIcon({ handle }) {
    const { language } = useSelector(state => state.currentUser.data);
    return (
        <SvgIcon onClick={handle} sx={{ cursor: "pointer" }}>
            {getFlagIcon(language)}
        </SvgIcon>
    );
}

// Setting default values for the props of FlagIcon
FlagIcon.defaultProps = {
};

// Typechecking props for the FlagIcon
FlagIcon.propTypes = {
    handle: PropTypes.func.isRequired,
};

export default FlagIcon;
