import { ROUTES } from 'constants';
import { store } from 'index';
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ component, isAuth = false }) => {
  if (isAuth) {
    return store.getState().currentUser.data.jwtToken ? <Navigate to={ROUTES.HOME} replace /> : component;
  } else {
    return store.getState().currentUser.data.jwtToken ? component : <Navigate to={ROUTES.LOGIN} replace />;
  }
}

export default PrivateRoute;