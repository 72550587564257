// @mui material components
import { Icon } from "@mui/material";

// Soft UI Dashboard React components
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

export default function ExchangeButton({ handleOpen }) {
  return (
    <SoftButton
      variant="gradient"
      color="info"
      size="small"
      circular
      onClick={handleOpen}
    >
      <SoftTypography variant="caption" color="white">
        API Keys
      </SoftTypography>
      <Icon>arrow_forward_ios</Icon>
    </SoftButton>
  );
}
