/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

// Translate
import { useTranslation } from 'react-i18next';

function DataTableBodyCell({ noBorder, align, header, children }) {
  const { t } = useTranslation();
  const { size } = typography;

  const tdContainer = ({ breakpoints }) => ({
    [breakpoints.down("md")]: {
      display: "grid",
      gridTemplateColumns: "25ch auto",
    },
  });

  return (
    <SoftBox
      component="td"
      textAlign={align}
      fontSize={size.sm}
      py={1.5}
      px={3}
      sx={(theme) => tdContainer(theme)}
    >
      <SoftTypography
        variant="caption"
        textTransform="uppercase"
        fontWeight="bold"
        color="secondary"
        opacity={0.7}
        fontSize={size.xs}
        display={{ xs: "inline-block", md: "none" }}
        alignSelf="center"
      >
        {t(header)}:
      </SoftTypography>
      <SoftBox
        display="inline-block"
        color="text"
        sx={{ verticalAlign: "middle" }}
      >
        {children}
      </SoftBox>
    </SoftBox>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
  header: "",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
  header: PropTypes.string,
};

export default DataTableBodyCell;
