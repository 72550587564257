// Redux
import { useSelector } from "react-redux"

/// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from 'components/SoftSelect';

// Translate
import { useTranslation } from 'react-i18next';

import "./styles.css";

export default function PairSelectorArbitration({ disabled = false, defaultInputValue = "", setStrategy }) {
    const { t } = useTranslation();

    const pairs = useSelector(state => state.arbitration.pairs.data);

    const options = pairs.map(pair => {
        const { pares } = pair;
        const value = pares;
        const label = pares.replace("-", "/");
        return { value, label };
    });

    const handleChange = ({ value }) => {
        setStrategy(prev => ({ ...prev, pair: value }));
    };

    return (
        <SoftBox id="pairSelect" mb={3}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                >
                    {t('pair')}
                    {!disabled && <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                        color='error'
                    >
                        *
                    </SoftTypography>}
                </SoftTypography>
            </SoftBox>
            <SoftSelect
                className="select"
                size="small"
                options={options}
                defaultValue={[...options].find(
                    (option) => option.value === defaultInputValue
                )}
                onChange={handleChange}
                isDisabled={disabled}
                placeholder={t("Select...")}
                menuPosition="fixed"
                required
            />
        </SoftBox>
    );
}
