import SoftButton from "components/SoftButton";

// Translate
import { useTranslation } from 'react-i18next';

import "../../styles.css";
import SoftBox from "components/SoftBox";

export default function WalletSelector({ selectedWallet, setSelectedWallet, wallets }) {
    const { t } = useTranslation();

    // Gets the "all" wallet to the first position
    const walletsArray = Object.keys(wallets);
    walletsArray.sort((a, b) => (a === "all" ? -1 : b === "all" ? 1 : a.localeCompare(b)));
    return (
        <SoftBox id="exchangeFilters">
            {walletsArray.map((wallet) => {
                return (
                    <SoftButton
                        key={wallet}
                        className={selectedWallet === wallet ? "selected" : "notSelected"}
                        variant="text" color="dark" value={wallet}
                        onClick={e => setSelectedWallet(e.target.value)}
                    >
                        {t(wallet)}
                    </SoftButton>
                )
            })}
        </SoftBox>
    )
}