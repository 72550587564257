import { useState, useEffect } from "react";

// react-redux
import { useSelector } from "react-redux";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Loader from "components/Loader";

// Constans
import { STATUS } from "constants/fetchStatus";
import { LANGUAGES } from "constants";

// Translate
import { useTranslation } from "react-i18next";

import "./index.css";

function News() {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.currentUser.data);
  const { es, en } = useSelector((state) => state.news.data);
  const { status: newsStatus } = useSelector((state) => state.news);
  const [showNew, setShowNew] = useState({});

  const getNew = () => {
    switch (language) {
      case LANGUAGES.ES:
        setShowNew(es[0]);
        break;
      case LANGUAGES.EN:
        setShowNew(en[0]);
        break;
      default:
        setShowNew(en[0]);
        break;
    }
  };

  useEffect(() => {
    newsStatus === STATUS.OK && getNew();
  }, [language, newsStatus]);

  return (
    <Card>
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        borderRadius="xl"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 1)
            )}, url(${showNew?.imageurl})`,
          backgroundSize: "cover",
        }}
      >
        {newsStatus === STATUS.LOADING && <Loader />}
        {newsStatus === STATUS.ERROR && t("Oops, something went wrong...")}
        {newsStatus === STATUS.OK && showNew?.title && (
          <SoftBox p={2} width="100%" height="23rem">
            <SoftBox width="100%" height="80%">
              <SoftTypography
                className="title__news"
                variant="h5"
                color="white"
                fontWeight="bold"
                p={0.5}
                height="31%"
              >
                {showNew.title}
              </SoftTypography>
              <SoftTypography
                className="body__news"
                variant="body2"
                color="white"
                fontWeight="light"
                p={0.5}
                height="66%"
                align="justify"
                paragraph
              >
                {showNew.body}
              </SoftTypography>
            </SoftBox>
            <SoftBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              height="20%"
              p={2}
            >
              <SoftBox>
                <Link href={showNew.url} target={showNew.url}>
                  <SoftTypography
                    variant="button"
                    color="white"
                    fontWeight="medium"
                    sx={{
                      border: "1px solid white",
                      p: "0px 11px",
                      borderRadius: "54px",
                      display: "inline-flex",
                      alignItems: "center",
                      "& .material-icons-round": {
                        transform: `translate(2px, -0.5px)`,
                        transition:
                          "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                      },

                      "&:hover .material-icons-round, &:focus  .material-icons-round":
                        {
                          transform: `translate(6px, -0.5px)`,
                        },
                    }}
                  >
                    {t("Read More")}
                    <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                  </SoftTypography>
                </Link>
              </SoftBox>
              <SoftBox>
                <SoftTypography
                  component="a"
                  to={showNew.url}
                  align="right"
                  variant="subtitle2"
                  fontWeight="bold"
                  color="white"
                >
                  {showNew.source}
                </SoftTypography>
                <SoftTypography
                  align="right"
                  variant="body2"
                  fontWeight="light"
                  color="white"
                >
                  {new Intl.DateTimeFormat("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                  }).format(showNew.published_on)}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        )}
      </SoftBox>
    </Card>
  );
}

export default News;
