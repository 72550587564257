// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// Soft UI Dashboard PRO React example components
import DataTable from "examples/Tables/DataTable";
// Overview page components
import ProductCell from "layouts/Common/Ecommerce/overview/components/ProductCell";
import DefaultCell from "layouts/Common/Ecommerce/overview/components/DefaultCell";
import { useTranslation } from 'react-i18next';
import team1 from "assets/images/team-3.jpg";

export default function ReferralsTable({ referrals }) {

    const { t } = useTranslation();
    const entriesPerPage = {
        defaultValue: 5,
        entries: [5, 10, 15, 50, 100]
    }
    const tableData = {
        columns: [
            { Header: "user", accessor: "user", width: "40%" },
            { Header: "earnings", accessor: "earnings", align: "center" },
            { Header: "lvl", accessor: "lvl" },
            { Header: t("contract count"), accessor: "contractCount", align: "center" }
        ],

        rows: referrals.users.map(referral => {
            return {
                user: <ProductCell image={team1} name={referral.username} referrals={referral.referral_count} />,
                lvl: <DefaultCell>{referral.depth}</DefaultCell>,
                contractCount: <DefaultCell>{referral.contract_prices.length}</DefaultCell>,
                earnings: referral.earnings > 0 ?
                    <DefaultCell>{referral.earnings}<span style={{ color: "#82D616" }}> $</span></DefaultCell> : "-"
            }
        })
    }

    return (
        <Card>
            <SoftBox pt={3} px={3}>
                <SoftTypography variant="h6" fontWeight="medium">
                    {t("Top referred users")}
                </SoftTypography>
            </SoftBox>
            {
                referrals.users.length > 0 ?
                    <DataTable
                        table={tableData}
                        entriesPerPage={entriesPerPage}
                        showTotalEntries={true}
                        isSorted={false}
                        canSearch={false}
                        noEndBorder
                    /> : <SoftBox pt={3} px={3} pb={3}>
                        <SoftTypography variant="h6" fontWeight="medium">
                            {t("No referrals yet!")}
                        </SoftTypography>
                    </SoftBox>
            }
        </Card>
    )
}