import { getYouTubeVideoInfo } from "api/videos";
import { fetchVideos } from "api/videos";
import { SET_VIDEOS, VIDEOS_STATUS } from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";

/***** Redux actions that end up in the store *****/
export const getVideos = () => async (dispatch) => {
  try {
    dispatch({ type: VIDEOS_STATUS, payload: STATUS.LOADING });
    const videos = await fetchVideos();
    const data = await getYouTubeVideoInfo(videos);
    dispatch({ type: SET_VIDEOS, payload: data });
    dispatch({ type: VIDEOS_STATUS, payload: STATUS.OK });
  } catch (error) {
    dispatch({ type: VIDEOS_STATUS, payload: STATUS.ERROR });
  }
};
