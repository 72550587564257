import axios from "axios";
import { BACK_API } from "apiconfig";
import { store } from "index";
import { loadStripe } from "@stripe/stripe-js";
import { updateSubscritionStatus } from "redux/actions/currentUser";
import { setInformativeMessage } from "redux/actions/informativeMessage";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const fetchCheckout = async (priceId) => {
  const headers = {
    token: store.getState().currentUser.data.jwtToken,
    id: store.getState().currentUser.data.id,
  };
  const url = `${BACK_API}/checkout`;
  const body = { priceId, userId: store.getState().currentUser.data.id };

  axios
    .post(url, body, { headers })
    .then(async ({ data }) => {
      // Redirect to Stripe Checkout
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: data.sessionId,
      });

      if (error) {
        console.error("Error redirecting to Stripe Checkout:", error);
        store.dispatch(
          setInformativeMessage({
            success: false,
            message:
              "Error redirecting to Stripe Checkout. Please try again or contact support if the issue persists.",
          })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        setInformativeMessage({
          success: false,
          message:
            "Error redirecting to Stripe Checkout. Please try again or contact support if the issue persists.",
        })
      );
      console.log({ ...err });
    });
};

export const getCheckoutResponse = async (sessionId) => {
  const headers = {
    token: store.getState().currentUser.data.jwtToken,
    id: store.getState().currentUser.data.id,
  };
  const url = `${BACK_API}/checkout-session`;
  const body = { sessionId, userId: store.getState().currentUser.data.id };

  return await axios
    .post(url, body, { headers })
    .then(({ data }) => {
      store.dispatch(updateSubscritionStatus(data.status));
      store.dispatch(
        setInformativeMessage({
          success: true,
          message:
            "Your subscription has been successfully processed. Thank you for subscribing!",
        })
      );
    })
    .catch((err) => {
      store.dispatch(
        setInformativeMessage({
          success: false,
          message:
            "There was an error processing your charge. Please contact support for assistance.",
        })
      );
      console.log({ ...err });
    });
};

export const fetchUnsubscription = async () => {
  const headers = {
    token: store.getState().currentUser.data.jwtToken,
    id: store.getState().currentUser.data.id,
  };
  const url = `${BACK_API}/unsubscribe`;
  const body = { userId: store.getState().currentUser.data.id };

  return await axios
    .post(url, body, { headers })
    .then(({ data }) => {
      store.dispatch(updateSubscritionStatus(data.status));
    })
    .catch((err) => {
      console.log({ ...err });
    });
};
