import { useState } from "react";

// react-redux
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "redux/actions/users";

// sweetalert2 components
import Swal from "sweetalert2";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Admin React components
import AdminDataTable from "../../Components/AdminDataTable";
import EditUser from "../../Components/EditForm/EditUser";

// Utils
import { deleteUser } from "api/users";
import { getUsersDataTableData } from "../../data/dataTableData";

// Translate
import { useTranslation } from "react-i18next";

export default function UsersTab() {
  const { t } = useTranslation();
  const { data: users, status } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const cancelEdit = () => {
    setIsEditing(false);
    setSelectedUser({});
  };

  const editClickedUser = (user) => {
    setSelectedUser(user);
    setIsEditing(true);
  };

  const refreshButtonAction = () => {
    dispatch(getUsers());
  };

  const handleRemove = ({ id: userId, email: userEmail }) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: `${t("Delete user")} ${userEmail}?`,
        text: t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("Yes, DELETE!"),
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteUser(userId)
            .then(() => {
              cancelEdit();
              refreshButtonAction();
              Swal.fire(
                t("Deleted!"),
                `${"User"} ${userEmail} ${t("has been deleted")}`,
                "success"
              );
            })
            .catch(() => {
              Swal.fire(
                t("Error"),
                `${t("User")} ${userEmail} ${t("could not be deleted")}`,
                "error"
              );
            });
        }
      });
  };

  return (
    <SoftBox>
      {isEditing && (
        <EditUser
          userData={selectedUser}
          handleCancel={cancelEdit}
          handleRemove={handleRemove}
          handleRefresh={refreshButtonAction}
        />
      )}
      {!isEditing && (
        <AdminDataTable
          label="Users"
          dataTableData={getUsersDataTableData(
            users,
            "User",
            editClickedUser,
            handleRemove
          )}
          dataState={status}
          handleRefresh={refreshButtonAction}
        />
      )}
    </SoftBox>
  );
}
