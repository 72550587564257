import { useState, useEffect } from "react";

import { v4 as uuidv4 } from "uuid";

// react-redux
import { useSelector, useDispatch } from "react-redux";
import { getNews } from "redux/actions/news";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Loader from "components/Loader";

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Constans
import { STATUS } from "constants/fetchStatus";
import { LANGUAGES } from "constants";

// Translate
import { useTranslation } from "react-i18next";

import "./index.css";

function News() {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.currentUser.data);
  const { es, en } = useSelector((state) => state.news.data);
  const { status: newsStatus } = useSelector((state) => state.news);
  const [showNew, setShowNew] = useState([]);
  const dispatch = useDispatch();

  const getNew = () => {
    switch (language) {
      case LANGUAGES.ES:
        setShowNew(es);
        break;
      case LANGUAGES.EN:
        setShowNew(en);
        break;
      default:
        setShowNew(en);
        break;
    }
  };

  useEffect(() => {
    newsStatus === STATUS.OK && getNew();
  }, [language, newsStatus]);

  useEffect(() => {
    dispatch(getNews());
  }, []);

  return (
    <DashboardLayout>
      <SoftBox id="news" mt={3}>
        {newsStatus === STATUS.LOADING && <Loader />}
        <Grid container mb={3} spacing={3}>
          {newsStatus === STATUS.OK &&
            showNew.map(
              ({ imageurl, title, url, body, source, published_on }) => (
                <Grid key={uuidv4()} item lg={4}>
                  <SoftBox className="card-news" position="relative">
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      height="100%"
                      py={2}
                      px={2}
                      borderRadius="xxl"
                      sx={{
                        backgroundImage: ({
                          functions: { linearGradient, rgba },
                          palette: { gradients },
                        }) =>
                          `${linearGradient(
                            rgba(gradients.dark.main, 0.5),
                            rgba(gradients.dark.state, 1)
                          )}, url(${imageurl})`,
                        backgroundSize: "cover",
                      }}
                    >
                      <SoftBox px={2} pt={1}>
                        <SoftTypography
                          variant="h5"
                          color="white"
                          fontWeight="bold"
                        >
                          {title}
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox mb={0} px={2} pt={1}>
                        <SoftTypography
                          className="descripcion"
                          variant="h6"
                          color="white"
                          fontWeight="light"
                        >
                          {body}
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox className="news-author" mt={3} px={2}>
                        <SoftTypography
                          component="a"
                          to={url}
                          align="right"
                          variant="subtitle2"
                          fontWeight="bold"
                          color="white"
                        >
                          {source}
                        </SoftTypography>
                        <SoftTypography
                          align="right"
                          variant="body2"
                          fontWeight="light"
                          color="white"
                        >
                          {new Intl.DateTimeFormat("es-ES", {
                            hour: "2-digit",
                            minute: "2-digit",
                          }).format(published_on)}
                        </SoftTypography>
                      </SoftBox>
                      <Link href={url} target={url}>
                        <SoftTypography
                          variant="button"
                          color="white"
                          fontWeight="medium"
                          sx={{
                            border: "1px solid white",
                            p: "0px 11px",
                            borderRadius: "54px",
                            ml: 2,
                            mt: 9,
                            mb: 3,
                            mr: "auto",
                            display: "inline-flex",
                            alignItems: "center",
                            cursor: "pointer",
                            position: "absolute",
                            bottom: 3,
                            "& .material-icons-round": {
                              fontSize: "1.125rem",
                              transform: `translate(2px, -0.5px)`,
                              transition:
                                "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                            },

                            "&:hover .material-icons-round, &:focus  .material-icons-round":
                            {
                              transform: `translate(6px, -0.5px)`,
                            },
                          }}
                        >
                          {t("Read More")}
                          <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                        </SoftTypography>
                      </Link>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              )
            )}
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default News;
