import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// @mui material icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import StatusAnimations from "componentsCustom/StatusAnimations";

// Admin React components
import AdminButton from "../AdminButton";

// Utils
import { STATUS } from "constants/fetchStatus";
import { updateMessage } from "api/messages";
import { timestampToDateTime } from "utils/timestampToDateTime";
import { messageForm } from "../../schemas/form";

// Translate
import { useTranslation } from "react-i18next";

export default function EditMessage({
  messageData,
  handleCancel,
  handleRemove,
  handleRefresh,
}) {
  const { t } = useTranslation();
  const { id: messageId, author, content, create_date } = messageData;
  const [formStatus, setFormStatus] = useState(STATUS.PENDING);
  const [tempMessageData, setTempMessageData] = useState({
    id: messageId,
    author,
    content,
    create_date,
  });

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    setTempMessageData({ ...tempMessageData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormStatus(STATUS.LOADING);
    updateMessage(tempMessageData)
      .then(() => {
        handleRefresh();
        setFormStatus(STATUS.OK);
      })
      .catch((err) => {
        setFormStatus(STATUS.ERROR);
        throw Error(err);
      });
  };

  return (
    <SoftBox my={3}>
      {formStatus === STATUS.PENDING && (
        <Card component="form" onSubmit={handleSubmit}>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <Stack spacing={1} direction="row" alignItems="center">
              <AdminButton onClick={handleCancel} size="medium">
                <ArrowBackIcon />
              </AdminButton>
              <SoftTypography variant="h5" fontWeight="medium">
                {t("Editing message:")} {messageId}
              </SoftTypography>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <AdminButton type="submit">{t("save")}</AdminButton>
              <AdminButton
                onClick={() => handleRemove(messageData)}
                color="error"
              >
                {t("remove")}
              </AdminButton>
            </Stack>
          </SoftBox>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <SoftBox width="100%">
              <SoftBox mb={2}>
                <SoftTypography variant="h5" fontWeight="medium">
                  {t("Message information")}
                </SoftTypography>
              </SoftBox>
              <SoftBox mt={1.625}>
                <Grid container spacing={3}>
                  {Object.entries(tempMessageData).map(
                    ([propName, propData], i) => {
                      if (propName !== "id") {
                        return (
                          <Grid key={i} item xs={12} sm={6}>
                            <SoftBox mb={1.5}>
                              <SoftBox
                                mb={1}
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                              >
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                  textTransform="capitalize"
                                >
                                  {t(messageForm[propName])}
                                </SoftTypography>
                              </SoftBox>
                              <SoftInput
                                name={propName}
                                autoFocus={propName === "content"}
                                value={
                                  propName === "create_date"
                                    ? timestampToDateTime(propData)
                                    : propData
                                }
                                onChange={handleInputChange}
                                disabled={propName !== "content"}
                                required
                              />
                            </SoftBox>
                          </Grid>
                        );
                      }
                    }
                  )}
                </Grid>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      )}
      <StatusAnimations
        status={formStatus}
        setStatusFunction={setFormStatus}
        afterOKAnimationAction={handleCancel}
      />
    </SoftBox>
  );
}
