import { useState } from "react";

// sweetalert2 components
import Swal from "sweetalert2";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Translate
import { useTranslation } from "react-i18next";
import { sendEmailContactUs } from "api/contactUs";
import { contactUsForm } from "../../schemas/contactUs";

function ContactUs({ contactUsHandler }) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_phone: "",
    user_message:
      "Hi CTrading,\n\nI’m looking to discuss CTrading’s custom pricing for my organization.\n\nMany thanks!",
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSendEmail = async (event) => {
    event.preventDefault();
    sendEmailContactUs(formData)
      .then(() =>
        Swal.fire({
          title: t("Message sent!"),
          html: `<p style="white-space: pre;">${t(
            "Thank you for contacting us!"
          )}\n${t("We will be in touch soon.")}</p>`,
          timer: 4000,
          icon: "success",
        }).then(() => {
          contactUsHandler();
        })
      )
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: t("Oops..."),
          html: `<p style="white-space: pre;">${t(
            "Something went wrong."
          )}\n${t("Please try again.")}.</p>`,
          timer: 4000,
          icon: "error",
        });
      });
  };

  const handleCancel = () => contactUsHandler();

  const renderFields = contactUsForm.map(
    ({ id, name, containerProps, label, placeholder, inputProps }) => (
      <SoftBox key={id} component={Grid} item {...containerProps}>
        <SoftBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        >
          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              fontSize={{ xs: "2.5vw", lg: "1vw" }}
            >
              {t(label)}
            </SoftTypography>
          </SoftBox>
          <SoftInput
            placeholder={t(placeholder)}
            value={formData[name]}
            name={name}
            onChange={handleChange}
            {...inputProps}
          />
        </SoftBox>
      </SoftBox>
    )
  );

  return (
    <SoftBox
      position="relative"
      zIndex={10}
      mt={{ xs: "-20rem", lg: "-15rem", xl: "-20rem" }}
      mb={3}
      px={{ xs: 1, sm: 0 }}
    >
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={10} xl={5.5}>
          <SoftBox mt={3}>
            <Card sx={{ height: "100%" }}>
              <SoftBox
                component="form"
                onSubmit={handleSendEmail}
                py={6}
                px={6}
              >
                <SoftBox component={Grid} container spacing={5}>
                  {renderFields}
                </SoftBox>
                <SoftBox mt="10vh">
                  <SoftTypography
                    component="p"
                    color="secondary"
                    variant="caption"
                    fontSize={{ xs: "2.2vw", lg: "0.7vw" }}
                  >
                    {t(
                      "By clicking Talk to CTrading, I acknowledge I have read and understand the "
                    )}
                    <SoftTypography
                      component="a"
                      href="https://ctrading.io/privacy-policy"
                      target="_blank"
                      color="info"
                      fontWeight="bold"
                      textTransform="capitalize"
                      fontSize={{ xs: "2.2vw", lg: "0.7vw" }}
                    >
                      {t("Privacy Notice.")}
                    </SoftTypography>
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  display="flex"
                  flexDirection={{ xs: "column-reverse", lg: "row" }}
                  gap={3}
                  mt="2vh"
                >
                  <SoftButton
                    variant="gradient"
                    color="error"
                    onClick={handleCancel}
                    fullWidth
                  >
                    {t("cancel")}&nbsp;
                  </SoftButton>
                  <SoftButton
                    type="submit"
                    variant="gradient"
                    color="info"
                    fullWidth
                  >
                    {t("Talk to CTrading")}&nbsp;
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </Card>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

// Typechecking props for the ContactUs
ContactUs.propTypes = {
  contactUsHandler: PropTypes.func.isRequired,
};

export default ContactUs;
