// react-router-dom components
import { Link } from "react-router-dom";

// Soft UI Dashboard React component
import SoftTypography from "components/SoftTypography";

// Routes
import { ROUTES } from "constants";

// Translate
import { useTranslation } from "react-i18next";

export default function ForgotLinkBasic() {
  const { t } = useTranslation();
  return (
    <SoftTypography
      component={Link}
      to={ROUTES.FORGOT}
      variant="button"
      color="info"
      fontWeight="medium"
      textGradient
    >
      {t("Forgot your password?")}
    </SoftTypography>
  );
}
