import { timestampToDateTime } from "utils/timestampToDateTime";
import "./styles.scss"

function DateTypography({ timestamp }) {

    return (
        <div className="crypto-pair-container">
            <div className="name">{timestampToDateTime(timestamp)}</div>
        </div>
    )
};

export default DateTypography;