// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
// Soft UI Dashboard React examples
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

export default function ProfileInfoTab({ infoUser, handle }) {
    return (
        <SoftBox mt={5} mb={3}>
            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={6} xl={4}>
                    <ProfileInfoCard
                        title="profile information"
                        description={null}
                        info={infoUser}
                        action={{ handle, tooltip: "edit profile" }}
                    />
                </Grid>
            </Grid>
        </SoftBox>
    )
};