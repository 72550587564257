// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Local component
import ReferalButton from "./ReferalButton";
import SaveButton from "./SaveButton";

export default function ExchangeKeyButtons({ referalLink, label, handleSubmit }) {

    return (
        <SoftBox p={3} mt={-3}>
            <Grid container spacing={3}>
                <ReferalButton referalLink={referalLink} label={label} />
                <SaveButton handleSubmit={handleSubmit} />
            </Grid>
        </SoftBox>
    );
}