import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Translate
import { useTranslation } from "react-i18next";

// Constants
import { dataTabs } from "../../schemas/tabs";

function Header({ tabValue, setTabValue }) {
  const { t } = useTranslation();

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <Grid container mb={2} justifyContent="center">
      <Grid item sm={12} md={8}>
        <SoftBox>
          <AppBar position="static">
            <Tabs
              orientation={tabsOrientation}
              value={tabValue}
              onChange={handleSetTabValue}
              sx={{ background: "transparent" }}
            >
              {dataTabs.map((tab) => {
                const { id, key, name, icon, value } = tab;
                return (
                  tab && (
                    <Tab
                      id={id}
                      key={key}
                      name={name}
                      label={
                        <SoftTypography
                          variant="button"
                          color="text"
                          fontWeight="medium"
                          textTransform="capitalize"
                        >
                          {t(name)}
                        </SoftTypography>
                      }
                      icon={icon}
                      value={value}
                    />
                  )
                );
              })}
            </Tabs>
          </AppBar>
        </SoftBox>
      </Grid>
    </Grid>
  );
}

export default Header;
