import { fetchNews } from "api/news";
import { SET_NEWS } from "constants/actionTypes";
import { NEWS_STATUS } from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";


/***** Redux actions that end up in the store *****/
export const getNews = () => async dispatch => {
    try {
        dispatch({ type: NEWS_STATUS, payload: STATUS.LOADING });
        const data = await fetchNews();
        dispatch({ type: SET_NEWS, payload: data });
        dispatch({ type: NEWS_STATUS, payload: STATUS.OK });
    } catch (error) {
        dispatch({ type: NEWS_STATUS, payload: STATUS.ERROR });
    }
};