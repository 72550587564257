// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import WalletsAddedGuard from "componentsCustom/WalletsAddedGuard";
import { useSelector } from "react-redux"
import WalletError from "./components/WalletError";
import { STATUS } from "constants/fetchStatus";
import useAllWalletStatus from "utils/useAllWalletStatus";
import Loader from "components/Loader";
import SummaryChartPie from "./components/Charts/SummaryChartPie";
import "./styles.css";


export default function Wallet() {

  const wallets = useSelector(state => state.wallets)
  const selectedWallet = wallets.all
  const { allWalletStatus } = useAllWalletStatus()

  return (
    <WalletsAddedGuard>
      <SoftBox height={allWalletStatus === STATUS.LOADING ? "17rem" : "100%"}>
        {allWalletStatus === STATUS.ERROR && <WalletError />}
        {allWalletStatus === STATUS.LOADING && <Loader />}
        {allWalletStatus === STATUS.OK && <SummaryChartPie wallet={selectedWallet} walletStatus={allWalletStatus} />}
      </SoftBox>
    </WalletsAddedGuard>
  );
}
