import React, { useEffect } from 'react'
// Redux
import { useSelector } from "react-redux"

/// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from 'components/SoftSelect';

// Utils
import { parseActiveExchangesFromKeys } from "utils/parseActiveExchangesFromKeys";

// Translate
import { useTranslation } from 'react-i18next';

export default function ExchangeSelectorArbitration({ title, defaultInputValue = "", disabled = false, setStrategy, label, focus = false }) {

  const { t } = useTranslation();
  const exchangesFromRedux = useSelector(state => state.arbitration.exchanges.data);
  const allowedExchanges = exchangesFromRedux.map(exchange => exchange.toLowerCase())
  const activeExchanges = parseActiveExchangesFromKeys();
  const options = [];

  allowedExchanges.forEach(exch => {
    const isActive = activeExchanges.includes(exch);
    isActive && options.push({ value: exch, label: exch });
  });

  const handleChange = ({ value }) => {
    setStrategy(prev => ({ ...prev, [label]: value }));
  };


  return (
    <SoftBox id={title === "Exchange 1" ? "exchange1Select" : "exchange2Select"} mb={3}>
      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {t(title)}
          {!disabled && <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
            color='error'
          >
            *
          </SoftTypography>}
        </SoftTypography>
      </SoftBox>
      <SoftSelect
        size="small"
        options={options}
        autoFocus={focus}
        defaultValue={[...options].find(
          (option) => option.value === defaultInputValue
        )}
        onChange={handleChange}
        isDisabled={disabled}
        placeholder={t("Select...")}
        menuPosition="fixed"
        required
      />
    </SoftBox>
  );
};
