// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import DataTable from "examples/Tables/DataTable";
import Date from "componentsCustom/TableItems/Date";
import Exchange from "componentsCustom/TableItems/Exchange";
import Pair from "componentsCustom/TableItems/Pair";
import { useTranslation } from 'react-i18next';
import ProfitPerOne from "componentsCustom/TableItems/ProfitPerOne";

export default function OpportunitiesTable({ opportunities }) {
    const { t } = useTranslation();
    const columns = [
        { Header: t("Exchange 1"), accessor: "exchange" },
        { Header: t("Exchange 2"), accessor: "exchange2" },
        { Header: t("Pair"), accessor: "pair" },
        { Header: t("Profit"), accessor: "profit", width: "10%" },
        { Header: t("Date"), accessor: "date", width: "15%" },
    ];
    const table = getTableContent(opportunities)
    function getTableContent(opportunities) {
        const rows = opportunities.map(opportunity => {
            const { exchange_1, exchange_2, pair, profit, timestamp } = opportunity;
            return {
                exchange: <Exchange exchange={exchange_1} />,
                exchange2: <Exchange exchange={exchange_2} />,
                pair: <Pair pair={pair} />,
                profit: <ProfitPerOne profit={profit} />,
                date: <Date timestamp={timestamp} />
            };
        });
        return { columns, rows }
    }

    return (
        <SoftBox mt={2}>
            <DataTable
                entriesPerPage={false}
                showTotalEntries={false}
                isSorted={false}
                canSearch={false}
                table={table} />
        </SoftBox>
    )
}
