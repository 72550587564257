/***** We receive an action and set de data to the store *****/

import { SET_PRICING, PRICING_STATUS } from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";

export default (pricing = { data: {}, status: STATUS.PENDING }, action) => {
    switch (action.type) {
        case SET_PRICING:
            return { ...pricing, data: action.payload };
        case PRICING_STATUS:
            return { ...pricing, status: action.payload };
        default:
            return pricing;
    }
};
