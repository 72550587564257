// @mui material components
import Grid from "@mui/material/Grid";

// @mui icons
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SvgIcon from '@mui/material/SvgIcon';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Icons
import { ReactComponent as Discord } from "assets/logos/discord.svg";
import { ReactComponent as Twitter } from "assets/logos/twitter.svg";

function Footer() {
  return (
    <SoftBox component="footer">
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={8}>
          <SoftBox display="flex" justifyContent="center" mt={1} mb={3}>
            <SoftBox component="a" href="https://twitter.com/ctradingio" target="_blank" mr={3} color="secondary">
              <SvgIcon
                fontSize="large"
                sx={{
                  "&:hover": {
                    fill: "#000",
                  },
                }}
              >
                <Twitter />
              </SvgIcon>
            </SoftBox>
            <SoftBox component="a" href="https://www.linkedin.com/company/ctradingio/" target="_blank" mr={3} color="secondary">
              <LinkedInIcon
                fontSize="large"
                sx={{
                  "&:hover": {
                    color: "#0077b5",
                  },
                }}
              />
            </SoftBox>
            <SoftBox component="a" href="https://discord.gg/zd7ArsB5yE" target="_blank" color="secondary">
              <SvgIcon
                fontSize="large"
                sx={{
                  "&:hover": {
                    fill: "#5865F2",
                  },
                }}
              >
                <Discord />
              </SvgIcon>
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
          <SoftTypography variant="body2" color="secondary">
            Copyright &copy; 2024 by <b>CTrading</b>. All Rights Reserved
          </SoftTypography>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default Footer;
