import SoftTypography from "components/SoftTypography";
import { timestampToDateTime } from "utils/timestampToDateTime";

export default function Date({ timestamp }) {
  return (
    <SoftTypography className="table-number">
      {timestamp ? timestampToDateTime(timestamp) : ""}
    </SoftTypography>
  );
}
