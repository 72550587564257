export const trimDecimalZeros = (numberStr) => {
    let [integerPart, decimalPart] = numberStr.split('.');

    integerPart = parseInt(integerPart).toLocaleString('en-US');

    if (decimalPart) {
        while (decimalPart.endsWith('0')) {
            decimalPart = decimalPart.slice(0, -1);
        }

        if (decimalPart === '') {
            return integerPart;
        }

        return `${integerPart}.${decimalPart}`;
    }

    return integerPart;
}