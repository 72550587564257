import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// @mui material icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Loader from "components/Loader";
import StatusAnimations from "componentsCustom/StatusAnimations";

// Admin React components
import AdminButton from "../AdminButton";

// Utils
import { updateUserStatus } from "api/users";
import { fetchReferralEarnings } from "api/referrals";
import { STATUS } from "constants/fetchStatus";

// Translate
import { useTranslation } from "react-i18next";
import UsersInput from "../Input/UsersInput";

export default function EditUser({
  userData,
  handleCancel,
  handleRemove,
  handleRefresh,
}) {
  const { t } = useTranslation();
  const { id: userId, email, userName, status, fullAccess } = userData;
  const [formStatus, setFormStatus] = useState(STATUS.PENDING);
  const [userEarnings, setUserEarnings] = useState();
  const [tempUserData, setTempUserData] = useState({
    id: userId,
    email,
    userName,
    status,
    fullAccess
  });

  const handleInputChange = (label, value) => {
    setTempUserData({ ...tempUserData, [label]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormStatus(STATUS.LOADING);
    updateUserStatus(tempUserData)
      .then(() => {
        handleRefresh();
        setFormStatus(STATUS.OK);
      })
      .catch((err) => {
        setFormStatus(STATUS.ERROR);
        throw Error(err);
      });
  };

  useEffect(() => {
    fetchReferralEarnings(userId)
      .then((res) => setUserEarnings(res))
      .catch((res) => console.log(res));
  }, []);

  return (
    <SoftBox my={3}>
      {formStatus === STATUS.PENDING && (
        <Card component="form" onSubmit={handleSubmit}>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <Stack spacing={1} direction="row" alignItems="center">
              <AdminButton onClick={handleCancel} size="medium">
                <ArrowBackIcon />
              </AdminButton>
              <SoftTypography variant="h5" fontWeight="medium">
                {t("Editing user: ")}
                {email}
              </SoftTypography>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <AdminButton type="submit">{t("save")}</AdminButton>
              <AdminButton onClick={() => handleRemove(userData)} color="error">
                {t("remove")}
              </AdminButton>
            </Stack>
          </SoftBox>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <SoftBox width="100%">
              <SoftBox mb={2}>
                <SoftTypography variant="h5" fontWeight="medium">
                  {t("Personal information")}
                </SoftTypography>
              </SoftBox>
              <SoftBox mt={1.625}>
                <Grid container spacing={3}>
                  {Object.entries(userData).map(([propName, propData], i) => {
                    if (propName !== "id") {
                      return (
                        <Grid key={i} item xs={12} sm={6}>
                          <UsersInput label={propName} value={propData} handle={handleInputChange} />
                        </Grid>
                      );
                    }
                  })}
                  <Grid item xs={12} sm={6}>
                    {userEarnings ? (
                      <SoftBox>
                        <SoftBox mb={1.5}>
                          <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display="inline-block"
                          >
                            <SoftTypography
                              component="label"
                              variant="body2"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              {t("User earnings: ")}
                              {userEarnings.totalEarnings} eu
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox
                          mb={1}
                          ml={0.5}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <SoftTypography
                            component="label"
                            variant="body2"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {t("Paid to user: work in progress")}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    ) : (
                      <Loader />
                    )}
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      )}
      <StatusAnimations
        status={formStatus}
        setStatusFunction={setFormStatus}
        afterOKAnimationAction={handleCancel}
      />
    </SoftBox>
  );
}
