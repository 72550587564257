/***** We receive an action and set de data to the store *****/

import { SET_TOAST } from "constants/actionTypes";

export default (toast = { open: false, title: "", body: "" }, action) => {
    switch (action.type) {
        case SET_TOAST:
            return { open: action.payload.open, title: action.payload.title, body: action.payload.body }
        default:
            return toast;
    };
};