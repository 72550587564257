import Slider from "@mui/material/Slider";

export default function SliderAmount({ amount, total, handleAmount, handleTotal, firstSymbol, secondSymbol, side }) {

    const maxAmount = side === "buy" ? secondSymbol.free : firstSymbol.free
    const value = side === "buy" ? total : amount
    const handleValueFunction = side === "buy" ? handleTotal : handleAmount
    const marks = [{ value: maxAmount / 2 }]

    return (
        <Slider
            marks={marks}
            value={value}
            onChange={handleValueFunction}
            step={maxAmount / 100}
            size="small"
            min={0}
            max={maxAmount}
        />
    )
}