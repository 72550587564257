import { useState } from 'react'
// Redux
import { useDispatch, useSelector } from "react-redux";
import { updateStrategy } from 'redux/actions/arbitration';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
// NewProduct page components
import StrategyInfo from "./components/StrategyInfo";
// Translate
import { useTranslation } from 'react-i18next';

function EditStrategy({ id, setShowComponent }) {
  const { t } = useTranslation();

  const strategies = useSelector(state => state.arbitration.strategies.data);
  const dispatch = useDispatch();
  const { exchange1, exchange2, pair, amount, profit } = strategies.find(strategy => strategy.id === id)
  
  const [newStrategy, setNewStrategy] = useState({
    id,
    exchange1,
    exchange2,
    pair, 
    amount: amount.toString(),
    profit: profit.toString(),
  });
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const parsedStrategy = {
      ...newStrategy,
      profit: Number(newStrategy.profit),
      amount: Number(newStrategy.amount)
    }
    await dispatch(updateStrategy(parsedStrategy));
    setShowComponent(prev => ({ ...prev, editStrategy: false, table: true }));
  };

  const handleCancel = (event) => {
    event.preventDefault();
    setShowComponent(prev => ({ ...prev, editStrategy: false, table: true }));
  };

  return (
    <SoftBox mt={1} mb={20}>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={8}>
          <Card sx={{ overflow: "visible" }}>
            <SoftBox p={2}>
              <SoftBox component="form" onSubmit={handleSubmit}>
                <StrategyInfo {...newStrategy} setNewStrategy={setNewStrategy} />
                <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                  <SoftButton variant="gradient" color="error" onClick={handleCancel} >
                    {t('cancel')}
                  </SoftButton>
                  <SoftButton variant="gradient" color="info" type="submit">
                    {t('save')}
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default EditStrategy;
