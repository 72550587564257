/***** We receive an action and set de data to the store *****/

import {
    SET_ARBITRATION_ACTIONS,
    SET_ARBITRATION_ACTIONS_STATUS,
    SET_ARBITRATION_OPPORTUNITIES,
    SET_ARBITRATION_OPPORTUNITIES_STATUS,
    SET_ARBITRATION_STRATEGIES,
    SET_ARBITRATION_STRATEGIES_STATUS,
    SET_ARBITRATION_EXCHANGES,
    SET_ARBITRATION_EXCHANGES_STATUS,
    SET_ARBITRATION_PAIRS,
    SET_ARBITRATION_PAIRS_STATUS,
    ADD_ARBITRATION_STRATEGY,
    REMOVE_ARBITRATION_STRATEGY,
    UPDATE_ARBITRATION_STRATEGY
} from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";

export default (arbitration = {
    actions: { data: [], status: STATUS.PENDING },
    opportunities: { data: [], status: STATUS.PENDING },
    strategies: { data: [], status: STATUS.PENDING },
    exchanges: { data: [], status: STATUS.PENDING },
    pairs: { data: [], status: STATUS.PENDING }
}, action) => {
    switch (action.type) {
        case SET_ARBITRATION_ACTIONS:
            return { ...arbitration, actions: { ...arbitration.actions, data: action.payload } };
        case SET_ARBITRATION_ACTIONS_STATUS:
            return { ...arbitration, actions: { ...arbitration.actions, status: action.payload } };
        case SET_ARBITRATION_OPPORTUNITIES:
            return { ...arbitration, opportunities: { ...arbitration.opportunities, data: action.payload } };
        case SET_ARBITRATION_OPPORTUNITIES_STATUS:
            return { ...arbitration, opportunities: { ...arbitration.opportunities, status: action.payload } };
        case SET_ARBITRATION_STRATEGIES:
            return { ...arbitration, strategies: { ...arbitration.strategies, data: action.payload } };
        case SET_ARBITRATION_STRATEGIES_STATUS:
            return { ...arbitration, strategies: { ...arbitration.strategies, status: action.payload } };
        case SET_ARBITRATION_EXCHANGES:
            return { ...arbitration, exchanges: { ...arbitration.exchanges, data: action.payload } };
        case SET_ARBITRATION_EXCHANGES_STATUS:
            return { ...arbitration, exchanges: { ...arbitration.exchanges, status: action.payload } };
        case SET_ARBITRATION_PAIRS:
            return { ...arbitration, pairs: { ...arbitration.pairs, data: action.payload } };
        case SET_ARBITRATION_PAIRS_STATUS:
            return { ...arbitration, pairs: { ...arbitration.pairs, status: action.payload } };
        case ADD_ARBITRATION_STRATEGY:
            return { ...arbitration, strategies: { ...arbitration.strategies, data: [...arbitration.strategies.data, action.payload] } };
        case REMOVE_ARBITRATION_STRATEGY: {
            const copiedStrategies = [...arbitration.strategies.data]
            const filteredStrategies = copiedStrategies.filter(strategy => strategy.id !== action.payload)
            return { ...arbitration, strategies: { ...arbitration.strategies, data: filteredStrategies } };
        }
        case UPDATE_ARBITRATION_STRATEGY:
            return { ...arbitration, strategies: { ...arbitration.strategies, data: action.payload } };
        default:
            return arbitration;
    };
};