import { fetchMessages } from "api/messages";
import { SET_MESSAGES } from "constants/actionTypes";
import { MESSAGES_STATUS } from "constants/actionTypes";
import { STATUS } from "constants/fetchStatus";

/***** Redux actions that end up in the store *****/
export const getMessages = () => async (dispatch) => {
  try {
    dispatch({ type: MESSAGES_STATUS, payload: STATUS.LOADING });
    const data = await fetchMessages();
    dispatch({ type: SET_MESSAGES, payload: data });
    dispatch({ type: MESSAGES_STATUS, payload: STATUS.OK });
  } catch (error) {
    dispatch({ type: MESSAGES_STATUS, payload: STATUS.ERROR });
  }
};
