import { CLIENTS_CONFIG } from "./clientsConfig";

const getClientConfig = () => {
    const domain = `https://${window.location.hostname}`;
    const clientConfig = CLIENTS_CONFIG.find(config => config.url === domain)
    return clientConfig
}

const clientConfig = getClientConfig()

export default clientConfig