// Translate
import { useTranslation } from 'react-i18next';
import ReportsDoughnutChart from "examples/Charts/DoughnutCharts/ReportsDoughnutChart";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React components
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// react-router components
import { Link } from "react-router-dom";
import { ROUTES } from "constants";
import {
    Chart as ChartJS,
    BarElement,
    Tooltip,
    Legend,
    CategoryScale,
    ArcElement
} from "chart.js"
import { STATUS } from "constants/fetchStatus";
ChartJS.register(
    BarElement,
    Tooltip,
    Legend,
    CategoryScale,
    ArcElement
)

export default function SummaryChartPie({ wallet, walletStatus }) {
    const { t } = useTranslation();
    const { walletBaseCoinValue, pairs } = wallet
    const limitedPairs = pairs.slice(0, 5)

    return (
        <Card style={{ height: "100%", opacity: walletStatus === STATUS.LOADING ? 0.3 : 1 }}>
            <SoftBox pt={2} px={3}>
                <Link to={ROUTES.WALLET}>
                    <SoftTypography
                        mr={3}
                        variant="section"
                        className="title"
                        sx={{
                            mr: "auto",
                            display: "inline-flex",
                            alignItems: "center",
                            "& .material-icons-round": {
                                transform: `translate(2px, -0.5px)`,
                                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                            },

                            "&:hover .material-icons-round, &:focus  .material-icons-round": {
                                transform: `translate(6px, -0.5px)`,
                            },
                        }}
                    >
                        {t('Portfolio')}
                        <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                    </SoftTypography>
                </Link>
            </SoftBox>
            <SoftBox mt={-1}>
                <ReportsDoughnutChart
                    count={{ number: `${walletBaseCoinValue}$`, text: "Total" }}
                    status={walletStatus}
                    chart={{
                        labels: limitedPairs.map(pair => pair.symbol),
                        datasets: {
                            label: "Value",
                            backgroundColors: ["primary", "secondary", "info", "success", "warning"],
                            data: limitedPairs.map(pair => `${(pair.baseCoinValue / wallet.walletBaseCoinValue * 100).toFixed(2)}`),
                        }
                    }}
                />
            </SoftBox>
        </Card>
    )
}