import { quantum } from 'ldrs'

quantum.register()

export default function Loader({ size = "md" }) {
    const loaderColor = localStorage.getItem("primaryColor") || "#cb0c9f";
    return (
        <div style={{ display: "flex", justifyContent: "center", margin: size === "md" ? "24px" : "0px" }}>
            <l-quantum
                size={size === "md" ? "35" : "18"}
                speed="1.75"
                color={loaderColor}
            ></l-quantum>
        </div>
    )
}