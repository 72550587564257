// @mui material components
import PeopleIcon from "@mui/icons-material/People";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import MovieCreationIcon from "@mui/icons-material/MovieCreation";

export const dataTabs = [
  {
    id: "usersTab",
    key: "users",
    name: "users",
    icon: <PeopleIcon fontSize="medium" />,
    value: "users",
  },
  {
    id: "messagesTab",
    key: "messages",
    name: "messages",
    icon: <ForwardToInboxIcon fontSize="medium" />,
    value: 'messages'
  },
  {
    id: "videosTab",
    key: "videos",
    name: "videos",
    icon: <MovieCreationIcon fontSize="medium" />,
    value: 'videos'
  },
];
